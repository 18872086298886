import { gql } from "@apollo/client";

export const TOP_TEN_LOCATIONS = gql`
query top_ten_location($where: reports_top_ten_location_bool_exp) {
  reports_top_ten_location(where: $where, order_by: { total_net_sales: desc }) {
    location_id
    location_name
    vendor_id
    total_net_sales
    total_tips
  }
}
`;
