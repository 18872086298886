import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  PaymentProcessorList,
  paymentProcessorListLoader,
} from "./PaymentProcessorList";

import {
  PaymentProcessorEdit,
  paymentProcessorEditAction,
  paymentProcessorEditLoader,
} from "./PaymentProcessorEdit";
import {
  PaymentProcessorCreate,
  paymentProcessorCreateAction,
  paymentProcessorsLoader,
} from "./PaymentProcessorCreate";
import { PaymentProcessor } from "../../types/paymentProcessor";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DeletePaymentProcessor } from "./mutations";
import { toast } from "react-hot-toast";

const PaymentProcessorsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/payment-processors") {
    navigate(`/payment-processors/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const client = createApolloClient();

const paymentProcessorDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await Promise.all([
      client.mutate({
        mutation: DeletePaymentProcessor,
        variables: { id },
      }),
    ]);
    toast.success("Menu deleted");
    return redirect("/payment-processors/list");
  } catch {
    toast.error("Payment Processor could not be deleted");
  }
};

export const paymentProcessorsRouter = {
  path: "/payment-processors",
  id: "payment-processors",
  element: <PaymentProcessorsIndex />,
  handle: {
    crumb: () => (
      <Breadcrumb name="PaymentProcessors" href="/payment-processors/list" />
    ),
  },
  children: [
    {
      path: "list",
      id: "payment-processors-list",
      element: <PaymentProcessorList />,
      loader: paymentProcessorListLoader,
    },
    {
      path: "create",
      id: "payment-processors-create",
      element: <PaymentProcessorCreate />,
      action: paymentProcessorCreateAction,
      loader: paymentProcessorsLoader,
      handle: {
        crumb: () => (
          <Breadcrumb
            name="Create Payment Processor"
            href="/payment-processors/create"
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "payment-processors-edit",
      element: <PaymentProcessorEdit />,
      loader: paymentProcessorEditLoader,
      action: paymentProcessorEditAction,
      handle: {
        crumb: (data: PaymentProcessor) => (
          <Breadcrumb
            name={`Edit ${data.payment_processor}`}
            href={`/payment-processors/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "payment-processors-delete",
      action: paymentProcessorDeleteAction,
    },
  ],
};
