import { gql } from "@apollo/client";

export const CASH_BALANCE_REPORT = gql`
  query reports_event($where: reports_cash_balance_bool_exp) {
    reports_cash_balance(where: $where, order_by: {username: asc}) {
    created_at_hour
    event_id
    event_name
    gross_cash_loaded
    gross_cash_refunded
    location_id
    location_name
    net_cash_loaded
    organization_id
    user_id
    username
    }
  }
`;
