import { gql } from "@apollo/client";


export const TICKET_SEND_EMAIL_SMS = gql`
  mutation SendEmailSmsTicket($data: sendTicketInput!) {
    ticket_send_email(sendTicketInput: $data) {
      message
    }
  }
`;


export const UPDATE_ATTENDEE = gql`
  mutation update_attendees(
    $input: attendees_set_input
    $where: attendees_bool_exp!
  ) {
    update_attendees(_set: $input, where: $where) {
      returning {
        id
      }
    }
  }
`;


export const UPDATE_ATTENDEE_CARD_ON_FILE = gql`
  mutation UpdateAttendeeCardOnFile($id: Int!, $attendee_id: Int) {
    update_card_on_files_by_pk(
      pk_columns: { id: $id }
      _set: { attendee_id: $attendee_id }
    ) {
      id
    }
  }
`;

