import { gql } from "@apollo/client";

export const CREATE_DEVICE = gql`
  mutation CreateDevice($input: devices_insert_input!) {
    insert_devices_one(
        object: $input
    ) {
      id
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation UpdateDevice($input: devices_set_input, $where: devices_bool_exp!) {
    update_items(_set: $input, where: $where) {
      id
    }
  }
`;
