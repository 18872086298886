import { gql } from "@apollo/client";

export const GET_ITEM_CATEGORIES = gql`
  query item_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`;

export const GET_ITEM_MODIFIER_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: item_modifiers_bool_exp!) {
    item_modifiers(order_by: { name: asc }, where: $where) {
      id
      is_active
      name
      organization_id
      sub_type
      type
      additional_price
    }
  }
`;

export const GET_ITEMS = gql`
  query items($where: items_bool_exp) {
    items(where: $where, order_by: { name: asc }) {
      created_at
      description
      id
      is_active
      name
      tags
      price
      short_name
      unique_id
      updated_at
      gl_account_number
      gl_account_name
      upc
      category
      event_id
      vendor_id
      is_variable_price
      redeemable_token_name
      tax
      tax_percentage
      alternate_tax
      menu_items {
        menu {
          tax_type
        }
      }
      event {
        id
        name
      }
      vendor {
        id
        name
      }
      user {
        username
      }
    }
  }
`;

export const GET_ITEM = gql`
  query GetItem($id: Int!) {
    items_by_pk(id: $id) {
      id
      price
      tags
      name
      short_name
      description
      unique_id
      is_active
      event_id
      tax
      upc
      category
      token_price
      is_variable_price
      redeemable_token_id
      redeemable_token_name
      tax_percentage
      taxable_item
      alternate_tax
      modifier_type
      modifiers
      vendor_id
      gl_account_number
      gl_account_name
      menu_items {
        menu {
          tax_type
          __typename
        }
        __typename
      }
      event {
        id
        name
        __typename
      }
      __typename
    }
  }
`;
