import { gql } from "@apollo/client";

export const UPDATE_ATTENDEE = gql`
  mutation update_attendees(
    $input: attendees_set_input
    $where: attendees_bool_exp!
  ) {
    update_attendees(_set: $input, where: $where) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ATTENDEE_RFID = gql`
  mutation UpdateRfidAsset($id: Int!, $attendee_id: Int) {
  update_rfid_assets(where: {id: {_eq: $id}}, _set: {attendee_id: $attendee_id}) {
    returning {
      id
    }
  }
}

`;

export const UPDATE_ATTENDEE_CARD_ON_FILE = gql`
  mutation UpdateAttendeeCardOnFile($id: Int!, $attendee_id: Int) {
    update_card_on_files_by_pk(
      pk_columns: { id: $id }
      _set: { attendee_id: $attendee_id }
    ) {
      id
    }
  }
`;

export const DELETE_ATTENDEE = gql`
   mutation DeleteAttendee($id: Int!) {
    delete_attendees_by_pk(id: $id) {
      id
    }
  }
`;