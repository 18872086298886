import gql from 'graphql-tag';

// export const CREATE_REFUND = gql`
//   {
//     id
//     statusMessage
//   }
// `;

export const CREATE_REFUNDS = gql`
  {
    returning: message
  }
`;
