import {ActionFunctionArgs, Outlet, redirect, useLocation, useNavigate} from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  AttendeeEdit,
  attendeeEditAction,
  attendeeEditLoader,
} from "./AttendeeEdit";
import { AttendeeList, attendeesListLoader } from "./AttendeeList";
import { Attendee } from "../../types/attendee";
import { AttendeeShow } from "./AttendeeShow";
import toast from "react-hot-toast";
import {DELETE_ATTENDEE} from "./mutations";
import {createApolloClient} from "../../providers/ApolloClientFactory";

const client = createApolloClient();

const AttendeeIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/attendees") {
    navigate(`/attendees/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const attendeeDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_ATTENDEE,
      variables: { id },
    });
    toast.success("Attendee Deleted");
    return redirect(`/attendees/list`);
  } catch (error) {
    toast.error("Failed to delete attendee: " + error);
    return null;
  }
};

export const attendeesRouter = {
  path: "/attendees",
  id: "attendees",
  element: <AttendeeIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Attendee" href="/attendees/list" />,
  },
  // loader: requireAuth(ADMIN_ORGANIZER),
  children: [
    {
      path: "list",
      id: "attendees-list",
      element: <AttendeeList />,
      loader: attendeesListLoader,
    },
    {
      path: ":id",
      id: "attendees-show",
      element: <AttendeeShow />,
    },
    {
      path: ":id/edit",
      id: "attendees-edit",
      element: <AttendeeEdit />,
      loader: attendeeEditLoader,
      action: attendeeEditAction,
      handle: {
        crumb: (data: { attendee: Attendee }) => (
          <Breadcrumb
            name={`Edit ${data.attendee.first_name} ${data.attendee.last_name}`}
            href={`/attendees/${data.attendee.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "attendees-delete",
      action: attendeeDeleteAction,
    },
  ],
};
