import { Suspense, StrictMode } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import { OverlayContainer } from "@react-aria/overlays";
import { Toaster } from "react-hot-toast";
import Loader from "./components/Loader";
import App from "./App";

const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <OverlayContainer>
        <Suspense fallback={<Loader />}>
          <App />
          <Toaster />
        </Suspense>
      </OverlayContainer>
    </QueryClientProvider>
  </StrictMode>,
  document.getElementById("root")
);
