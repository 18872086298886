import { gql } from "@apollo/client";

export const GET_PROMO_CODE = gql`
  query GetPromoCode($where: promo_code_bool_exp) {
    promo_code(where: $where, order_by: { created_at: desc }) {
      id
      uid
      description
      promo_payload
      promo_code_type
      event {
        id
        name
        description
      }
      organization {
        id
        name
      }
      attendee {
        id
      }
      email
      phone_number
      is_used
      created_at
      updated_at
      user {
        username
      }
    }
  }
`;

export const GET_PROMO_CODE_PK = gql`
  query PromoCodeByPk($id: Int!) {
    promo_code_by_pk(id: $id) {
      id
      uid
      description
      promo_payload
      promo_code_type
      event {
        id
        name
      }
      organization {
        id
        name
      }
      attendee {
        id
      }
      email
      phone_number
      is_used
      created_at
      updated_at
    }
  }
`;

export const GET_PROMO_CODE_ITEM_CATEGORIES = gql`
  query item_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`;

export const GET_PROMO_CODE_ITEMS = gql`
  query items($where: items_bool_exp) {
    items(where: $where, order_by: { name: asc }) {
      id
      is_active
      name
      event_id
      category
      short_name
    }
  }
`;
