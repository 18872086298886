import gql from 'graphql-tag';

export const GET_LIST_RFID_ASSETS = gql`
  {
    id
    uid
    is_active
    last_four_phone_numbers
    event_id
    # event {
    #   id
    #   name
    # }
    attendee_id

    attendee {
      id
      first_name
      last_name
    }
  }
`;

export const INSERT_RFID_ASSETS = gql`
  {
    id
    uid
    is_active
    event_id
    attendee_id
    last_four_phone_numbers
  }
`;

export const UPDATE_RFID_ASSETS = gql`
  {
    id
    uid
    is_active
    event_id
    attendee_id
    last_four_phone_numbers
  }
`;

export const GET_ONE_RFID_ASSETS = gql`
  {
    id
    uid
    is_active
    last_four_phone_numbers
    event_id
    event {
      id
      name
    }

    attendee_id
    attendee {
      id
      first_name
      last_name
    }
  }
`;

export const GET_MANY_RFID_ASSETS = gql`
  {
    id
    uid
    is_active
    last_four_phone_numbers
    event_id
    # event {
    #   id
    #   name
    # }

    attendee_id
    attendee {
      id
      first_name
      last_name
    }
  }
`;
