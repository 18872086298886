import { gql } from "@apollo/client";

export const UPDATE_RFID = gql`
  mutation UpdateRfidAsset(
    $uid: String!
    $access_type: String!
    $reg_id: String!
    $is_active: Boolean
    $event_id: Int!
    $cash_balance: Int!
    $promo_balance: Int!
    $personnal_pin: String!
    $last_four_phone_numbers: String!
    $tokens_balance: jsonb!
    $notes: String!
    $last_updated_by: Int
  ) {
    update_rfid_assets(
      where: { event_id: { _eq: $event_id }, uid: { _eq: $uid } }
      _set: {
        access_type: $access_type
        cash_balance: $cash_balance
        is_active: $is_active
        last_four_phone_numbers: $last_four_phone_numbers
        personnal_pin: $personnal_pin
        promo_balance: $promo_balance
        reg_id: $reg_id
        tokens_balance: $tokens_balance
        notes: $notes
        last_updated_by: $last_updated_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_RFID_WITHOUT_PIN = gql`
  mutation UpdateRfidAsset(
    $uid: String!
    $access_type: String!
    $reg_id: String!
    $is_active: Boolean
    $event_id: Int!
    $cash_balance: Int!
    $promo_balance: Int!
    $tokens_balance: jsonb!
    $notes: String!
    $last_updated_by: Int
  ) {
    update_rfid_assets(
      where: { event_id: { _eq: $event_id }, uid: { _eq: $uid } }
      _set: {
        access_type: $access_type
        cash_balance: $cash_balance
        is_active: $is_active
        promo_balance: $promo_balance
        reg_id: $reg_id
        tokens_balance: $tokens_balance
        notes: $notes
        last_updated_by: $last_updated_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;
