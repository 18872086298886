import gql from 'graphql-tag';

export const GET_LIST_PAYMENTS = gql`
  {
    amount
    id
    payment_data
    payment_type
    reference_id
    status
  }
`;

export const UPDATE_PAYMENT = gql`
  {
    amount
    id
    payment_data
    payment_type
    reference_id
    status
  }
`;

export const GET_ONE_PAYMENT = gql`
  {
    amount
    id
    payment_data
    payment_type
    reference_id
    status
  }
`;
export const GET_MANY_PAYMENT = gql`
  {
    amount
    id
    payment_data
    payment_type
    reference_id
    status
  }
`;
