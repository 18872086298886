import { gql } from "@apollo/client";

export const UPDATE_ORGANIZATION = gql`
  mutation OrganizationEdit(
    $id: Int!,
    $name: String,
    $user_id: Int,
    $logo_url: String,
    $background_url: String,
    $business_name: String,
    $business_address_1: String,
    $business_address_2: String,
    $business_city: String,
    $business_state: String,
    $business_zipcode: String,
    $business_website: String,
    $business_phone_number: String
  ) {
    update_organizations_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name,
        last_updated_by: $user_id,
        logo_url: $logo_url,
        background_url: $background_url,
        business_name: $business_name,
        business_address_1: $business_address_1,
        business_address_2: $business_address_2,
        business_city: $business_city,
        business_state: $business_state,
        business_zipcode: $business_zipcode,
        business_website: $business_website,
        business_phone_number: $business_phone_number
      }
    ) {
      id
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization(
    $input: organizations_insert_input!
  ) {
    insert_organizations_one( object: $input )
    {
      id
    }
  }
`;

export const DELETE_ORGANIZATION = gql`
  mutation DeleteOrganization($id: Int!) {
    delete_organizations_by_pk(id: $id) {
      id
    }
  }
`;
