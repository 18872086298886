import {
  ActionFunctionArgs,
  Form,
  redirect,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { Input, TextArea } from "../../components/inputs/Input";
import PriceInput from "../../components/inputs/PriceInput";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { TagsInput } from "../../components/inputs/TagsInput";
import { CREATE_ITEM } from "./mutations";
import { FormEvent, useEffect, useState} from "react";
import { Categories, Modifier} from "../../types/item";
import {userStore} from "../../store/user";
import CustomSelect from "../../components/customSelect";
import {Vendor} from "../../types/vendor";
import {organizationStore} from "../../store/organization";
import {
  GET_LIST_EVENTS_BY_ORGANIZATION,
  GET_LIST_VENDORS_BY_ORGANIZATION,
} from "../../queries";
import {Event} from "../../types/event";
import {GET_ITEM_MODIFIER_BY_ORGANIZATION} from "../item-modifiers/queries";
import {usdFormat} from "../../utils";
import {PrimaryButton} from "../../components/Button";
import {GET_ITEM_CATEGORIES} from "./queries";
import {AlternateTax, DropdownOption} from "./type";
import {round} from "lodash";

const client = createApolloClient();
const {getState} = userStore;
const {getState: getOrganizationStore} = organizationStore;

export const itemCreateLoader = async () => {
  const organizationId = getOrganizationStore().organizationId;
  const user = getState().user;

  let eventWhere = {};
  let vendorWhere = {};

  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }

  if (user?.events) {
    eventWhere = {
      ...eventWhere,
      id: {
        _in: user?.events,
      },
    };
  }

  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };

    eventWhere = {
      ...eventWhere,
      organization_id: {_eq: organizationId},
      is_active: {_eq: true},
    };
  }
  const {data} = await client.query({
    query: GET_LIST_VENDORS_BY_ORGANIZATION,
    variables: {
      where: vendorWhere,
    },
  });

  const {data: eventsData} = await client.query({
    query: GET_LIST_EVENTS_BY_ORGANIZATION,
    variables: {
      where: eventWhere,
    },
  });

  const {data: itemCategoriesData} = await client.query({
    query: GET_ITEM_CATEGORIES,
    variables: {
      where: organizationId
          ? {
            organization_id: {
              _in: [0, organizationId],
            },
            is_active: {
              _eq: true,
            },
            reference_type: {
              _eq: "item_category",
            },
          }
          : {},
    },
  });
  return {
    vendors: data.vendors,
    events: eventsData.events,
    itemCategories: itemCategoriesData.dashboard_reference,
  };
};
export const itemCreateAction = async ({
                                         params,
                                         request,
                                       }: ActionFunctionArgs) => {
  const body = await request.formData();
  const user = getState().user;
  const applyInclusivePrice = body.get("taxable_item") === "exclusive" ? true : false
  const inclusivePriceString = body.get("inclusive_price") as string;
  const inclusivePrice = parseFloat(inclusivePriceString.length ? inclusivePriceString : "0");
  const priceString = body.get("price") as string;
  const price = parseFloat(priceString.length ? priceString : "0");
  const tagsString = body.get("tags") as string;
  const tags = tagsString.length > 0 ? tagsString.split(",").map((tag) => tag.trim()) : [];
  const taxPercentageString = body.get("tax_percentage") as string;
  const taxPercentage = (parseFloat(taxPercentageString) / 100).toFixed(8);
  const redeemable_token_id = body.get("redeemable_token_name");
  const modifiers = JSON.parse(body.get("selected_item_modifiers") as string);
  const alternateTax = JSON.parse(body.get("alternate_tax") as string);
  const alternateTaxArray = [...alternateTax];
  const modifierArray = [...modifiers];


  let input = {
    name: body.get("name"),
    is_active: body.get("is_active"),
    event_id: body.get("event_id"),
    vendor_id: body.get("vendor_id"),
    category: body.get("category"),
    description: body.get("description"),
    upc: body.get("upc"),
    price: Math.round((applyInclusivePrice ? price : inclusivePrice) * 100),
    tags,
    gl_account_number: body.get("gl_account_number"),
    gl_account_name: body.get("gl_account_name"),
    is_variable_price: body.get("is_variable_price"),
    redeemable_token_id: redeemable_token_id,
    redeemable_token_name: redeemable_token_id,
    token_price: body.get("token_price"),
    short_name: body.get("name"),
    tax: parseFloat(taxPercentage) !== 0 ? "taxed" : "no_tax",
    taxable_item: body.get("taxable_item") === "exclusive" ? true : false,
    tax_percentage: taxPercentage ? taxPercentage : 0,
    last_updated_by: user?.id,
    alternate_tax: alternateTaxArray,
    modifiers: modifierArray,
    modifier_type: body.get("modifier_type")
  };

  try {
    const {data} = await client.mutate({
      mutation: CREATE_ITEM,
      variables: {
        input,
      },
    });

    toast.success("Item created");

    return redirect(`/items/list`);
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    const message = error?.graphQLErrors?.[0]?.message;
    if (errorType === "constraint-violation") {
      toast.error(message);
    } else {
      toast.error("Failed to create item" + error);
    }
    return redirect("/items/create");
  }
};

export const ItemCreate = () => {

  const [taxed, setTaxed] = useState(true);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [isAddClicked, setIsAddClicked] = useState(false);
  const [totalTax, setTotalTax] = useState(0);
  const [price, setPrice] = useState<number>(0);
  const [inclusivePrice, setInclusivePrice] = useState<number>(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [taxType, setTaxType] = useState("exclusive");
  const [alternateTax, setAlternateTax] = useState<AlternateTax[]>([]);
  const [variablePrice, setVariablePrice] = useState(false);
  const [token, setToken] = useState(false);

  const handleAddLineTax = () => {
    setAlternateTax([...alternateTax, {description: "", taxPercentage: 0}]);
    setIsAddClicked(true);
  };

  const handleTaxTypeChange = (value: string) => {
    setTaxType(value);
  };
  const handleRemoveLastTax = () => {
    setAlternateTax(alternateTax.slice(0, -1));
    if (alternateTax.length === 1) {
      setIsAddClicked(false);
    }
  };
  const handlePriceChange = (value: number) => {
    setPrice(value);
  };
  const handleTaxPercentageChange = (value: string | number | undefined) => {
    if (typeof value === "string") {
      setTaxPercentage(parseFloat(value) || 0);
    }
  };
  const handleLineItemChange = (
      index: number,
      field: string,
      value: string | number | undefined
  ) => {
    const newLineItems = [...alternateTax];
    newLineItems[index] = {...newLineItems[index], [field]: value ?? ""};
    setAlternateTax(newLineItems);
  };

  const calculateTotals = () => {
    let totalLineItemTax = 0;

    alternateTax.forEach((item) => {
      const itemTax = (price * (item.taxPercentage || 0)) / 100;
      totalLineItemTax += itemTax;
    });
    const mainTaxPercentage = taxPercentage || 0;
    if (taxType === "inclusive") {
      const mainTaxRate = mainTaxPercentage / 100;
      const lineItemTaxRate = totalLineItemTax / price;
      const combinedTaxRate = mainTaxRate + lineItemTaxRate;
      setInclusivePrice( round((price / (1 + combinedTaxRate)),2));
      const calculatedTax = round((price - inclusivePrice),2);
      setTotalTax(calculatedTax);
      setGrandTotal(price);
    } else {
      const mainTax = (price * mainTaxPercentage) / 100;
      const totalTax = mainTax + totalLineItemTax;
      setTotalTax(totalTax);
      setGrandTotal(price + totalTax);
    }

  };
  const {vendors, events, itemCategories} = useLoaderData() as {
    vendors: Vendor[];
    events: Event[];
    itemCategories: Categories[];
  };
  const organizationId = getOrganizationStore().organizationId;
  const [allItemModifiers, setAllItemModifiers] = useState<Modifier[]>([]);
  const [modifierTypes, setModifierTypes] = useState<DropdownOption[]>([]);
  const [selectedModifierType, setSelectedModifierType] = useState('')
  const [subTypes, setSubTypes] = useState([{ subType: '', multiSelection: false }]);
  const [selectedItemModifiers, setSelectedItemModifiers] = useState<Modifier[]>([]);
  const [checkedModifiers, setCheckedModifiers] = useState<Record<string, boolean>>({});

  const handleCheckboxChange = (modifier: Modifier, isChecked: boolean | FormEvent<HTMLInputElement>) => {
    const currentSubTypeIndex = subTypes.findIndex((subType) => subType.subType === modifier.sub_type);
    const multiSelectionValue = subTypes[currentSubTypeIndex]?.multiSelection || false;
    setCheckedModifiers((prevState) => ({
      ...prevState,
      [modifier.id]: isChecked as boolean,
    }));

    if (isChecked) {
      setSelectedItemModifiers((prev) => [
        ...prev,
        {
          id: modifier.id,
          name: modifier.name,
          sub_type: modifier.sub_type,
          additional_price: modifier.additional_price,
          multi_selection: multiSelectionValue,
          default: false,
          type: modifier.type,
        },
      ]);
    } else {
      setSelectedItemModifiers((prev) =>
          prev.filter((item) => item.id !== modifier.id)
      );
    }
  };


  const handleMultiSelectionChange = (index: number, isChecked: boolean) => {
    setSubTypes((prev) => {
      const newSubTypes = [...prev];
      newSubTypes[index].multiSelection = isChecked;
      return newSubTypes;
    });

    setSelectedItemModifiers((prevModifiers) =>
        prevModifiers.map((modifier) =>
            modifier.sub_type === subTypes[index].subType
                ? { ...modifier, multi_selection: isChecked }
                : modifier
        )
    );

    if (!isChecked) {
      setSelectedItemModifiers((prevModifiers) =>
          prevModifiers.map((modifier) =>
              modifier.sub_type === subTypes[index].subType
                  ? { ...modifier, default: false }
                  : modifier
          )
      );
    }
  };

  const handleDefaultChange = (modifierId: number, isDefault: boolean) => {
    setSelectedItemModifiers((prevModifiers) =>
        prevModifiers.map((modifier) =>
            modifier.id === modifierId ? { ...modifier, default: isDefault } : modifier
        )
    );
  };

  const handleAddSubType = () => {
    setSubTypes((prev) => [...prev, { subType: '', multiSelection: false }]);
  };

  const handleRemoveSubType = (index: number) => {
    setSubTypes((prev) => prev.filter((_, i) => i !== index));

    setSelectedItemModifiers((prevModifiers) =>
        prevModifiers.filter((modifier) => modifier.sub_type !== subTypes[index].subType)
    );
  };

  const updateSubType = (index: number, value: string) => {
    setSubTypes((prev) => {
      const newSubTypes = [...prev];
      const oldSubType = newSubTypes[index].subType;

      if (oldSubType && oldSubType !== value) {
        setSelectedItemModifiers((prevModifiers) =>
            prevModifiers.filter((modifier) => modifier.sub_type !== oldSubType)
        );
      }

      newSubTypes[index] = {
        subType: value,
        multiSelection: newSubTypes[index].multiSelection,
      };
      return newSubTypes;
    });
  };

  const getModifiersByType = (type: string): DropdownOption[] => {
    const uniqueSubTypes = new Set(
        allItemModifiers
            .filter((modifier) => modifier.type === type)
            .map((modifier) => modifier.sub_type)
    );
    return Array.from(uniqueSubTypes).map((subType) => ({
      label: subType.charAt(0).toUpperCase() + subType.slice(1),
      value: subType,
    }));
  };

  const getModifiersBySubType = (subType: string): Modifier[] => {
    return allItemModifiers.filter((modifier) => modifier.sub_type === subType);
  };

  const clearModifiers = () => {
    setSubTypes([{subType: '', multiSelection: false}]);
    setCheckedModifiers({});
    setSelectedItemModifiers([]);
    setSelectedModifierType('')
  };

  useEffect(() => {
    const fetchAllModifiers = async () => {
      const { data } = await client.query({
        query: GET_ITEM_MODIFIER_BY_ORGANIZATION,
        variables: {
          where: organizationId
              ? { organization_id: { _eq: organizationId } }
              : {},
        },
      });

      const itemModifiers: Modifier[] = data.item_modifiers;

      setAllItemModifiers(itemModifiers);

      // Extract distinct types and transform them into dropdown options
      const types: DropdownOption[] = [
        ...new Set(itemModifiers.map((item) => item.type)),
      ].map((type) => ({
        label: type.charAt(0).toUpperCase() + type.slice(1),
        value: type,
      }));

      setModifierTypes(types);
    };

    fetchAllModifiers();
  }, [organizationId]);

  useEffect(() => {
    calculateTotals();
  }, [alternateTax, taxType, price, taxPercentage, calculateTotals]);
console.log(selectedItemModifiers)
  return (
    <Form
      method="post"
      action={`/items/create`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
        }
      }}
    >
      <FormLayout>
        <div className="col-span-5 sm:col-span-3">
          <Input label="Item Name" name="name" required/>
        </div>
        <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked
        />
        <div className="col-span-6">
          <CustomSelect
              title="Event"
              name="event_id"
              required
              options={[
                ...(events?.map((e) => ({
                  label: e.name,
                  value: e.id,
                })) || []),
              ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
              title="Vendor"
              name="vendor_id"
              required
              options={[
                {
                  value: 0,
                  label: "All Vendors",
                },
                ...(vendors?.map((v) => ({
                  label: v.name,
                  value: v.id,
                })) || []),
              ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
              title="Item Category"
              name="category"
              required
              options={[
                ...(itemCategories?.map((v) => ({
                  label: v.reference_label,
                  value: v.reference_value,
                })) || []),
              ]}
          />
        </div>
        <div className="col-span-6">
          <TextArea label="Description" name="description"/>
        </div>
        <div className="col-span-6">
          <Input label="UPC" name="upc"/>
        </div>
        <div className="col-span-6">
          <TagsInput
              label="Optional Tags"
              name="tags"
              placeholder="Add a Tag (press 'enter' to add)"
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Input label="GL Account" name="gl_account_name"/>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <Input label="GL Account Number" name="gl_account_number"/>
        </div>
        {!variablePrice && (
            <div className="col-span-5 sm:col-span-3">
              <PriceInput
                  label="Price"
                  name="price"
                  required
                  onChange={(value) => handlePriceChange(value ? value : 0)}
                  value={price}
              />
            </div>
        )}
        {variablePrice && <input type="hidden" value={"0"} name="price"/>}
        <div className={variablePrice ? "col-span-6 sm:col-span-3" : "col-span-1 sm:col-span-1"}>
          <IndeterminateCheckbox
              label="Custom Price?"
              name="is_variable_price"
              defaultChecked={variablePrice}
              onChange={(checked) => setVariablePrice(checked as boolean)}
          />
        </div>
        <br/>
        <div className="col-span-3 sm:col-span-3">
          <CustomSelect
              title="Tax Type"
              name="taxable_item"
              options={[
                {label: "Inclusive", value: "inclusive"},
                {label: "Exclusive", value: "exclusive"},
              ]}
              defaultValue={"exclusive"}
              onChange={handleTaxTypeChange}
          />
        </div>
        <div className="col-span-5 sm:col-span-3">
          <Input
              label="Tax % ( 7.5 = 7.5% )"
              name="tax_percentage"
              required
              defaultValue={0}
              onChange={handleTaxPercentageChange}
          />
        </div>
        <>
          {isAddClicked &&
              alternateTax.map((item, index) => (
                  <div key={index} className="col-span-6">
                    <div className="col-span-6" key={index}>
                      <Input
                          label="Tax name"
                          name={`alternate_tax_description_${index}`}
                          onChange={(value) =>
                              handleLineItemChange(index, "description", value)
                          }
                      />
                    </div>
                    <div className="col-span-6">
                      <Input
                          label="Tax % ( 7.5 = 7.5% )"
                          name={`alternate_tax_percentage_${index}`}
                          required
                          onChange={(value) =>
                              handleLineItemChange(index, "taxPercentage", value)
                          }
                      />
                    </div>
                  </div>
              ))}
          <div className="col-span-6 flex gap-4">
            <PrimaryButton type="button" onClick={handleAddLineTax}>
              + Alternate Tax
            </PrimaryButton>
            {isAddClicked && alternateTax.length > 0 && (
                <PrimaryButton
                    type="button"
                    onClick={handleRemoveLastTax}
                >
                  - Alternate Tax
                </PrimaryButton>
            )}
          </div>
        </>
        <div className="col-span-6">
          <h3 className="text-lg font-semibold">Price Preview</h3>
          <div>Price: ${taxType === "inclusive" ? inclusivePrice.toFixed(2) : price.toFixed(2)}</div>
          <div>Total Tax: ${totalTax.toFixed(2)}</div>
          <div>Grand Total: ${grandTotal.toFixed(2)}</div>
        </div>
        <div className="col-span-6">
          <>
            <IndeterminateCheckbox
                label="Token Eligible"
                defaultChecked={token}
                onChange={(checked) => setToken(checked as boolean)}
            />
            {token && (
                <Input
                    label="Token Redemption Ratio (example: 1, 2, 3)"
                    name="token_price"
                    required
                />
            )}
            {token && (
                <Input
                    label="Token Name (example: VIP TOKEN)"
                    name="redeemable_token_name"
                    required
                />
            )}
          </>
        </div>
        <div className="col-span-6">
          <CustomSelect
              title="Add Modifiers?"
              name="modifier_type"
              value={selectedModifierType}
              onChange={(v) => {
                setSelectedModifierType(v);
                setSubTypes([{subType: '', multiSelection: false}]);
                setCheckedModifiers({});
                setSelectedItemModifiers([]);
              }}
              options={modifierTypes}
          />
        </div>
        {selectedModifierType && subTypes.map((subType, index) => (
            <div key={index} className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
              <div className="col-span-5">
                <CustomSelect
                    title={`Sub-Type ${index + 1}`}
                    name={`sub_type${index}`}
                    onChange={(v) => updateSubType(index, v)}
                    options={getModifiersByType(selectedModifierType)}
                />
              </div>
              <div className="col-span-1">
                <IndeterminateCheckbox
                    label="Multi Selection?"
                    name={`multi_selection${index}`}
                    onChange={(checked) => handleMultiSelectionChange(index, checked as boolean)}
                />
              </div>
              {getModifiersBySubType(subTypes[index].subType).map((modifier) => (
                  <div className="col-span-1" key={modifier.id}>
                    <div className="flex items-center gap-4 bg-gray-300 rounded p-1">
                      <IndeterminateCheckbox
                          label={`${modifier.name} (${usdFormat.format(modifier.additional_price / 100)})`}
                          name={`modifier-${modifier.id}`}
                          checked={checkedModifiers[modifier.id]}
                          onChange={(isChecked) => handleCheckboxChange(modifier, isChecked)}
                      />
                      {subTypes[index].multiSelection && (
                          <IndeterminateCheckbox
                              label="Default?"
                              name={`default-${modifier.id}`}
                              checked={
                                  selectedItemModifiers.find((m) => m.id === modifier.id)?.default ||
                                  false
                              }
                              onChange={(isChecked) => handleDefaultChange(modifier.id, isChecked as boolean)}
                          />
                      )}
                    </div>
                  </div>
              ))}
              <div className="col-span-6">
                {/*{index > 0 && (*/}
                <PrimaryButton type="button" onClick={() => handleRemoveSubType(index)}>
                  - Remove Sub-Type
                </PrimaryButton>
                {/*)}*/}
              </div>
            </div>
        ))}
        {selectedModifierType && (
            <div className="sm:col-span-1 col-span-2">
              <PrimaryButton type="button" onClick={handleAddSubType}>
                + Add Modifier
              </PrimaryButton>
            </div>
        )}
        {selectedModifierType && (
            <div className="sm:col-span-1 col-span-2">
              <PrimaryButton type="button" onClick={clearModifiers}>
                Clear Modifiers
              </PrimaryButton>
            </div>
        )}
        <input
            type="hidden"
            value={JSON.stringify(alternateTax)}
            name="alternate_tax"
        />
        <input
            type="hidden"
            value={JSON.stringify(selectedItemModifiers)}
            name="selected_item_modifiers"
        />
        <input
            type="hidden"
            value={inclusivePrice.toFixed(2)}
            name="inclusive_price"
        />
      </FormLayout>
    </Form>
  );
};
