import {Badge} from "../../components/Badge";
import {
    Link,
    useLoaderData,
    LoaderFunctionArgs,
    useNavigate,
    useRevalidator,
} from "react-router-dom";
import {organizationStore} from "../../store/organization";
import {createApolloClient} from "../../providers/ApolloClientFactory";

import {TICKETS_LIST_QUERY} from "./queries";
import {useEffect, useRef, useState} from "react";
import {eventStore} from "../../store/event";
import {usdFormat} from "../../utils";
import {usePermissions} from "../auth/api";
import {
    ColDef,
    GridApi,
    SizeColumnsToContentStrategy,
    ICellRendererParams,
} from "ag-grid-community";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {Input} from "../../components/inputs/Input";
import {PrimaryButton, SecondaryButton} from "../../components/Button";
import {AgGridReact} from "ag-grid-react";
import moment from "moment/moment";
import {userStore} from "../../store/user";
import {Tickets} from "../../types/tickets";

const client = createApolloClient();
const {getState} = organizationStore;
const {getState: getEventStore} = eventStore;
const {getState: getUserStore} = userStore;

const buildSearch = (search: string) => [
    {
        attendee: {
            first_name: {
                _ilike: `%${search}%`,
            },
        }
    },
    {
        attendee: {
            last_name: {
                _ilike: `%${search}%`,
            },
        }
    },
    {
        attendee: {
            phone_number: {
                _ilike: `%${search}%`,
            },
        }
    },
    {
        email: {
            _ilike: `%${search}%`,
        },
    },
    {
        reference_id: {
            _ilike: `%${search}%`,
        },
    },
];

type CellRenderer = (params: {
    value:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | React.ReactFragment
        | React.ReactPortal
        | null
        | undefined;
    data: Tickets;
}) => JSX.Element | null;
const createCellRenderer = (
    permission: boolean,
    linkFunc: (params: { data: Tickets }) => string
): CellRenderer => {
    return (params) => {
        if (params.value === undefined) {
            return null;
        }

        return (
            <>
                {permission ? (
                    <Link to={linkFunc(params)}>
                        <Badge>{params.value}</Badge>
                    </Link>
                ) : (
                    <Badge>{params.value}</Badge>
                )}
            </>
        );
    };
};

export const ticketsListLoader = async ({request}: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const search = url.searchParams.get("search");
    const rawOrganizationId = getState().organizationId;
    const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
    const eventId = getEventStore().eventId;
    const user = getUserStore().user;
    console.log({organizationId, eventId});
    let where: any = {_or: search ? buildSearch(search) : undefined};
    if (organizationId && organizationId !== 0) {
        where = {
            ...where,
            event: {
                organization_id: {
                    _eq: organizationId,
                },
            },
        };
    }

    if (eventId) {
        where = {
            ...where,
            event_id: {
                _eq: eventId,
            },
        };
    } else if (user?.events) {
        where = {
            ...where,
            event_id: {
                _in: user?.events,
            },
        };
    }
    const {data} = await client.query({
        query: TICKETS_LIST_QUERY,
        variables: {
            where,
        },
    });
    console.log(data)
    return data.orders;
};

export const TicketsList = () => {
    const {editCreatePermission, ListPermission, moduleWisePermission} =
        usePermissions("tickets");
    const {editCreatePermission: attendeesProcessorEditPermission} =
        usePermissions("attendees");
    const navigate = useNavigate();
    const revalidator = useRevalidator();
    const gridRef = useRef<GridApi>();
    const rfids = useLoaderData() as Tickets[];
    const [rowData, setRowData] = useState<Tickets[]>([]);
    useEffect(() => {
        setRowData(rfids);
    }, [rfids]);
    const generateColDefs = (): ColDef<Tickets>[] => [
        {
            headerName: 'Date',
            valueGetter: (params) => params.data?.created_at,
            pinned: 'left',
            valueFormatter: (params) => {
                if (params.value === 'Total') {
                    return params.value;
                }
                const date = params.value ? moment.utc(params.value) : null;
                return date ? date.format('ddd, MMM DD, YYYY') : '';
            },
        },
        {
            headerName: "Order ID",
            valueGetter: (p) => p.data?.id,
            cellRenderer: createCellRenderer(
                editCreatePermission,
                (p) => `/tickets/${p.data?.attendee?.id}/edit`
            ),
            filter: "agTextColumnFilter",
            pinned: "left",
        },
        {
            headerName: "Reference ID",
            valueGetter: (p) => p.data?.reference_id,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Event",
            valueGetter: (p) => p.data?.event.name,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Attendee First Name",
            valueGetter: (p) => p.data?.attendee?.first_name,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Attendee Last Name",
            valueGetter: (p) => p.data?.attendee?.last_name,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Attendee Email",
            valueGetter: (p) => p.data?.email,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Attendee Phone Number",
            valueGetter: (p) => p.data?.attendee?.phone_number,
            filter: "agTextColumnFilter",
        },
        {
            headerName: "Attendee ID",
            valueGetter: (p) => p.data?.attendee?.id,
        },
        {
            headerName: "Payment Processor Reference ID",
            valueGetter: (p) => p.data?.mx_ref_id,
        }
    ];
    const colDefs: ColDef<Tickets>[] = generateColDefs();
    const rowHeight = 50;
    const autoSizeStrategy: SizeColumnsToContentStrategy = {
        type: "fitCellContents",
    };
    const handleExportCSV = () => {
        if (gridRef.current) {
            const timestamp = new Date().toISOString().replace(/[-:]/g, "");
            const params = {
                fileName: `Tickets_${timestamp}.csv`,
                columnSeparator: ",",
            };
            gridRef.current.exportDataAsCsv(params);
        }
    };
    const gridOptions = {
        enableCellTextSelection: true,
    };
    return (
        <div style={{height: "75vh"}}>
            <div
                className={"ag-theme-quartz"}
                style={{width: "100%", height: "100%"}}
            >
                <div className=" grid gap-2 grid-cols-6">
                    <Breadcrumbs/>
                    <div className="col-span-2 col-start-5 mt-2">
                        <Input
                            name="search"
                            placeholder={`Search Tickets`}
                            onChange={(s) => {
                                navigate(
                                    s && typeof s === "string" && s.length > 0
                                        ? `/tickets/list?search=${s}`
                                        : `/tickets/list`
                                );
                                revalidator.revalidate();
                            }}
                        />
                    </div>
                    <div className="py-2 col-span-2 col-start-7">
                        <SecondaryButton onClick={handleExportCSV}>
                            Export to CSV
                        </SecondaryButton>
                        {editCreatePermission && (
                            <PrimaryButton onClick={() => navigate(`/tickets/create`)}>
                                Create
                            </PrimaryButton>
                        )}
                    </div>
                </div>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowHeight={rowHeight}
                    autoSizeStrategy={autoSizeStrategy}
                    pagination={true}
                    gridOptions={gridOptions}
                    paginationPageSize={100}
                    onGridReady={(params) => (gridRef.current = params.api)}
                />
            </div>
        </div>
    );
};
