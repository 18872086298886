import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils";

interface BadgeProps {
  className?: string;
  children?: ReactNode;
  link?: string;
  removable?: boolean;
  onRemove?: () => void;
}

export function Badge(props: BadgeProps) {
  const { children, link, removable, onRemove } = props;
  return (
    <span
      className={classNames(
        removable ? "pl-2.5 pr-1.5" : "px-2.5",
        "inline-flex justify-between items-center rounded-full bg-gray-100 py-0.5 text-s font-medium text-gray-800"
      )}
    >
      {link ? <Link to={link}>{children}</Link> : children}
      {removable && (
        <XMarkIcon
          onClick={onRemove}
          className="cursor-pointer ml-1"
          height={15}
        />
      )}
    </span>
  );
}
