import { gql } from "@apollo/client";

export const RolesListQuery = gql`
  query RolesList($where: roles_bool_exp) {
    roles(where: $where, order_by: { display_name: asc }) {
      id
      name
      is_active
      display_name
      created_at
      updated_at
      description
      organization_id
      organization {
        name
      }
      user {
        username
      }
    }
  }
`;

export const RoleByPK = gql`
  query RoleById($id: Int!) {
    roles_by_pk(id: $id) {
      id
      name
      is_active
      display_name
      permissions
      description
      organization_id
      organization {
        name
      }
    }
  }
`;

export const GET_LIST_EVENTS_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: events_bool_exp!) {
    events(order_by: { name: asc }, where: $where) {
      id
      name
      location_zones
    }
  }
`;
