import gql from "graphql-tag";

export const GET_LIST_MENUS = gql`
  query GetMenus($where: menus_bool_exp!) {
    menus(order_by: { name: asc }, where: $where) {
      id
      name
      is_active
      event_id
      vendor_id
      tax_type
      __typename
    }
  }
`;

export const GET_MENU_BY_LOCATION = gql`
  query GetMenu($id: Int!) {
    menus(where: { location_menus: { location_id: { _eq: $id } } }) {
      id
      name
      is_active
      event_id
      vendor_id
      tax_type
      __typename
    }
  }
`;

export const CREATE_MENU = gql`
  {
    id
    name
    is_active
    event_id
    vendor_id
    tax_type
  }
`;

export const UPDATE_MENU = gql`
  {
    id
    name
    is_active

    event_id

    vendor_id
    tax_type
    location_menus {
      id
      menu_id
      location_id
    }

    menu_items {
      id
      item_id
      menu_id
    }
  }
`;

export const GET_ONE_MENU = gql`
  {
    id
    name
    is_active
    event_id

    vendor_id
    tax_type
    location_menus {
      id
      menu_id
      location_id
    }

    menu_items {
      id
      item_id
      menu_id
    }
  }
`;

export const DELETE_MENU = gql`
  {
    id
    event_id
    is_active
    name
    vendor_id
  }
`;

export const GET_MANY_MENU = gql`
  {
    id
    name
    is_active
    event_id
    vendor_id
    tax_type
  }
`;

export const GET_MANY_REFERENCE_MENU = gql`
  {
    id
    name
    is_active
    event_id
    vendor_id
    tax_type
  }
`;
