import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  name: string;
  href?: string;
}

export function Breadcrumb({ name, href }: BreadcrumbProps) {
  return (
    <li key={name}>
      <div className="flex items-center">
        <ChevronRightIcon
          className="h-5 w-5 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
        {href ? (
          <Link
            to={href}
            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            // aria-current={current ? 'page' : undefined}
          >
            {name}
          </Link>
        ) : (
          <span
            className="ml-4 text-sm font-medium text-gray-500"
            aria-current="page"
          >
            {name}
          </span>
        )}
      </div>
    </li>
  );
}
