import gql from "graphql-tag";

export const GET_LIST_ORGANIZATIONS = gql`
  {
    id
    name

    events {
      id
      name
      created_at
      timezone
    }
  }
`;

export const GET_ONE_ORGANIZATIONS = gql`
  {
    id
    name

    events {
      id
      name
      created_at
      timezone
    }
    users {
      id
      username
      email
      created_at
      role {
        name
      }
    }
  }
`;

export const QUERY_LIST_ORGANIZATIONS = gql`
  query ListOrganizations(
    $where: organizations_bool_exp
    $order_by: [organizations_order_by!]
  ) {
    organizations(where: $where, order_by: $order_by) {
      id
      name
    }
  }
`;
