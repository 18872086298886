import { createApolloClient } from "../../providers/ApolloClientFactory";
import { organizationStore } from "../../store/organization";
import { Item } from "../../types/item";
import { usdFormat } from "../../utils";
import { GET_ITEMS } from "./queries";
import { Badge } from "../../components/Badge";
import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import moment from "moment";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { userStore } from "../../store/user";
import ItemImportCSVModal from "../../components/importCsv/ItemImportCSVModal";

const client = createApolloClient();
const { getState } = userStore;
const { getState: getOrganizationState } = organizationStore;
const { getState: getEventStore } = eventStore;

const buildSearch = (search: string) => [
  {
    name: {
      _ilike: `%${search}%`,
    },
  },
  {
    vendor: {
      name: {
        _ilike: `%${search}%`,
      },
    },
  },
  {
    category: {
      _ilike: `%${search}%`,
    },
  },
  {
    short_name: {
      _ilike: `%${search}%`,
    },
  },
  {
    description: {
      _ilike: `%${search}%`,
    },
  },
  {
    unique_id: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Item;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Item }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};
export const itemsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(
    rawOrganizationId !== null ? rawOrganizationId.toString() : "",
    10
  );
  const eventId = getEventStore().eventId;
  const user = getState().user;

  let where: any = {
    _or: search ? buildSearch(search) : undefined,
  };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }
  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  if (user?.vendors) {
    where = {
      ...where,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }
  const { data } = await client.query({
    query: GET_ITEMS,
    variables: {
      where,
    },
  });
  return data.items;
};

export const ItemList = () => {
  const { editCreatePermission } = usePermissions("items");
  const { editCreatePermission: eventsEditPermission } =
    usePermissions("events");
  const { editCreatePermission: vendorsEditPermission } =
    usePermissions("vendors");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const items = useLoaderData() as Item[];
  const [rowData, setRowData] = useState<Item[]>([]);
  useEffect(() => {
    setRowData(items);
  }, [items]);
  const generateColDefs = (): ColDef<Item>[] => [
    {
      headerName: "Id",
      valueGetter: (p) => p.data?.id,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/items/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Item Name",
      valueGetter: (p) => p.data?.name,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/items/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Is Active?",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "Price",
      valueGetter: (p) => p.data?.price,
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
    },
    {
      headerName: "Tax Percentage",
      valueGetter: (p) => p.data?.tax_percentage,
      valueFormatter: (params) => {
        let value = parseFloat(params.value);

        if (isNaN(value)) {
          value = 0;
        }

        return value.toFixed(6);
      },
    },
    {
      headerName: "Category",
      valueGetter: (p) => p.data?.category,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "UPC",
      valueGetter: (p) => p.data?.upc,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Token Name",
      valueGetter: (p) => p.data?.redeemable_token_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Event",
      valueGetter: (p) => p.data?.event?.name,
      filter: "agTextColumnFilter",
      cellRenderer: createCellRenderer(
        eventsEditPermission,
        (p) => `/events/${p.data?.event?.id}/edit`
      ),
    },
    {
      headerName: "Vendor",
      valueGetter: (p) => p.data?.vendor?.name,
      filter: "agTextColumnFilter",
      cellRenderer: createCellRenderer(
        vendorsEditPermission,
        (p) => `/vendors/${p.data?.vendor?.id}/edit`
      ),
    },
    {
      headerName: "GL Account Name",
      valueGetter: (p) => p.data?.gl_account_name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "GL Account Number",
      valueGetter: (p) => p.data?.gl_account_number,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<Item>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Items_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Items`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/items/list?search=${s}`
                    : `/items/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            {/*<ItemImportCSVModal*/}
            {/*  // user={user}*/}
            {/*  onConfirm={() => {*/}
            {/*    // fetcher.submit(*/}
            {/*    //   {},*/}
            {/*    //   {*/}
            {/*    //     method: "delete",*/}
            {/*    //     action: `/menus/${menu.id}/delete`,*/}
            {/*    //   }*/}
            {/*    // );*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {({ setOpen }) => (*/}
            {/*    <PrimaryButton onClick={() => setOpen(true)}>*/}
            {/*      Import CSV*/}
            {/*    </PrimaryButton>*/}
            {/*  )}*/}
            {/*</ItemImportCSVModal>*/}
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/items/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
