import { gql } from "@apollo/client";

export const RFID_LIST_QUERY = gql`
  query RfidListQuery($where: rfid_assets_bool_exp!) {
    rfid_assets(where: $where, order_by: { uid: asc }) {
      id
      uid
      is_active
      last_four_phone_numbers
      event_id
      attendee_id
      reg_id
      tokens_balance
      access_type
      cash_balance
      promo_balance
      created_at
      updated_at
      notes
      event {
        id
        name
      }
      attendee {
        id
        first_name
        last_name
      }
      is_qr
      user {
        username
      }
    }
  }
`;

export const RFID_BY_ID_QUERY = gql`
  query RfidListQuery($id: Int!) {
    rfid_assets(where: { id: { _eq: $id } }) {
      id
      uid
      is_active
      last_four_phone_numbers
      event_id
      reg_id
      attendee_id
      access_type
      tokens_balance
      promo_balance
      cash_balance
      notes
      attendee {
        id
        first_name
        last_name
      }
    }
  }
`;
