import { Badge } from "../../components/Badge";
import {
  Link,
  useLoaderData,
  LoaderFunctionArgs,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { organizationStore } from "../../store/organization";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { RFID } from "../../types/rfid";
import { RFID_LIST_QUERY } from "./queries";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import { usdFormat } from "../../utils";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
  ICellRendererParams,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import moment from "moment/moment";
import { userStore } from "../../store/user";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;
const { getState: getUserStore } = userStore;

const buildSearch = (search: string) => [
  {
    uid: {
      _ilike: `%${search}%`,
    },
  },
  {
    last_four_phone_numbers: {
      _ilike: `%${search}%`,
    },
  },
  {
    reg_id: {
      _ilike: `%${search}%`,
    },
  },
  {
    notes: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: RFID;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: RFID }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }

    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};

export const rfidsListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getUserStore().user;

  let where: any = { _or: search ? buildSearch(search) : undefined };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }

  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  const { data } = await client.query({
    query: RFID_LIST_QUERY,
    variables: {
      where,
    },
  });
  return data.rfid_assets;
};

export const RFIDAssetList = () => {
  const { editCreatePermission, ListPermission, moduleWisePermission } =
    usePermissions("cashless_assets");
  const { editCreatePermission: attendeesProcessorEditPermission } =
    usePermissions("attendees");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const rfids = useLoaderData() as RFID[];
  const [rowData, setRowData] = useState<RFID[]>([]);
  useEffect(() => {
    setRowData(rfids);
  }, [rfids]);
  const generateColDefs = (): ColDef<RFID>[] => [
    {
      headerName: "Unique ID",
      valueGetter: (p) => p.data?.uid,
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/rfid-assets/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Print ID",
      valueGetter: (p) => p.data?.reg_id,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Asset Type",
      valueGetter: (p) => p.data?.is_qr,
      valueFormatter: (p) => {
        return p.data?.is_qr ? "QR" : "RFID";
      },
    },
    {
      headerName: "Access Type",
      valueGetter: (p) => p.data?.access_type,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Attendee Linked",
      valueGetter: (p) => p.data?.is_active,
      valueFormatter: (p) => {
        return p.data?.is_active ? "Yes" : "No";
      },
    },
    {
      headerName: "PIN",
      valueGetter: (p) => p.data?.last_four_phone_numbers,
      valueFormatter: (p) => {
        const phoneNumber = p.value;
        if (phoneNumber && phoneNumber.length >= 2) {
          const hiddenDigits = phoneNumber.slice(0, -2).replace(/\d/g, "*");
          const visibleDigits = phoneNumber.slice(-2);
          return hiddenDigits + visibleDigits;
        } else {
          return phoneNumber;
        }
      },
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Promo Balance",
      valueGetter: (params) => {
        return params.data?.promo_balance;
      },
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
    },
    {
      headerName: "Cash Balance",
      valueGetter: (params) => {
        return params.data?.cash_balance;
      },
      valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
    },
    {
      headerName: "Token Balance",
      valueGetter: (params) => {
        const tokensBalance = params?.data?.tokens_balance;
        console.log("tokensBalance:", tokensBalance); // Log the tokensBalance object
        if (tokensBalance && Object.keys(tokensBalance).length > 0) {
          const tokenStrings = Object.values<any>(tokensBalance).map(
            (token) => `${token.redeemable_token_name} - ${token.balance}`
          );
          return tokenStrings.join(", ");
        }
        return null;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const tokensBalance = params.value;

        if (tokensBalance) {
          return <div>{tokensBalance}</div>;
        }
        return null;
      },
    },
    {
      headerName: "Attendee",
      valueGetter: (p) => {
        const firstName = p.data?.attendee?.first_name;
        const lastName = p.data?.attendee?.last_name;
        if (firstName === undefined || lastName === undefined) {
          return undefined;
        }
        return `${firstName} ${lastName}`.trim();
      },
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/attendees/${p.data.attendee_id}/edit`
      ),
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Notes",
      valueGetter: (p) => p.data?.notes,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Updated By",
      valueGetter: (p) => p.data?.user?.username,
    },
    {
      headerName: "Created Date",
      valueGetter: (p) => p.data?.created_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
    {
      headerName: "Updated Date",
      valueGetter: (p) => p.data?.updated_at,
      valueFormatter: (p) => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format("MM/DD/YYYY h:mm:ss a") : "";
      },
    },
  ];
  const colDefs: ColDef<RFID>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `CashlessAssets_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Cashless Assets`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/rfid-assets/list?search=${s}`
                    : `/rfid-assets/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/rfid-assets/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
