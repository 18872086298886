import { Link, useMatches } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/20/solid";
import { ReactNode } from "react";

interface Matches {
  id: string;
  pathname: string;
  params: unknown;
  data: unknown;
  handle: {
    crumb: (data: unknown) => ReactNode;
  };
}

export function Breadcrumbs() {
  let matches = useMatches() as Matches[];
  let crumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter((match) => Boolean(match.handle?.crumb))
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => match.handle.crumb(match.data));

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4 pl-2 md:p-0">
        <li>
          <div>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </div>
        </li>
        {crumbs.map((crumb, index) => (
          <li key={index}>{crumb}</li>
        ))}
      </ol>
    </nav>
  );
}
