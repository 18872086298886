import { gql } from "@apollo/client";

export const VENDORS_LIST_QUERY = gql`
  query vendors($where: vendors_bool_exp) {
    vendors(where: $where, order_by: { name: asc }) {
      id
      name
      organization_id
      organization {
        name
      }
      created_at
      updated_at
      poc_name
      poc_email
      poc_phone
      type
      other_type
      is_active
      revenue_share
      sales_tax_responsible
      flat_fee
      user {
        username
      }
      __typename
    }
  }
`;

export const VENDOR_BY_ID_QUERY = gql`
  query vendors_by_pk($id: Int!) {
    vendors_by_pk(id: $id) {
      id
      name
      organization_id
      organization {
        name
      }
      created_at
      updated_at
      type
      other_type
      poc_name
      poc_email
      poc_phone
      is_active
      revenue_share
      sales_tax_responsible
      flat_fee
      __typename
    }
  }
`;

export const LOCATION_BY_VENDOR_ID_QUERY = gql`
  query locations($id: Int!) {
    locations(where: { vendor_id: { _eq: $id } }) {
      id
      event {
        id
        name
      }
      vendor_id
      is_active
      name
      vendor {
        id
        name
      }
      location_type
      zone
      created_at
      updated_at
      dynamic_descriptor
      digital_surcharge_percentage
      payment_processor_config {
        id
        payment_processor
        __typename
      }
      location_menus {
        id
        menu_id
        location_id
        menu {
          name
          id
        }
        __typename
      }
      __typename
    }
  }
`;

export const MENUS_BY_VENDOR_ID_QUERY = gql`
  query GetMenus($id: Int!) {
    menus(where: { vendor_id: { _eq: $id } }, order_by: { name: asc }) {
      id
      name
      is_active
      updated_at
      created_at
      event_id
      event {
        name
        id
      }
      vendor_id
      vendor {
        name
        id
      }
      is_cash
      is_credit
      is_rfid
      is_tips
      is_qr
      is_tappit
      is_custom_item
      tip_percentage_1
      tip_percentage_2
      tip_percentage_3
      is_discount
      is_discount_protected
      is_cash_not_taxed
      processing_mode
      tax_type
      menu_items {
        item {
          name
        }
      }
      location_menus {
        location {
          id
          name
        }
      }

      __typename
    }
  }
`;

export const ITEMS_BY_VENDOR_ID_ITEMS = gql`
  query items($id: Int!) {
    items(where: { vendor_id: { _eq: $id } }, order_by: { name: asc }) {
      created_at
      description
      id
      is_active
      name
      tags
      price
      short_name
      unique_id
      updated_at
      upc
      event_id
      vendor_id
      redeemable_token_name
      tax
      tax_percentage
      menu_items {
        menu {
          tax_type
        }
      }
      event {
        id
        name
      }
      vendor {
        id
        name
      }
    }
  }
`;

export const VENDOR_CATAGORIES = gql`
  query vendor_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`;