import { gql } from '@apollo/client';

export const DELETE_LOCATION_MENUS = gql`
mutation DeleteLocationMenus($id: Int!) {
  delete_location_menu(
    where: {
      menu_id: {
        _eq: $id
      }
    }
  ) {
    returning {
      id
    }
  }
}
`;

export const DELETE_MENU_ITEMS = gql`
  mutation DeleteMenuItems($id: Int!) {
    delete_menu_item(
      where: {
        menu_id: {
          _eq: $id
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const CREATE_LOCATION_MENUS = gql`
  mutation CreateLocationMenus($objects: [location_menu_insert_input!]!) {
    insert_location_menu(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const CREATE_MENU_ITEMS = gql`
  mutation CreateMenuItems($objects: [menu_item_insert_input!]!) {
    insert_menu_item(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const CREATE_MENU = gql`
  mutation create_menus($input: menus_insert_input!) {
    insert_menus_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_MENU = gql`
  mutation update_menus($input: menus_set_input, $id: Int!) {
    update_menus_by_pk(_set: $input, pk_columns: {id: $id}) {
      id
    }
  }
`;

export const DELETE_MENU = gql`
  mutation delete_menus($id: Int!) {
    delete_menus_by_pk(id: $id) {
      id
    }
  }
`;