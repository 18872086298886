import React, { useState } from 'react';
import DownloadButton from "../components/DownloadButton";
import { TransactionReportRow } from "../transactions-report/type";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

interface ReportHeaderProps {
    title: string;
    toolTipShow?: boolean;
    tooltipText?: string;
}

export default function ReportHeader({
                                         title,
                                         tooltipText,
                                         toolTipShow,
                                     }: ReportHeaderProps) {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <div className="pt-4 relative">
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <h3 className="text-2xl font-bold">{title}</h3>
                    {toolTipShow && (
                    <span
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setTimeout(() => setShowTooltip(false), 300)}
                        className="cursor-pointer ml-2 relative"
                    >
            <FontAwesomeIcon icon={faQuestionCircle} size="1x" />
                        {showTooltip && (
                            <div
                                style={{ zIndex: 1000 }}
                                className="absolute top-full left-1/2 transform -translate-x-1/2  ml-2 mt-2 p-2 border rounded shadow bg-gray-500 text-sm font-medium text-white w-96"
                            >
                                {tooltipText}
                            </div>
                        )}
          </span>
                        )}
                </div>
            </div>
        </div>
    );
}