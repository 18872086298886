import { ColumnDef } from "@tanstack/react-table";
import { usdFormat } from "../../../utils";
import { FinancialHourlyReportRow } from "./type";
import moment from "moment";
import {ColDef} from "ag-grid-community";

const formatHour = (hour: number): string => {
    const period = hour < 12 || hour === 24 ? 'am' : 'pm';
    const formattedHour = (hour % 12) || 12;
    return `${formattedHour}:00${period}`;
};
const generateColDefs = (): ColDef<FinancialHourlyReportRow>[] => [
    {
        headerName: 'Date',
        valueGetter: p => p.data?.display_date,
        pinned: 'left',
        cellStyle: params => {
            if (params.value === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((5) % 24)} - ${formatHour((5 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_5,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((6) % 24)} - ${formatHour((6 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_6,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((7) % 24)} - ${formatHour((7 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_7,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((8) % 24)} - ${formatHour((8 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_8,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((9) % 24)} - ${formatHour((9 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_9,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((10) % 24)} - ${formatHour((10 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_10,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((11) % 24)} - ${formatHour((11 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_11,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((12) % 24)} - ${formatHour((12 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_12,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((13) % 24)} - ${formatHour((13 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_13,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((14) % 24)} - ${formatHour((14 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_14,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((15) % 24)} - ${formatHour((15 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_15,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((16) % 24)} - ${formatHour((16 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_16,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((17) % 24)} - ${formatHour((17 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_17,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((18) % 24)} - ${formatHour((18 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_18,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((19) % 24)} - ${formatHour((19 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_19,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((20) % 24)} - ${formatHour((20 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_20,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((21) % 24)} - ${formatHour((21 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_21,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((22) % 24)} - ${formatHour((22 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_22,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((23) % 24)} - ${formatHour((23 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_23,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((24) % 24)} - ${formatHour((24 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_24,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((25) % 24)} - ${formatHour((25 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_25,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((26) % 24)} - ${formatHour((26 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_26,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((27) % 24)} - ${formatHour((27 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_27,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
    {
        headerName: `${formatHour((28) % 24)} - ${formatHour((28 + 1) % 24)}`,
        valueGetter: p => p.data?.hour_28,
        valueFormatter: params => usdFormat.format(params.value ?? 0),
        cellStyle: params => {
            if (params.data?.username === 'Total') {
                return { fontWeight: 'bold' };
            }
            return null;
        }
    },
];

export const colDefs: ColDef<FinancialHourlyReportRow>[] = generateColDefs();

export const hourColumns: ColumnDef<FinancialHourlyReportRow>[] = Array.from({ length: 24 }, (_, idx) => {
    const hour = (5 + idx);
    const displayHour = (5 + idx) % 24;
    return {
        accessorKey: `hour_${hour}`,
        header: `${formatHour((displayHour) % 24)} - ${formatHour((displayHour + 1) % 24)}`,
        cell: (info) => usdFormat.format(info.getValue<number>() ?? 0),
    };
});

export const columns: ColumnDef<FinancialHourlyReportRow>[] = [
    {
        accessorKey: "display_date",
        header: "Date",
        cell: (info) => info.getValue<string>() ?? "",
    },
    ...hourColumns,
];

export const formatData = (data: FinancialHourlyReportRow[]) => {
    if (!data) return [];

    const initialGr: Record<string, FinancialHourlyReportRow & { [key: string]: number }> = {};

    const [groupedRow] = data.reduce(
        (acc, curr) => {
            const [groupedRow] = acc;
            const hourString = curr.adjusted_transaction_hour?.split(' ')[1]?.split(':')[0];
            const hour = parseInt(hourString, 10);

            const dateString = curr.adjusted_transaction_hour?.split(' ')[0];
            const dateMoment = moment(dateString, 'YYYY-MM-DD');
            const truncatedDate = dateMoment.format('YYYY-MM-DD');

            const total_net_sales = ((curr.gross_cash_balance_sales + curr.gross_cash_sales + curr.gross_credit_sales + curr.gross_promo_balance_sales + curr.gross_rfid_sales + curr.gross_token_sales) / 100) -
                ((curr.gross_token_sales + curr.net_refunds + curr.net_promo_balance_sales + curr.total_discounts + curr.total_promo_discounts + curr.total_tax) / 100);

            if (!groupedRow[truncatedDate]) {
                (groupedRow[truncatedDate] as any) = {
                    ...curr,
                    id: truncatedDate,
                    display_date: moment(truncatedDate).utc().format("ddd, MMM DD, YYYY"),
                };
                for (let i = 5; i <= 28; i++) {
                    groupedRow[truncatedDate][`hour_${i}`] = 0;
                }
            }

            const currentHourSales = total_net_sales ?? 0;

            if (hour >= 5 && hour <= 28) {
                if (groupedRow[truncatedDate][`hour_${hour}`] == null) {
                    groupedRow[truncatedDate][`hour_${hour}`] = 0;
                }
                groupedRow[truncatedDate][`hour_${hour}`] += currentHourSales;
            }

            return [groupedRow];
        },
        [initialGr]
    );

    return Object.values(groupedRow);
};

