import gql from 'graphql-tag';

export const GET_LIST_ORDERS = gql`
  {
    id
    discount
    subtotal
    digital_surcharge
    tax
    tip
    status
    phone_number
    user_id
    created_at
    transaction_at
    reference_id
    attendee_id
    transaction_time
    device_app_id
    
    attendee {
      rfid_assets {
        uid
      }
    }
    uid
    event {
      name
    }

    location {
      name
    }

    reference_id

    items

    user {
      id
      username
      phone_number
      email
    }

    menu_id
    menu {
      name
    }
    vendor_id
    vendor {
      name
    }
    tokens_redeemed
    mx_ref_id
    transactions {
      payment {
        amount
        id
        payment_data
        payment_type
        reference_id
        status
      }
      amount
      status
      id
      note
    }
  }
`;

export const GET_MANY_ORDERS = gql`
  {
    id
    discount
    subtotal
    digital_surcharge
    tax
    tip
    status
    phone_number
    user_id
    created_at
    transaction_at
    reference_id
    attendee_id
    transaction_time
    tokens_redeemed
    mx_ref_id
    attendee {
      rfid_assets {
        uid
      }
    }
    uid
    menu_id
    menu {
      name
    }
    vendor_id
    vendor {
      name
    }

    reference_id
    device_app_id

    event {
      name
    }

    location {
      name
    }

    items

    user {
      id
      username
      email
      phone_number
    }

    transactions {
      payment {
        amount
        id
        payment_data
        payment_type
        reference_id
        status
      }
      amount
      status
      id
      note
    }
  }
`;

export const UPDATE_ORDER = gql`
  {
    id
    discount
    subtotal
    digital_surcharge
    tax
    tip
    status
    phone_number
    items
    
    user_id
    created_at
    transaction_at
    reference_id
    attendee_id
    transaction_time
    mx_ref_id
    menu_id
    menu {
      name
    }
    vendor_id
    vendor {
      name
    }

    reference_id
    device_app_id

    user {
      id
      username
      email
    }
  }
`;

export const CREATE_ORDER = gql`
  {
    id
    discount
    subtotal
    digital_surcharge
    tax
    tip
    status
    phone_number
    items
    
    menu_id
    menu {
      name
    }
    vendor_id
    vendor {
      name
    }

    user_id
    created_at
    transaction_at
    reference_id
    attendee_id
    transaction_time

    reference_id
    device_app_id

    user {
      id
      username
      email
    }
  }
`;

export const GET_ONE_ORDER = gql`
  {
    id
    discount
    subtotal
    digital_surcharge
    tax
    tip
    status
    attendee {
      rfid_assets {
        uid
      }
    }
    uid
    menu_id
    menu {
      name
    }
    vendor_id
    vendor {
      name
    }

    event {
      name
    }

    location {
      name
    }
    mx_ref_id
    items
    phone_number
    user_id
    created_at
    transaction_at
    reference_id
    attendee_id
    transaction_time
    tokens_redeemed
    reference_id
    device_app_id

    user {
      id
      username
      phone_number
      email
    }

    transactions {
      payment {
        amount
        id
        payment_data
        payment_type
        reference_id
        status
      }
      amount
      status
      id
      note
    }
  }
`;
