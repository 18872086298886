// import necessary modules and dependencies
import { CardsOnFileRow } from "./type";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/Badge";
import { ColDef } from "ag-grid-community";
import React from 'react';
import {cardOnFileAction} from "./CardOnFileActions";


// Define the button cell renderer
const ButtonCellRenderer = (props: any) => {
  const handleButtonClick = async () => {
    const { id } = props.data;
    await cardOnFileAction({ id });
  };

  return <button onClick={handleButtonClick}
                 className="rounded-md border border-gray-300 bg-primary-600  py-1 px-2 text-sm font-medium shadow-sm text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >Disable Card</button>;
};

// Define other cell renderers
type CellRenderer = (params: { value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; data: CardsOnFileRow; }) => JSX.Element;

const createCellRenderer = (permission: boolean, linkFunc: (params: { data: CardsOnFileRow }) => string): CellRenderer => {
  return (params) => (
      <>
        {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
        ) : (
            <Badge>{params.value}</Badge>
        )}
      </>
  );
};

// Generate column definitions
const generateColDefs = (): ColDef<CardsOnFileRow>[] => [
  {
    headerName: 'Last Four Digits',
    valueGetter: p => p.data?.last_four_digits,
    valueFormatter: (p) => {
      return "**** **** **** " + p.data?.last_four_digits;
    },
  },
  {
    headerName: "Is Active?",
    valueGetter: (p) => p.data?.is_active,
    valueFormatter: (p) => {
      return p.data?.is_active ? "Yes" : "No";
    },
  },
  {
    headerName: "Actions",
    cellRenderer: ButtonCellRenderer, // Use the button cell renderer
  }
];

export const cardsOnFileColDefs: ColDef<CardsOnFileRow>[] = generateColDefs();
