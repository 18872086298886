import { ReactNode } from "react";
import { FormFooter } from "../components/FormFooter";

interface FormLayoutProps {
    children: ReactNode;
    isTopSave?: boolean; // Add the isMenuEdit prop
}

export function FormLayout({ children, isTopSave }: FormLayoutProps) {
    return (
        <div className="shadow sm:rounded-md bg-white px-4 py-5 sm:p-6">
            {isTopSave && <FormFooter />} {/* Render FormFooter only if isMenuEdit is true */}
            <div className="grid grid-cols-6 gap-6">{children}</div>
            <FormFooter /> {/* Render FormFooter only if isMenuEdit is true */}
        </div>
    );
}