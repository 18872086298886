import { toast } from "react-hot-toast";
import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { requireAuth } from "../../modules/auth/RequireAuth";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Event } from "../../types/event";
import {
  EventCreate,
  eventCreateAction,
  eventCreateLoader,
} from "./EventCreate";
import { EventEdit, eventEditAction, eventEditLoader } from "./EventEdit";
import { EventsList, eventsListLoader } from "./EventList";
import { DELETE_EVENT } from "./mutation";
const client = createApolloClient();

const EventsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/events") {
    navigate(`/events/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const eventDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_EVENT,
      variables: {
        id,
      },
    });
    toast.success("Event deleted");
    return redirect(`/events/list`);
  } catch {
    toast.error("Failed to delete event");
  }
};

export const eventsRouter = {
  path: "/events",
  id: "events",
  element: <EventsIndex />,
  loader: requireAuth(),
  handle: {
    crumb: () => <Breadcrumb name="Events" href="/events/list" />,
  },
  children: [
    {
      path: "list",
      id: "events-list",
      element: <EventsList />,
      loader: eventsListLoader,
    },
    {
      path: "create",
      id: "events-create",
      element: <EventCreate />,
      loader: eventCreateLoader,
      action: eventCreateAction,
      handle: {
        crumb: () => <Breadcrumb name="Create Event" href="/events/create" />,
      },
    },
    {
      path: ":id/edit",
      id: "events-edit",
      element: <EventEdit />,
      loader: eventEditLoader,
      action: eventEditAction,
      handle: {
        crumb: (data: { event: Event }) => (
          <Breadcrumb
            name={`Edit ${data.event.name}`}
            href={`/events/${data.event.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "events-delete",
      action: eventDeleteAction,
    },
  ],
};
