import { Form, ActionFunctionArgs, redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { CREATE_ROLE } from "./mutations";
import { useEffect, useState } from "react";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { adminModules, modules, modulesLabel } from "../../utils";
import { userStore } from "../../store/user";

const client = createApolloClient();
type Event = {
  name: string;
  id: string;
};
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const roleCreateAction = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();
  const user = getUserStore().user;

  const permissions = body.get("permissions");
  const parsePermissions =
    permissions && typeof permissions === "string"
      ? JSON.parse(permissions)
      : {};
  try {
    await client.mutate({
      mutation: CREATE_ROLE,
      variables: {
        name: body.get("name"),
        display_name: body.get("display_name"),
        permissions: parsePermissions,
        description: body.get("description"),
        organization_id: body.get("organization_id"),
        is_active: true,
        last_updated_by: user?.id,
      },
    });
    toast.success("Roles created");
    return redirect(`/roles/list`);
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    if (errorType === "constraint-violation") {
      toast.error("role already created");
    } else {
      toast.error("Unable to create role:" + error);
    }
    return redirect(`/roles/create`);
  }
};

export const RoleCreate = () => {
  const [selectedPermissions, setSelectedPermissions] = useState<any>({});
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const organizations = useOrganizationStore((state) => state.organizations);
  return (
    <Form action="/roles/create" method="post">
      <FormLayout>
        <div className="col-span-4">
          <Input label="Role Name" name="display_name" required />
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={true}
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={organizationId}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <input type="hidden" value={"dashboard_user"} name="name" />
        <div className="col-span-6">
          <TextArea label="Description" name="description" required />
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <input
            name="permissions"
            type="hidden"
            value={JSON.stringify(selectedPermissions)}
          />
          <label className="col-span-5 block text-lg font-medium text-gray-700">
            Permissions
          </label>

          <div className="col-span-6">
            {Object.keys(permissions === "admin" ? adminModules : modules)?.map(
              (module: string) => (
                <>
                  <div className="col-span-6">
                    <label className="col-span-1 block text-md pb-2 font-medium text-gray-700">
                      {modulesLabel[module]}
                    </label>
                  </div>
                  <div className="bg-gray-300 col-span-6 grid grid-cols-6 rounded p-2">
                    {(permissions === "admin" ? adminModules : modules)[
                      module
                    ]?.map((moduleData: { label: string; value: string }) => (
                      <IndeterminateCheckbox
                        checked={(selectedPermissions[module] || [])?.filter(
                          (permissionData: any) =>
                            permissionData?.value === moduleData?.value
                        )}
                        label={moduleData?.label}
                        name={moduleData?.value}
                        onChange={(data) => {
                          const selectPermissionByType =
                            selectedPermissions[module] || [];
                          let currentModulePresent = [
                            ...selectPermissionByType,
                          ];
                          if (selectPermissionByType?.length > 0) {
                            if (!data) {
                              const modulePresent =
                                currentModulePresent?.filter(
                                  (currentModule) =>
                                    currentModule?.value === moduleData?.value
                                );
                              if (modulePresent?.length > 0) {
                                currentModulePresent =
                                  currentModulePresent?.filter(
                                    (currentModule) =>
                                      currentModule?.value !== moduleData?.value
                                  );
                              } else {
                                currentModulePresent.push(moduleData);
                              }
                            } else {
                              currentModulePresent.push(moduleData);
                            }
                          } else {
                            currentModulePresent.push(moduleData);
                          }
                          console.log({
                            data,
                            selectPermissionByType,
                            module,
                            name: moduleData.value,
                            moduleData,
                            currentModulePresent,
                          });
                          setSelectedPermissions({
                            ...selectedPermissions,
                            [module]: currentModulePresent,
                          });
                        }}
                      />
                    ))}
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </FormLayout>
    </Form>
  );
};
