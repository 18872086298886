import { Outlet, Navigate, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { useUserStore } from "../store/user";
import { ResourceLayout } from "./ResourceLayout";

export const Layout = () => {
  const location = useLocation();
  const user = useUserStore((state) => state.user);

  if (location.pathname === "/") {
    return <Navigate to="/dashboard" />;
  }

  if (!user) {
    return (
      <>
        <Outlet />
      </>
    );
  }

  return (
    <Sidebar>
      <ResourceLayout>
        <Outlet />
      </ResourceLayout>
    </Sidebar>
  );
};
