import gql from 'graphql-tag';

export const GET_LIST_CARD_ON_FILES = gql`
  {
    id
    last_four_digits
    payment_data
    payment_processor
    card_holder_name
    card_type
    is_active
    event_id
    attendee_id

    attendee {
      id
    }
  }
`;

export const INSERT_CARD_ON_FILES = gql`
  {
    id
    last_four_digits
    payment_data
    payment_processor
    card_holder_name
    card_type
    is_active
    event_id
    attendee_id

    attendee {
      id
    }
  }
`;

export const UPDATE_CARD_ON_FILES = gql`
  {
    id
    last_four_digits
    payment_data
    payment_processor
    card_holder_name
    card_type
    is_active
    event_id
    attendee_id

    attendee {
      id
    }
  }
`;

export const GET_ONE_CARD_ON_FILES = gql`
  {
    id
    last_four_digits
    payment_data
    payment_processor
    card_holder_name
    card_type
    is_active
    event_id
    attendee_id

    attendee {
      id
    }
  }
`;

export const GET_MANY_CARD_ON_FILES = gql`
  {
    id
    last_four_digits
    payment_data
    payment_processor
    card_holder_name
    card_type
    is_active
    event_id
    attendee_id

    attendee {
      id
    }
  }
`;
