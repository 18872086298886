import { gql } from '@apollo/client'

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: locations_insert_input!) {
    insert_locations_one(object: $input) {
      id
    }
  }
`

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: Int!, $input: locations_set_input) {
    update_locations_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`

export const CREATE_LOCATION_MENUS = gql`
  mutation CreateLocationMenus($objects: [location_menu_insert_input!]!) {
    insert_location_menu(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const DELETE_LOCATION_MENUS = gql`
  mutation DeleteLocationMenus($id: Int!) {
    delete_location_menu(
      where: {
        location_id: {
          _eq: $id
        }
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: Int!) {
    delete_locations_by_pk(id: $id) {
      id
    }
  }
`;