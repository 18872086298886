import React from 'react';
import {
  LoaderFunctionArgs,
  useLoaderData,
} from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';

import { GET_PROMO_CODE } from '../promo-code/queries';
import { createApolloClient } from '../../providers/ApolloClientFactory';
import { PromoCode } from '../../types/promoCode';

const client = createApolloClient();

export const QrCodeDisplayLoader = async ({ params }: LoaderFunctionArgs) => {
  const { uid } = params;
  console.log({ uid });
  const { data } = await client.query({
    query: GET_PROMO_CODE,
    variables: {
      where: {
        uid: {
          _eq: uid,
        },
      },
    },
  });

  return {
    data: data.promo_code?.[0],
    uid
  };
};

export const QrCodeDisplay = () => {
  const { data, uid } = useLoaderData() as {
    data: PromoCode;
    uid: string;
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        background: 'white',
        padding: '16px',
        height: '100vh',
      }}
    >
      <div>
        {data ? (
            <div>
              <h1 className="text-center text-2xl mb-2">
                <strong>{data?.event?.name}</strong> for{' '}
                <strong>{data?.description}</strong> - Promo Code!
              </h1>
              <p className="text-center">
                Display the below QR Code at the Point-Of-Sale Terminal
              </p>
              <div>
                <QRCodeSVG value={uid} width={'30vw'} height={'40vw'} />
              </div>
            </div>
        ) : (
            <div>
              <p className="text-center">
                See your Ticket QR Code Below:
              </p>
              <QRCodeSVG value={uid} width={'30vw'} height={'40vw'} />
            </div>
        )}
      </div>
    </div>
  );
};
