import { gql } from "@apollo/client";

export const CREATE_ITEM = gql`
  mutation create_item($input: items_insert_input!) {
    insert_items_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_ITEM = gql`
  mutation update_items($input: items_set_input, $where: items_bool_exp!) {
    update_items(_set: $input, where: $where) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_ITEM = gql`
   mutation DeleteItem($id: Int!) {
    delete_items_by_pk(id: $id) {
      id
    }
  }
`;
