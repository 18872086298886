import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { requireAuth } from "../auth/RequireAuth";
import {OrdersList, ordersListLoader} from "./OrdersList";
import { orderShowLoader, OrderShow } from "./OrderShow";

const OrdersIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/orders") {
    navigate(`/orders/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const ordersRouter = {
  path: "/orders",
  id: "orders",
  element: <OrdersIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Orders" href="/orders/list" />,
  },
  loader: requireAuth(),
  children: [
    {
      path: "list",
      id: "orders-list",
      element: <OrdersList />,
      loader: ordersListLoader,
    },
    {
      path: ":reference_id/:id",
      id: "order-show",
      element: <OrderShow />,
      loader: orderShowLoader,
    },
  ],
};
