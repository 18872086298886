import {
  Form,
  ActionFunctionArgs,
  redirect,
  useLoaderData,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { CREATE_ORGANIZATION_USER } from "./mutations";
import { useEffect, useState } from "react";
import { GET_LIST_VENDORS_BY_ORGANIZATION } from "../../queries";
import { Vendor } from "../../types/vendor";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "./queries";
import { RolesListQuery } from "../roles/queries";
import { Roles } from "../roles/type";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { useUserStore, userStore } from "../../store/user";

const client = createApolloClient();
type Event = {
  name: string;
  id: string;
};
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const userCreateLoader = async () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizationId = getState().organizationId;
  const user = getUserStore().user;

  let vendorWhere = {};
  if (organizationId) {
    vendorWhere = {
      ...vendorWhere,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (user?.vendors) {
    vendorWhere = {
      ...vendorWhere,
      id: {
        _in: user?.vendors,
      },
    };
  }
  const [{ data: vendors }, { data: roles }] = await Promise.all([
    client.query({
      query: GET_LIST_VENDORS_BY_ORGANIZATION,
      variables: {
        where: vendorWhere,
      },
    }),
    client.query({
      query: RolesListQuery,
      variables: {
        where: {
          is_active: { _eq: true },
          organization_id: organizationId
            ? { _in: [0, organizationId] }
            : { _in: [0] },
          ...(permissions !== "admin" && { id: { _neq: 1 } }),
        },
      },
    }),
  ]);
  return {
    vendors: vendors.vendors,
    roles: roles?.roles,
  };
};

export const userCreateAction = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();
  const user = getUserStore().user;
  const eventIds = body.getAll("event_id");
  const vendorIds = body.getAll("vendor_id");
  const events = eventIds.length > 0 && eventIds[0] !== "" ? eventIds : null;
  const vendors = vendorIds.length > 0 && vendorIds[0] !== "" ? vendorIds : null;
  try {
    await client.mutate({
      mutation: CREATE_ORGANIZATION_USER,
      variables: {
        password_hash: body.get("password"),
        organizationId: body.get("organization_id"),
        roleId: body.get("role_id"),
        username: body.get("username"),
        email: body.get("email") || null,
        first_name: body.get("first_name") || null,
        last_name: body.get("last_name") || null,
        phoneNumber: body.get("phone_number") || null,
        validationCode: body.get("validation_code"),
        vendor: vendors,
        tabletAccessCode: body.get("tablet_access_code") || null,
        events: events,
        is_active: body.get("is_active"),
        last_updated_by: user?.id,
      },
    });
    toast.success("User created");
    return redirect(`/users/list`);
  } catch (error: any) {
    const errorType = error?.graphQLErrors?.[0]?.extensions?.code;
    if (errorType === "constraint-violation") {
      toast.error("user already created");
    } else {
      toast.error("Unable to create user:" + error);
    }
    return redirect(`/users/create`);
  }
};

export const UserCreate = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizations = useOrganizationStore((state) => state.organizations);
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const user = useUserStore((state) => state.user);

  const [selectedOrg, setSelectedOrg] = useState(organizationId);
  const [eventOptions, setEventOptions] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState<string[] | null>();
  console.log({ selectedEventId });
  const { vendors, roles } = useLoaderData() as {
    vendors: Vendor[];
    roles: Roles[];
  };

  const [selectedRole, setSelectedRole] = useState<number>(roles[0].id);

  useEffect(() => {
    const fetchEvents = async () => {
      if (selectedOrg) {
        try {
          let where: any = {
            organization_id: { _eq: selectedOrg },
          };
          if (user?.events) {
            where = {
              ...where,
              id: {
                _in: user?.events,
              },
            };
          }
          const { data } = await client.query({
            query: GET_LIST_EVENTS_BY_ORGANIZATION,
            variables: {
              where,
            },
            fetchPolicy: "network-only",
          });

          if (data && data.events) {
            const newEventOptions = data.events.map((event: Event) => ({
              label: event.name,
              value: event.id,
            }));
            setEventOptions(newEventOptions);
            setSelectedEventId(null);
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        }
      } else {
        setEventOptions([]);
      }
    };
    fetchEvents();
  }, [selectedOrg]);

  const handleEventSelect = (eventId: string[]) => {
    setSelectedEventId(eventId);
  };
  return (
    <Form action="/users/create" method="post">
      <FormLayout>
        <div className="col-span-4">
          <Input label="Username" name="username" required />
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
            name="is_active"
            label="Is Active"
            defaultChecked={true}
          />
        </div>
        <div className="col-span-6">
          <Input label="Password" name="password" required />
        </div>
        <div className="col-span-6">
          <Input label="First Name" name="first_name" />
        </div>
        <div className="col-span-6">
          <Input label="Last Name" name="last_name" />
        </div>
        <div className="col-span-6">
          <Input label="Email" name="email" type="email" />
        </div>
        <div className="col-span-6">
          <Input label="Phone Number" name="phone_number" />
        </div>
        <div className="col-span-6">
          <Input
            label="Tablet Access Code"
            name="tablet_access_code"
            defaultValue="22425"
            required
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={organizationId}
              onChange={(v) => {
                setSelectedOrg(v);
                setEventOptions([]);
                setSelectedEventId(null);
              }}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6">
          <CustomSelect
            title="Role"
            name="role_id"
            required
            onChange={(v) => setSelectedRole(v)}
            options={[
              ...roles.map((role) => ({
                label: role.display_name,
                value: role.id,
              })),
            ]}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Restrict to Event(s)"
            name="event_id"
            multi
            defaultValue={selectedEventId}
            onChange={handleEventSelect}
            options={eventOptions}
          />
        </div>
        <div className="col-span-6">
          <CustomSelect
            title="Restrict to Vendor(s)"
            name="vendor_id"
            multi
            options={[
              ...vendors.map((vendor) => ({
                label: vendor.name,
                value: vendor.id,
              })),
            ]}
          />
        </div>
      </FormLayout>
    </Form>
  );
};
