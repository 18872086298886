import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { DeviceEdit, deviceEditAction, deviceEditLoader } from "./DeviceEdit";
import { DeviceList, devicesListLoader } from "./DeviceList";
import { requireAuth } from "../auth/RequireAuth";
import { Device } from "../../types/device";
import {
  DeviceCreate,
  deviceCreateAction,
  deviceCreateLoader,
} from "./DeviceCreate";

const DeviceIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/devices") {
    navigate(`/devices/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const devicesRouter = {
  path: "/devices",
  id: "devices",
  element: <DeviceIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Device" href="/devices/list" />,
  },
  loader: requireAuth(),
  children: [
    {
      path: "list",
      id: "devices-list",
      element: <DeviceList />,
      loader: devicesListLoader,
    },
    {
      path: "create",
      id: "devices-create",
      element: <DeviceCreate />,
      loader: deviceCreateLoader,
      action: deviceCreateAction,
      handle: {
        crumb: () => (
          <Breadcrumb name={`Create Device`} href={`/devices/create`} />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "devices-edit",
      element: <DeviceEdit />,
      loader: deviceEditLoader,
      action: deviceEditAction,
      handle: {
        crumb: (data: { device: Device }) => (
          <Breadcrumb
            name={`Edit ${data.device.profile.name}`}
            href={`/devices/${data.device.id}/edit`}
          />
        ),
      },
    },
  ],
};
