import gql from 'graphql-tag';

export const GET_LIST_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id

    menu {
      id
      name
      is_active
      event_id
    }

    item {
      id
      name
      price
      tags
      short_name
      description
      unique_id
      is_active
    }
  }
`;

export const GET_ONE_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id
  }
`;

export const CREATE_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id
  }
`;

export const UPDATE_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id
  }
`;

export const DELETE_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id
  }
`;

export const DELETE_MANY_MENU = gql`
  {
    id
    item_id
    menu_id
  }
`;

export const GET_MANY_MENU_ITEM = gql`
  {
    id
    item_id
    menu_id
  }
`;
