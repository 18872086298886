import { HTMLProps, useEffect, useRef, useState } from "react";

export default function IndeterminateCheckbox({
  indeterminate,
  className = "",
  label,
  defaultChecked,
  onChange,
  name,
  disabled,
  ...rest
}: HTMLProps<HTMLInputElement> & {
  indeterminate?: boolean;
  onChange?: (v: boolean) => void;
}) {
  const [checked, setChecked] = useState(defaultChecked);
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onChange && onChange(e.target.checked);
  };

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <input
        type="checkbox"
        ref={ref}
        className="-mt-2 h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 sm:left-6"
        onChange={handleChange}
        defaultChecked={checked}
        checked={checked}
        disabled={disabled}
      />
      <input type="hidden" name={name} value={checked ? "true" : "false"} />
    </div>
  );
}
