import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION_USER = gql`
  mutation CreateOrganizationUser(
    $password_hash: String!
    $organizationId: Int!
    $roleId: Int!
    $username: String!
    $first_name: String!
    $last_name: String!
    $email: String!
    $phoneNumber: String!
    $validationCode: String!
    $tabletAccessCode: numeric
    $events: jsonb
    $vendor: jsonb
    $is_active: Boolean
    $last_updated_by: Int
  ) {
    insert_users_one(
      object: {
        password_hash: $password_hash
        organization_id: $organizationId
        role_id: $roleId
        username: $username
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone_number: $phoneNumber
        validation_code: $validationCode
        vendors: $vendor
        tablet_access_code: $tabletAccessCode
        events: $events
        is_active: $is_active
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION_USER = gql`
  mutation UpdateOrganizationUser(
    $id: Int!
    $password_hash: String!
    $roleId: Int!
    $username: String!
    $email: String!
    $first_name: String!
    $last_name: String!
    $phoneNumber: String!
    $validationCode: String!
    $tabletAccessCode: numeric
    $orgId: Int
    $events: jsonb
    $vendor: jsonb
    $is_active: Boolean
    $last_updated_by: Int
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        password_hash: $password_hash
        role_id: $roleId
        username: $username
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone_number: $phoneNumber
        validation_code: $validationCode
        vendors: $vendor
        tablet_access_code: $tabletAccessCode
        organization_id: $orgId
        events: $events
        is_active: $is_active
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ORGANIZATION_USER_NO_PASSWORD = gql`
  mutation UpdateOrganizationUser(
    $id: Int!
    $roleId: Int!
    $username: String!
    $email: String!
    $first_name: String!
    $last_name: String!
    $phoneNumber: String!
    $validationCode: String!
    $tabletAccessCode: numeric
    $orgId: Int
    $events: jsonb
    $vendor: jsonb
    $is_active: Boolean
    $last_updated_by: Int
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        role_id: $roleId
        username: $username
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone_number: $phoneNumber
        validation_code: $validationCode
        vendors: $vendor
        tablet_access_code: $tabletAccessCode
        organization_id: $orgId
        events: $events
        is_active: $is_active
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const DELETE_ORGANIZATION_USER = gql`
  mutation DeleteOrganizationUser($id: Int!) {
    delete_users_by_pk(id: $id) {
      id
    }
  }
`;
