import {
  ApolloClient,
  InMemoryCache,
  from,
  ApolloLink,
  HttpLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { organizationStore } from "../store/organization";
import { userStore } from "../store/user";

const HASURA_ENDPOINT = import.meta.env.VITE_SERVICE_HASURA_ENDPOINT;
if (!HASURA_ENDPOINT) throw new Error("missing VITE_SERVICE_HASURA_ENDPOINT");

let client: ApolloClient<any>;
let unauthedClient: ApolloClient<any>;

const link = new HttpLink({
  uri: HASURA_ENDPOINT,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem("ron-auth");
  operation.setContext({
    headers: token ? { authorization: `Bearer ${token}` } : {},
  });
  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { getState } = userStore;
  const { getState: getOrganizationState } = organizationStore;

  if (graphQLErrors)
    if (
      graphQLErrors.some(
        ({ message }) =>
          message.includes("Authorization") || message.includes("JWT")
      ) &&
      window.location.pathname !== "/login"
    ) {
      const setUser = getState().setUser;
      const setOrganizationId = getOrganizationState().setOrganizationId;

      // If there is an authentication error redirect to login
      setUser(undefined);
      setOrganizationId(999999999);

      localStorage.removeItem("ron-auth");
      window.location.href = "/login";
    }
  // graphQLErrors.forEach(({ message, locations, path }) =>
  //   console.log(
  //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //   )
  // );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const createApolloClient = () => {
  if (client) return client;
  client = new ApolloClient({
    link: from([errorLink, authMiddleware, link]),
    cache: new InMemoryCache(),
  });
  return client;
};

export const createUnauthenticatedApolloClient = () => {
  if (unauthedClient) return unauthedClient;
  unauthedClient = new ApolloClient({
    link: from([errorLink, link]),
    cache: new InMemoryCache(),
  });
  return unauthedClient;
};
