import { ActionFunctionArgs, Form, useLoaderData } from "react-router-dom";
import { FormFooter } from "../../components/FormFooter";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "../../queries";
import { organizationStore } from "../../store/organization";
import { Event } from "../../types/event";
import { CREATE_DEVICE } from "./mutations";

const client = createApolloClient();

const { getState } = organizationStore;

export const deviceCreateLoader = async () => {
  const organizationId = getState().organizationId;
  const { data: eventsData } = await client.query<Event[]>({
    query: GET_LIST_EVENTS_BY_ORGANIZATION,
    variables: {
      where: organizationId
        ? {
            organization_id: { _eq: organizationId },
          }
        : {},
    },
  });

  return {
    events: eventsData,
  };
};

export const deviceCreateAction = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();
  await client.mutate({
    mutation: CREATE_DEVICE,
    variables: {
      input: Object.fromEntries(body),
    },
  });
};

export const DeviceCreate = () => {
  const { events } = useLoaderData() as {
    events: Event[];
  };
  return (
    <Form method="post" action="/devices/create">
      <FormLayout>
        <div className="col-span-3 sm:col-span-2">
          <Input name="name" label="Name" />
        </div>
        <div className="col-span-3 sm:col-span-2">
          <CustomSelect
            options={events.map((e) => ({
              value: e.id,
              label: e.name,
            }))}
            label="Event"
            name="event_id"
            defaultValue={null}
          />
        </div>
        <FormFooter />
      </FormLayout>
    </Form>
  );
};
