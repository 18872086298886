import { gql } from "@apollo/client";

export const LIST_ORGANIZATIONS = gql`
  query getOrganizations($where: organizations_bool_exp) {
    organizations(where: $where, order_by: { name: asc }) {
      id
      name
      events {
        id
        name
        created_at
        locations(order_by: { name: asc }) {
          id
          name
          vendor_id
        }
        timezone
      }
      vendors(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;

export const listOrgs = gql`
  query getOrganizations {
    organizations {
      id
      name
      events {
        id
        name
        created_at
        locations(order_by: { name: asc }) {
          id
          name
        }
        timezone
      }
    }
  }
`;
