import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";
import { Badge } from "../../components/Badge";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { organizationStore } from "../../store/organization";
import { Attendee } from "../../types/attendee";
import { GET_ATTENDEES } from "./queries";
import { useEffect, useRef, useState } from "react";
import { eventStore } from "../../store/event";
import { usePermissions } from "../auth/api";
import {
  ColDef,
  GridApi,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { Breadcrumbs } from "../../components/Breadcrumbs";
import { Input } from "../../components/inputs/Input";
import { PrimaryButton, SecondaryButton } from "../../components/Button";
import { AgGridReact } from "ag-grid-react";
import { userStore } from "../../store/user";

const client = createApolloClient();

const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;
const { getState: getUserStore } = userStore;

const buildSearch = (search: string) => [
  {
    first_name: {
      _ilike: `%${search}%`,
    },
  },
  {
    last_name: {
      _ilike: `%${search}%`,
    },
  },
  {
    phone_number: {
      _ilike: `%${search}%`,
    },
  },
  {
    email: {
      _ilike: `%${search}%`,
    },
  },
  {
    event: {
      name: {
        _ilike: `%${search}%`,
      },
    },
  },
];

type CellRenderer = (params: {
  value:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  data: Attendee;
}) => JSX.Element | null;
const createCellRenderer = (
  permission: boolean,
  linkFunc: (params: { data: Attendee }) => string
): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }

    return (
      <>
        {permission ? (
          <Link to={linkFunc(params)}>
            <Badge>{params.value}</Badge>
          </Link>
        ) : (
          <Badge>{params.value}</Badge>
        )}
      </>
    );
  };
};
export const attendeesListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const eventId = getEventStore().eventId;
  const user = getUserStore().user;

  let where: any = { _or: search ? buildSearch(search) : undefined };
  if (organizationId && organizationId !== 0) {
    where = {
      ...where,
      event: {
        organization_id: {
          _eq: organizationId,
        },
      },
    };
  }
  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    where = {
      ...where,
      event_id: {
        _in: user?.events,
      },
    };
  }
  const { data } = await client.query({
    query: GET_ATTENDEES,
    variables: {
      where,
    },
  });
  return data.attendees;
};

export const AttendeeList = () => {
  const { editCreatePermission } = usePermissions("attendees");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const attendees = useLoaderData() as Attendee[];
  const [rowData, setRowData] = useState<Attendee[]>([]);
  useEffect(() => {
    setRowData(attendees);
  }, [attendees]);
  const generateColDefs = (): ColDef<Attendee>[] => [
    {
      headerName: "Username",
      valueGetter: (p) => {
        const firstName = p.data?.first_name;
        const lastName = p.data?.last_name;
        if (firstName === undefined || lastName === undefined) {
          return undefined;
        }
        return `${firstName} ${lastName}`.trim();
      },
      cellRenderer: createCellRenderer(
        editCreatePermission,
        (p) => `/attendees/${p.data.id}/edit`
      ),
      filter: "agTextColumnFilter",
      pinned: "left",
    },
    {
      headerName: "Event",
      valueGetter: (p) => p.data?.event?.name,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Validation Code",
      valueGetter: (p) => p.data?.validation_code,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Phone Number",
      valueGetter: (p) => p.data?.phone_number,
    },
    {
      headerName: "E-Mail Address",
      valueGetter: (p) => p.data?.email,
    },
  ];
  const colDefs: ColDef<Attendee>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `Attendees_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };
  return (
    <div style={{ height: "75vh" }}>
      <div
        className={"ag-theme-quartz"}
        style={{ width: "100%", height: "100%" }}
      >
        <div className=" grid gap-2 grid-cols-6">
          <Breadcrumbs />
          <div className="col-span-2 col-start-5 mt-2">
            <Input
              name="search"
              placeholder={`Search Attendees`}
              onChange={(s) => {
                navigate(
                  s && typeof s === "string" && s.length > 0
                    ? `/attendees/list?search=${s}`
                    : `/attendees/list`
                );
                revalidator.revalidate();
              }}
            />
          </div>
          <div className="py-2 col-span-2 col-start-7">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
            {editCreatePermission && (
              <PrimaryButton onClick={() => navigate(`/attendees/create`)}>
                Create
              </PrimaryButton>
            )}
          </div>
        </div>
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={rowHeight}
          autoSizeStrategy={autoSizeStrategy}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={100}
          onGridReady={(params) => (gridRef.current = params.api)}
        />
      </div>
    </div>
  );
};
