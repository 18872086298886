import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation CreateEvent($input: events_insert_input!) {
    insert_events_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation UpdateEvent($id: Int!, $input: events_set_input) {
    update_events_by_pk(pk_columns: { id: $id }, _set: $input) {
      id
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: Int!) {
    delete_events(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;
