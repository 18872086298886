import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import { InvoicesCreate, invoicesCreateAction, invoicesCreateLoader } from "./InvoicesCreate";
import { editInvoicesAction, invoicesByIdLoader, InvoicesEdit } from "./InvoicesEdit";
import { InvoicesList, invoicesListLoader } from "./InvoicesList";
import { OrganizationUser } from "../../types/organizationUser";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {DELETE_INVOICES_BY_PK} from "./mutations";

const client = createApolloClient();

const InvoicesIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/invoices") {
    navigate(`/invoices/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const InvoicesDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_INVOICES_BY_PK,
      variables: { id },
    });
    toast.success("Invoice Deleted");
    return redirect("/invoices/list");
  } catch (e) {
    toast.error("Error Deleting Invoice: " + e);
  }
};

export const invoicesRouter = {
  path: "/invoices",
  id: "invoices",
  element: <InvoicesIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Invoices" href="/invoices/list" />,
  },
  children: [
    {
      path: "list",
      id: "invoices-list",
      element: <InvoicesList />,
      loader: invoicesListLoader,
    },
    {
      path: "create",
      id: "invoices-create",
      element: <InvoicesCreate />,
      handle: {
        crumb: () => <Breadcrumb name="Create Invoice" href="/invoices/create" />,
      },
      action: invoicesCreateAction,
      loader: invoicesCreateLoader,
    },
    {
      path: ":id/edit",
      id: "invoices-edit",
      element: <InvoicesEdit />,
      loader: invoicesByIdLoader,
      action: editInvoicesAction,
      handle: {
        crumb: (data: OrganizationUser) => (
          <Breadcrumb
            name={`Edit ${data.id}`}
            href={`/invoices/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "invoices-delete",
      action: InvoicesDeleteAction,
    },
  ],
};
