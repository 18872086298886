import { createApolloClient } from "../../../providers/ApolloClientFactory";
import {UPDATE_ATTENDEE_CARD_ON_FILE} from "../mutations";
import toast from "react-hot-toast";
const client = createApolloClient();

export const cardOnFileAction = async ({ id }: { id: number }) => {
    try {
        await client.mutate({
            mutation: UPDATE_ATTENDEE_CARD_ON_FILE,
            variables: {
                id: id,
                attendee_id: null,
            },
        });
        toast.success("Card On File updated successfully");
        window.location.reload();
    } catch {
        toast.error("Error updating Card On File");
    }
};