import gql from 'graphql-tag';

export const GET_LIST_DISCOUNT = gql`
  {
    amount
    code
    description
    name
    id
    discount_type
    percentage
  }
`;

export const CREATE_DISCOUNT = gql`
  {
    amount
    code
    description
    name
    id
    discount_type
    percentage
  }
`;
export const UPDATE_DISCOUNT = gql`
  {
    amount
    code
    description
    name
    id
    discount_type
    percentage
  }
`;

export const GET_ONE_DISCOUNT = gql`
  {
    amount
    code
    description
    name
    id
    discount_type
    percentage
  }
`;
