import gql from "graphql-tag";

export const GET_LIST_PAYMENT_PROCESSOR_CONFIG = gql`
  {
    id
    is_active
    is_rfid
    created_at
    organization_id
    payment_processor
    updated_at
    config

    events {
      id
      name
      created_at
    }
  }
`;

export const GET_ONE_PAYMENT_PROCESSOR_CONFIG = gql`
  {
    id
    is_active
    is_rfid
    created_at
    organization_id
    payment_processor
    updated_at
    config

    events {
      id
      name
      created_at
    }
  }
`;

export const GET_MANY_PAYMENT_PROCESSOR_CONFIG = gql`
  {
    id
    is_active
    is_rfid
    created_at
    organization_id
    payment_processor
    updated_at
    config

    events {
      id
      name
      created_at
    }
  }
`;

export const INSERT_PAYMENT_PROCESSOR_CONFIG = gql`
  {
    id
    is_active
    is_rfid
    created_at
    organization_id
    payment_processor
    updated_at
    config

    events {
      id
      name
      created_at
    }
  }
`;

export const UPDATE_PAYMENT_PROCESSOR_CONFIG = gql`
  {
    id
    is_active
    is_rfid
    created_at
    organization_id
    payment_processor
    updated_at
    config

    events {
      id
      name
      created_at
    }
  }
`;

export const GET_LIST_PAYMENT_PROCESSORS_BY_ORGANIZATION = gql`
  query PaymentProcessorsByOrganization(
    $where: payment_processor_config_bool_exp!
  ) {
    payment_processor_config(where: $where, order_by: {organization: {name: asc}}) {
      id
      payment_processor
    }
  }
`;
