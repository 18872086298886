import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import { RoleCreate, roleCreateAction } from "./RoleCreate";
import { editUserAction, RoleEdit, roleByIdLoader } from "./RoleEdit";
import { RoleList, rolesListLoader } from "./RoleList";
import { OrganizationUser } from "../../types/organizationUser";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_ROLE } from "./mutations";

const client = createApolloClient();

const RoleIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/roles") {
    navigate(`/roles/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const roleDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_ROLE,
      variables: { id },
    });
    toast.success("Role deleted");
    return redirect("/roles/list");
  } catch {
    toast.error("Error deleting role");
  }
};

export const rolesRouter = {
  path: "/roles",
  id: "roles",
  element: <RoleIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Roles" href="/roles/list" />,
  },
  children: [
    {
      path: "list",
      id: "roles-list",
      element: <RoleList />,
      loader: rolesListLoader,
    },
    {
      path: "create",
      id: "roles-create",
      element: <RoleCreate />,
      handle: {
        crumb: () => <Breadcrumb name="Create Role" href="/roles/create" />,
      },
      action: roleCreateAction,
    },
    {
      path: ":id/edit",
      id: "roles-edit",
      element: <RoleEdit />,
      loader: roleByIdLoader,
      action: editUserAction,
      handle: {
        crumb: (data: OrganizationUser) => (
          <Breadcrumb
            name={`Edit ${data.username}`}
            href={`/roles/${data.id}`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "roles-delete",
      action: roleDeleteAction,
    },
  ],
};
