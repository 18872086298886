import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { ShowRow } from "../../components/ShowRow";
import { ShowLayout } from "../../layout/ShowLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_MENU_BY_LOCATION } from "../../queries";
import { Location } from "../../types/location";
import { Menu } from "../../types/menu";
import { GET_LOCATION } from "./queries";

const client = createApolloClient();

export const locationByIdLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { data: locationData } = await client.query({
    query: GET_LOCATION,
    variables: { id },
  });
  const { data: menuData } = await client.query({
    query: GET_MENU_BY_LOCATION,
    variables: { id },
  });
  return {
    location: locationData.locations_by_pk,
    menu: menuData.menus?.[0],
  };
};

export const LocationShow = () => {
  const { location, menu } = useLoaderData() as {
    location: Location;
    menu: Menu;
  };
  return (
    <ShowLayout header={location.name}>
      <ShowRow label="Is Active?" value={location.is_active ? "Yes" : "No"} />
      <ShowRow
        label="Service Fee Value (%)"
        value={location.digital_surcharge_percentage}
      />
      <ShowRow label="Event" value={location?.event?.name} />
      <ShowRow label="Vendor" value={location?.vendor?.name} />
      <ShowRow label="Menus" value={menu.name} />
    </ShowLayout>
  );
};
