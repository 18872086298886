import {
  ColumnDef,
  flexRender,
  ExpandedState,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  Updater,
  PaginationState,
} from "@tanstack/react-table";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../utils";
import IndeterminateCheckbox from "./inputs/IndeterminantCheckbox";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useVirtual } from "react-virtual";
import Loader from "./Loader";
import { ExportButton } from "./ExportButton";
import { Input } from "./inputs/Input";

interface TableProps<T> {
  data: any[];
  columns: ColumnDef<T>[];
  loading?: boolean;
  selectable?: boolean;
  expandable?: boolean;
  infinite?: boolean;
  isFetching?: boolean;
  fetchNextPage?: () => void;
  totalFetched?: number;
  totalDBRowCount?: number;
  pagination?: boolean;
  manualPagination?: boolean;
  pageCount?: number;
  handlePageChanges?: (arg: any) => any;
  totalRecoreds?: number;
  pageData?: { pageIndex?: number; pageSize?: number };
}

export default function Table<T>({
  data: oldData,
  columns,
  selectable,
  expandable,
  isFetching,
  infinite,
  fetchNextPage,
  totalFetched,
  totalDBRowCount,
  pagination,
  pageCount,
  manualPagination = false,
  handlePageChanges,
  totalRecoreds,
  pageData,
}: TableProps<T>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const data =
    manualPagination && !totalRecoreds
      ? oldData.slice(pageIndex * 100, (pageIndex + 1) * pageSize)
      : oldData;

  if (selectable) {
    columns = [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            {row.getIsSelected() && (
              <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-600" />
            )}
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      ...columns,
    ];
  }
  if (expandable) {
    columns = [
      {
        id: "expand",
        header: ({ table }) => (
          <button
            {...{
              onClick: table.getToggleAllRowsExpandedHandler(),
            }}
          >
            {table.getIsAllRowsExpanded() ? (
              <ChevronUpIcon className="h-5 w-5 text-black" />
            ) : (
              <ChevronDownIcon className="h-5 w-5 text-black" />
            )}
          </button>
        ),
        cell: ({ row }) =>
          row.getCanExpand() ? (
            <button
              {...{
                onClick: row.getToggleExpandedHandler(),
                style: { cursor: "pointer" },
              }}
            >
              {row.getIsExpanded() ? (
                <ChevronUpIcon className="h-5 w-5 text-black" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-black" />
              )}
            </button>
          ) : null,
      },
      ...columns,
    ];
  }

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (
        containerRefElement &&
        infinite &&
        fetchNextPage &&
        totalDBRowCount &&
        totalFetched
      ) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 300 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [
    fetchMoreOnBottomReached,
    fetchNextPage,
    isFetching,
    totalFetched,
    totalDBRowCount,
  ]);

  const paginationData = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (pageData?.pageIndex != null && pageData?.pageSize) {
      setPagination({
        pageIndex: pageData?.pageIndex,
        pageSize: pageData?.pageSize,
      });
    }
  }, [pageData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      expanded,
      pagination: paginationData,
    },

    manualPagination,
    pageCount,
    onPaginationChange: (upadter: any) => {
      setPagination((prevPage) => upadter(prevPage));
      if (manualPagination && handlePageChanges) {
        handlePageChanges(upadter);
      }
    },
    onRowSelectionChange: setRowSelection,
    getSubRows: (row) => row.subRows,
    onExpandedChange: setExpanded,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: data?.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  if (!data && isFetching) {
    return <Loader />;
  }

  if (!data || data.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full pb-4">
        <div className="text-gray-500 text-sm">No data to display</div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="max-h-[calc(100vh_*_0.7)] px-4 sm:px-6 lg:px-8 overflow-x-auto overflow-y-scroll">
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                {Object.keys(rowSelection).length > 0 && (
                  <div className="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
                    <>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      >
                        Bulk edit
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      >
                        Delete all
                      </button>
                    </>
                  </div>
                )}

                <div
                  className="container"
                  onScroll={(e) =>
                    fetchMoreOnBottomReached(e.target as HTMLDivElement)
                  }
                  ref={tableContainerRef}
                >
                  <table
                      className="min-w-full border-separate"
                      style={{borderSpacing: 0}}
                  >
                    <thead className="bg-gray-50">
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                          {headerGroup.headers.map((header, columnIndex) => {
                            const isStickyColumn = columnIndex === 0;

                            return (
                                <th
                                    key={header.id}
                                    colSpan={header.colSpan}
                                    scope="col"
                                    className={`${
                                        isStickyColumn ? 'sticky -left-10' : ''
                                    } border-b border-gray-300 bg-gray-50 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8`}
                                >
                                  {header.isPlaceholder ? null : (
                                      <div
                                          {...{
                                            className: header.column.getCanSort()
                                                ? 'cursor-pointer select-none'
                                                : '',
                                            onClick: header.column.getToggleSortingHandler(),
                                          }}
                                      >
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                        {{
                                          asc: ' 🔼',
                                          desc: ' 🔽',
                                        }[header.column.getIsSorted() as string] ?? null}
                                      </div>
                                  )}
                                </th>
                            );
                          })}
                        </tr>
                    ))}
                    </thead>
                    <tbody className="bg-white">
                    {paddingTop > 0 && (
                        <tr>
                          <td style={{height: `${paddingTop}px`}}/>
                        </tr>
                    )}
                    {table.getRowModel().rows.map((row) => {
                      return (
                          <tr key={row.id}>
                            {row.getVisibleCells().map((cell, columnIndex) => {
                              const isStickyColumn = columnIndex === 0;

                              return (
                                  <td
                                      key={cell.id}
                                      className={`${
                                          isStickyColumn ? 'sticky -left-10 bg-white' : ''
                                      } ${classNames(
                                          row.index !== data.length - 1
                                              ? 'border-b border-gray-200'
                                              : '',
                                          'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'
                                      )}`}
                                  >
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                  </td>
                              );
                            })}
                          </tr>
                      );
                    })}
                    {isFetching && (
                        <tr>
                          <td>
                            <Loader/>
                          </td>
                        </tr>
                    )}
                    {paddingBottom > 0 && (
                        <tr>
                          <td style={{height: `${paddingBottom}px`}}/>
                        </tr>
                    )}
                    </tbody>
                    {table.getFooterGroups().length > 0 && (

                        <tfoot className="bg-gray-50">
                        {table.getFooterGroups().map((footerGroup) => (
                            <tr key={footerGroup.id}>
                              {footerGroup.headers.map((header, columnIndex) => {
                                const isStickyColumn = columnIndex === 0;

                                return (
                                    <th
                                        className={`${
                                            isStickyColumn ? 'sticky -left-10' : ''
                                        } border-t border-gray-300 bg-gray-50 py-3.5 p-4 min-h-5 text-left text-sm font-semibold text-gray-900  sm:pl-6 lg:pl-8`}
                                        key={header.id}
                                    >
                                      {header.isPlaceholder
                                          ? null
                                          : flexRender(header.column.columnDef.footer, header.getContext())}
                                    </th>
                                );
                              })}
                            </tr>
                        ))}
                        </tfoot>
                    )}
                  </table>
                  {pagination && !isFetching && data ? (
                      <>
                        <div className="h-2"/>
                        <div className="flex items-center gap-2">
                          <button
                              className="border rounded p-1"
                              onClick={() => table.setPageIndex(0)}
                              disabled={!table.getCanPreviousPage()}
                          >
                            <ChevronDoubleLeftIcon className="h-6 w-6 text-black-600"/>
                          </button>
                          <button
                              className="border rounded p-1"
                              onClick={() => table.previousPage()}
                              disabled={!table.getCanPreviousPage()}
                          >
                            <ChevronLeftIcon className="h-4 w-4 text-black-600"/>
                          </button>
                          <button
                              className="border rounded p-1"
                              onClick={() => table.nextPage()}
                              disabled={!table.getCanNextPage()}
                          >
                            <ChevronRightIcon className="h-4 w-4 text-black-600"/>
                          </button>
                          <button
                              className="border rounded p-1"
                              onClick={() =>
                                  table.setPageIndex(table.getPageCount() - 1)
                              }
                              disabled={!table.getCanNextPage()}
                          >
                            <ChevronDoubleRightIcon className="h-6 w-6 text-black-600"/>
                          </button>
                          <span className="flex items-center gap-1">
                          <div>Page</div>
                          <strong>
                            {table.getState().pagination.pageIndex + 1} of{" "}
                            {table.getPageCount()}
                          </strong>
                        </span>
                          <span className="flex items-center gap-1">
                          <div>Total Record</div>
                          <strong>
                            {pageIndex * pageSize + 1}
                            {"-"}
                            {pageIndex * pageSize + data?.length} of{" "}
                            {totalRecoreds || oldData?.length}
                          </strong>
                        </span>
                          <span className="flex items-center gap-1">
                          | Go to page:
                          <div className="w-24">
                            <Input
                                name="pagination_go_to"
                                type="number"
                                updateValue
                                // defaultValue={
                                //   table.getState().pagination.pageIndex + 1
                                // }
                                onChange={(value) => {
                                  const page = value ? Number(value) - 1 : 0;
                                  setTimeout(() => {
                                    table.setPageIndex(page);
                                  }, 1000);
                                }}
                            />
                          </div>
                        </span>
                        </div>
                      </>
                  ) : (
                      ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
