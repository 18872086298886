import { gql } from "@apollo/client";

export const GET_LIST_EVENTS = gql`
  query events($where: events_bool_exp!) {
    events(where: $where, order_by: { name: asc }) {
      id
      end_date
      currency
      created_at
      configuration
      name
      description
      logo
      background_url
      payment_types
      color_mode
      dynamic_descriptor
      digital_surcharge_label
      payment_processor_id
      payment_processor_config {
        id
        payment_processor
      }

      organization {
        name
      }
      organization_id
      slug

      start_date
      timezone
      timezone_diff
      updated_at
      locations {
        name
        id
      }
      is_active
      user {
        username
      }
    }
  }
`;

export const GET_EVENT = gql`
  query EventByPk($id: Int!) {
    events_by_pk(id: $id) {
      id
      name
      start_date
      end_date
      currency
      description
      logo
      background_url
      payment_types
      payment_processor_id
      signup_questions
      frontgate_site_id
      dynamic_descriptor
      location_zones
      available_tokens
      payment_processor_config {
        payment_processor
      }

      organization_id
      slug
      color_mode
      digital_surcharge_label
      timezone
      timezone_diff
      is_active
      is_org_logout_protected
      is_clerk_logout_protected
      event_passcode
      web_surcharge_percentage
      start_time
      end_time
      address_street
      address_street_2
      address_city
      address_state
      address_zipcode
      ticketing_slug
    }
  }
`;
