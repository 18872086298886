import gql from "graphql-tag";

export const GET_LIST_ROLES = gql`
  {
    id
    name
    is_active
  }
`;
export const GET_MANY_ROLES = gql`
  {
    id
    name
    is_active
  }
`;
