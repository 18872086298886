import { gql } from "@apollo/client";

export const PROMO_BALANCE_REPORT = gql`
  query reports_event($where: reports_promo_balance_bool_exp) {
    reports_promo_balance(where: $where, order_by: {username: asc}) {
    created_at_hour
    event_id
    event_name
    gross_promo_loaded
    gross_promo_refunded
    location_id
    location_name
    net_promo_loaded
    organization_id
    user_id
    username
    }
  }
`;
