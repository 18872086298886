import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { classNames } from "../../utils";
import { Badge } from "../Badge";

interface TagsInputProps {
  label: string;
  error?: string;
  type?: string;
  name: string;
  placeholder?: string;
  defaultValue?: string[];
}

export function TagsInput({
  label,
  error,
  name,
  type = "text",
  placeholder,
  defaultValue,
}: TagsInputProps) {
  const [tag, setTag] = useState<string>("");
  const [tags, setTags] = useState<string[]>(defaultValue || []);

  const onEnter = () => {
    setTags([...tags, tag]);
    setTag("");
  };

  const handleRemove = (index: number) => {
    setTags(tags.filter((_, idx) => idx !== index));
  };

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          id={name}
          type={type}
          onChange={(e) => setTag(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter();
            }
          }}
          className={classNames(
            "block w-full rounded-md border-gray-300 pr-10 sm:text-sm",
            error
              ? "text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500"
              : ""
          )}
          placeholder={placeholder}
          value={tag}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="error">
          {error}
        </p>
      )}
      <div className="mt-1 flex">
        {tags.map((tag, idx) => (
          <div className="mr-1">
            <Badge removable onRemove={() => handleRemove(idx)}>
              {tag}
            </Badge>
          </div>
        ))}
      </div>
      <input name={name} type="hidden" value={tags.join(",")} />
    </div>
  );
}
