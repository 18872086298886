import { gql } from '@apollo/client';

export const CREATE_PROMO_CODE = gql`
  mutation CreatePromoCode($data: promo_code_insert_input!) {
    insert_promo_code_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_PROMO_CODE = gql`
  mutation UpdatePromoCode($id: Int!, $data: promo_code_set_input) {
    update_promo_code_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const PROMO_CODE_SEND_EMAIL_SMS = gql`
  mutation SendEmailSmsPromoCode($data: SendPromoCodeInput!) {
    promocode_send_email(sendPromoCodeInput: $data) {
      message
    }
  }
`;

export const DELETE_PROMO_CODE = gql`
  mutation DeletePromoCode($id: Int!) {
    delete_promo_code_by_pk(id: $id) {
      id
    }
  }
`;
