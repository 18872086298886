import { gql } from "@apollo/client";

export const ACCESS_SCANS_REPORT = gql`
  query reports_access(
    $where: reports_access_bool_exp!
    $offset: Int!
    $limit: Int!
  ) {
    reports_access(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { scan_created_at: desc }
    ) {
      access_token_quantity
    access_token_type
    access_transaction_id
    event_id
    event_name
    id
    location_id
    location_name
    organization_id
    organization_name
    scan_created_at
    scan_status
    scan_status_message
    adjusted_scan_time
    uid
    user_id
    username
    }
  }
`;
export const ACCESS_SCANS_REPORT_COUNT = gql`
  query reports_access(
    $where: reports_access_bool_exp!
  ) {
    total: reports_access_aggregate(where: $where) {
      aggregate {
        count
        __typename
      }
      __typename
    }
  }
`;
