import { gql } from "@apollo/client";

export const PAYMENT_PROCESSOR_LIST = gql`
  query payment_processor_config($where: payment_processor_config_bool_exp) {
    payment_processor_config(
      where: $where
      order_by: { payment_processor: asc }
    ) {
      id
      is_active
      is_rfid
      created_at
      organization {
        name
      }
      organization_id
      payment_processor
      updated_at
      processor
      config
      events(order_by: { name: asc }) {
        id
        name
        created_at
        __typename
      }
      user {
        username
      }
      processing_rate
      __typename
    }
  }
`;

export const PaymentProcessorById = gql`
  query payment_processor_config($id: Int!) {
    payment_processor_config_by_pk(id: $id) {
      id
      is_active
      is_rfid
      created_at
      organization_id
      payment_processor
      processor
      updated_at
      config
      events {
        id
        name
        created_at
        __typename
      }
      processing_rate
      __typename
    }
  }
`;

export const PAYMENT_PROCESSOR_CATAGORIES = gql`
query vendor_categories($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`