import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { Badge } from "../../components/Badge";
import { ShowRow } from "../../components/ShowRow";
import { ShowLayout } from "../../layout/ShowLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Item } from "../../types/item";
import { Location } from "../../types/location";
import { LocationMenu, Menu, MenuItem } from "../../types/menu";
import { capitalize } from "../../utils";
import {
  GET_ITEMS,
  GET_LOCATIONS,
  GET_LOCATION_BY_MENU,
  GET_MENU,
  GET_MENU_ITEMS,
} from "./queries";

const client = createApolloClient();

export const menuShowLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const [
    { data: menuData },
    { data: locationMenuData },
    { data: menuItemData },
  ] = await Promise.all([
    client.query({
      query: GET_MENU,
      variables: {
        id,
      },
    }),
    client.query({
      query: GET_LOCATION_BY_MENU,
      variables: {
        id,
      },
    }),
    client.query({
      query: GET_MENU_ITEMS,
      variables: {
        id,
      },
    }),
  ]);

  const [{ data: itemData }, { data: locationData }] = await Promise.all([
    client.query({
      query: GET_ITEMS,
      variables: {
        ids: menuItemData.menu_item.map((item: MenuItem) => item.item_id),
      },
    }),
    client.query({
      query: GET_LOCATIONS,
      variables: {
        ids: locationMenuData.location_menu.map(
          (item: LocationMenu) => item.location_id
        ),
      },
    }),
  ]);

  return {
    menu: menuData.menus_by_pk,
    locations: locationData.locations,
    items: itemData.items,
  };
};

export const MenuShow = () => {
  const { menu, locations, items } = useLoaderData() as {
    menu: Menu;
    locations: Location[];
    items: Item[];
  };

  return (
    <ShowLayout header={menu.name}>
      <ShowRow label="Is Active?" value={menu.is_active ? "Yes" : "No"} />
      <ShowRow label="Tax Type" value={capitalize(menu.tax_type)} />
      <ShowRow label="Event" value={menu.event.name} />
      <ShowRow label="Vendor" value={menu.vendor.name} />
      <ShowRow
        label="Locations"
        value={
          <div className="flex flex-wrap gap-2">
            {locations.map((l) => (
              <Badge link={`/locations/${l.id}`}>{l.name}</Badge>
            ))}
          </div>
        }
      />
      <ShowRow
        label="Items"
        value={
          <div className="flex flex-wrap gap-2">
            {items.map((i) => (
              <Badge link={`/items/${i.id}`}>{i.name}</Badge>
            ))}
          </div>
        }
      />
      <ShowRow label="Cash Accepted?" value={menu.is_cash ? "Yes" : "No"} />
      <ShowRow label="Credit Accepted?" value={menu.is_credit ? "Yes" : "No"} />
      <ShowRow label="RFID Accepted?" value={menu.is_rfid ? "Yes" : "No"} />
      <ShowRow label="Tips Accepted?" value={menu.is_tips ? "Yes" : "No"} />
      <ShowRow label="Custom Items Available?" value={menu.is_custom_item ? "Yes" : "No"} />
    </ShowLayout>
  );
};
