import React from 'react';
import { Font, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { usdFormat } from "../utils";
import moment from "moment";

interface GLAccountPDFProps {
    reportTitle: string;
    tableData: Array<{
        gl_account_name: string;
        gl_account_number: string;
        pos_name: string;
        product_type: string;
        item_price: number;
        total_gross_sales: number;
        total_discounts: number;
        total_tax: number;
        total_net_sales: number;
        gross_credit_sales: number;
        gross_cash_sales: number;
    }>;
    filters: any;
}

Font.register({
    family: 'Roboto',
    fonts: [
        { src: '/fonts/Roboto-Regular.ttf', fontWeight: 'normal' },
        { src: '/fonts/Roboto-Bold.ttf', fontWeight: 'bold' },
    ],
});

// Helper function to format dates using moment
const formatDate = (dateString: string) => {
    return moment(dateString).format('ddd, MMM D, YYYY, h:mm A');
};

// Helper function to capitalize text
const capitalize = (text: string) => {
    return text.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
};

// Function to format filters for display
const formatFilter = (key: string, condition: any) => {
    if (Array.isArray(condition)) {
        return condition
            .map((subCondition) =>
                Object.keys(subCondition).map((subKey) => {
                    const operator = Object.keys(subCondition[subKey])[0];
                    const value = subCondition[subKey][operator];

                    let displayKey = subKey;
                    if (subKey === 'transaction_hour' && operator === '_gte') {
                        displayKey = 'From';
                        return `${displayKey}: ${formatDate(value)}`;
                    } else if (subKey === 'transaction_hour' && operator === '_lt') {
                        displayKey = 'To';
                        return `${displayKey}: ${formatDate(value)}`;
                    }

                    return `${capitalize(displayKey)}: ${value}`;
                })
            )
            .flat()
            .join(", ");
    }

    const operator = Object.keys(condition)[0];
    const value = condition[operator];

    let displayKey = key;
    if (key === 'transaction_hour' && operator === '_gte') {
        displayKey = 'From';
        return `${displayKey}: ${formatDate(value)}`;
    } else if (key === 'transaction_hour' && operator === '_lt') {
        displayKey = 'To';
        return `${displayKey}: ${formatDate(value)}`;
    }

    return `${capitalize(displayKey)}: ${value}`;
};

// PDF Document Component
export const GLAccountPDF: React.FC<GLAccountPDFProps> = ({ reportTitle, tableData, filters }) => (
    <Document>
        <Page style={styles.page} orientation="landscape">
            <View style={styles.headerSection}>
                <Image style={styles.logo} src="/static/Ronin_RGB_POS.png" />
                <View style={styles.titleAndFilters}>
                    <Text style={styles.header}>{reportTitle}</Text>
                    {Object.keys(filters).map((key, index) => (
                        <Text key={index} style={styles.filterText}>
                            {formatFilter(key, filters[key])}
                        </Text>
                    ))}
                </View>
            </View>

            <View style={styles.tableSection}>
                <View style={styles.tableHeader} fixed>
                    <Text style={styles.tableHeaderCell}>GL Name</Text>
                    <Text style={styles.tableHeaderCell}>GL Number</Text>
                    <Text style={styles.tableHeaderCell}>Product Name</Text>
                    <Text style={styles.tableHeaderCell}>Type</Text>
                    <Text style={styles.tableHeaderCell}>Price</Text>
                    <Text style={styles.tableHeaderCell}>Gross Sales</Text>
                    <Text style={styles.tableHeaderCell}>Discounts</Text>
                    <Text style={styles.tableHeaderCell}>Tax</Text>
                    <Text style={styles.tableHeaderCell}>Net Sales</Text>
                    <Text style={styles.tableHeaderCell}>Credit Sales</Text>
                    <Text style={styles.tableHeaderCell}>Cash Sales</Text>
                </View>
                {tableData.map((row, index) => (
                    <View key={index} style={styles.tableRow} minPresenceAhead={30}>
                        <Text style={styles.tableCell}>{row.gl_account_name}</Text>
                        <Text style={styles.tableCell}>{row.gl_account_number}</Text>
                        <Text style={styles.tableCell}>{row.pos_name}</Text>
                        <Text style={styles.tableCell}>{row.product_type}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.item_price)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.total_gross_sales)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.total_discounts)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.total_tax)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.total_net_sales)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.gross_credit_sales)}</Text>
                        <Text style={styles.tableCell}>{usdFormat.format(row.gross_cash_sales)}</Text>
                    </View>
                ))}
            </View>

            <Text
                style={styles.footer}
                render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}
                fixed
            />
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        padding: 24,
        backgroundColor: '#e5e7eb',
    },
    headerSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20, // Added margin below the header section
        alignItems: 'center',
    },
    logo: {
        width: 100,
        height: 'auto',
    },
    titleAndFilters: {
        textAlign: 'right',
    },
    header: {
        fontSize: 24,
        color: '#B71C1C',
        fontWeight: 'bold',
        fontFamily: 'Roboto',
        marginBottom: 8, // Padding between filters and title
    },
    filterText: {
        fontSize: 12,
        color: '#333',
        fontFamily: 'Roboto',
    },
    tableSection: {
        marginBottom: 40,
        borderRadius: 5,
        backgroundColor: '#FFFFFF',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#EE2E30',
        color: 'white',
        padding: 8,
        borderRadius: 5,
        fontWeight: 'bold',
        fontFamily: 'Roboto',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#E0E0E0',
        borderBottomStyle: 'solid',
        padding: 8,
        fontFamily: 'Roboto',
    },
    tableCell: {
        fontSize: 8,
        color: '#333',
        flex: 1,
        fontFamily: 'Roboto',
    },
    tableHeaderCell: {
        fontSize: 8,
        color: 'white',
        flex: 1,
        fontFamily: 'Roboto',
    },
    footer: {
        textAlign: 'center',
        marginTop: 10,
        fontSize: 12,
        color: 'gray',
    },
});
