import gql from 'graphql-tag';

export const GET_LIST_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
    order {
      attendee_id
      created_at
      device_id
      id
      items
      reference_id
      status
      subtotal
      tax
      tip
      transaction_at
      transaction_time
      user_id
      user {
        phone_number
      }
    }
    payment {
      amount
      id
      payment_data
      payment_type
      reference_id
      status
    }
  }
`;

export const GET_ONE_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
    order {
      attendee_id
      created_at
      device_id
      id
      items
      reference_id
      status
      subtotal
      tax
      tip
      transaction_at
      transaction_time
      user_id
      user {
        phone_number
      }
    }
    payment {
      amount
      id
      payment_data
      payment_type
      reference_id
      status
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
  }
`;
export const UPDATE_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
  }
`;

export const GET_MANY_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
    order {
      attendee_id
      created_at
      device_id
      id
      items
      reference_id
      status
      subtotal
      tax
      tip
      transaction_at
      transaction_time
      user_id
      user {
        phone_number
      }
    }
    payment {
      amount
      id
      payment_data
      payment_type
      reference_id
      status
    }
  }
`;

export const GET_MANY_REFERENCE_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
  }
`;

export const DELETE_TRANSACTION = gql`
  {
    order_id
    payment_id
    id
    amount
    created_at
    note
    status
    updated_at
  }
`;
