export const renderEventOptions = (events: any[]) => [
  {
    id: null,
    name: "All Events",
  },
  ...events
    .map((o: { events: { id: any; name: any }[] }) =>
      o?.events
        ?.map((e: { id: any; name: any }) => ({
          id: e.id,
          name: e.name,
        }))
        .flat()
    )
    .flat()
    .sort((a: { name: string }, b: { name: any }) =>
      a.name.localeCompare(b.name)
    ),
];

export const renderLocationOptions = (locations: any[]) => {
  let locationList = [
    ...locations.map((l: { id: any; name: any }) => ({
      value: l.id,
      label: l.name,
    })),
  ];
  return locationList;
};

export const renderVendorOptions = (vendors: any[]) => {
  let vendorList = [
    ...vendors.map((v: { id: any; name: any }) => ({
      value: v.id,
      label: v.name,
    })),
  ];
  return vendorList;
};

export const renderOrganizationOptions = (organizations: any[]) => {
  let organizationList = [
    {
      value: 0,
      label: "All Organizations",
    },
    ...organizations.map((o: { id: any; name: any }) => ({
      value: o.id,
      label: o.name,
    })),
  ];
  return organizationList;
};

export const renderEventSidebarOptions = (events: any[]) => {
  let eventList = [
    {
      value: "",
      label: "All Events",
    },
    ...events.map((o: { id: any; name: any }) => ({
      value: o.id,
      label: o.name,
    })),
  ];
  return eventList;
};

export const renderUsernameOptions = (users: any[]) => {
  let userList = [
    ...users.map((u: { username: any }) => ({
      value: u.username,
      label: u.username,
    })),
  ];
  return userList;
};
