import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { REPORTS_FINANCIAL_HOURLY } from "./fragment";
import { colDefs, hourColumns, formatData } from "./helpers";
import { ReportFilters } from "../components/ReportFilters";
import ReportHeader from "../components/ReportHeader";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { organizationStore } from "../../../store/organization";
import { FinancialHourlyReportRow } from "./type";
import React, { useRef } from "react";
import { eventStore } from "../../../store/event";
import { userStore } from "../../../store/user";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
} from "recharts";
import { GridApi, SizeColumnsToContentStrategy } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { SecondaryButton } from "../../../components/Button";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventState } = eventStore;
const { getState: getUserState } = userStore;

export const financialHourlyLoader = async ({
  request,
}: LoaderFunctionArgs) => {
  const rawOrganizationId = getState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getUserState().user;
  const search = new URL(request.url);
  const where = search.searchParams.get("where");
  let whereVariable: any = where ? JSON.parse(where) : {};

  if (organizationId && organizationId !== 0) {
    whereVariable = {
      ...whereVariable,
      organization_id: {
        _eq: organizationId,
      },
    };
  }
  if (user?.vendors) {
    whereVariable = {
      ...whereVariable,
      vendor_id: {
        _in: user?.vendors,
      },
    };
  }

  if (eventId) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _eq: eventId,
      },
    };
  } else if (user?.events) {
    whereVariable = {
      ...whereVariable,
      event_id: {
        _in: user?.events,
      },
    };
  }

  const { data } = await client.query({
    query: REPORTS_FINANCIAL_HOURLY,
    variables: { where: whereVariable },
  });
  return data?.reports_financial_hourly;
};

const LineChartComponent = ({ rows }: { rows: FinancialHourlyReportRow[] }) => {
  if (!rows || rows.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full pb-4">
        <div className="text-gray-500 text-sm">No data to display</div>
      </div>
    );
  }
  const day1 = rows[0] || [];
  const day2 = rows[1] || [];
  const day3 = rows[2] || [];
  const day4 = rows[3] || [];
  const day5 = rows[4] || [];
  const day6 = rows[5] || [];
  const day7 = rows[6] || [];

  const day1ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day1 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day1.display_date,
  }));

  const day2ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day2 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day2.display_date,
  }));
  const day3ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day3 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day3.display_date,
  }));
  const day4ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day4 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day4.display_date,
  }));
  const day5ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day5 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day5.display_date,
  }));
  const day6ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day6 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day6.display_date,
  }));
  const day7ChartData = hourColumns.map((column) => ({
    hour: column.header,
    totalNetSales: (day7 as Record<string, any>)[
      (
        column as {
          accessorKey: keyof FinancialHourlyReportRow;
        }
      ).accessorKey
    ],
    date: day7.display_date,
  }));

  const mergedChartData = day1ChartData.map((entry, index) => ({
    hour: entry.hour,
    totalNetSalesRecord1: entry.totalNetSales,
    totalNetSalesRecord2: day2ChartData[index].totalNetSales,
    totalNetSalesRecord3: day3ChartData[index].totalNetSales,
    totalNetSalesRecord4: day4ChartData[index].totalNetSales,
    totalNetSalesRecord5: day5ChartData[index].totalNetSales,
    totalNetSalesRecord6: day6ChartData[index].totalNetSales,
    totalNetSalesRecord7: day7ChartData[index].totalNetSales,
    date: entry.date,
  }));

  const usdFormatter = (value: number) => {
    return `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  return (
    <div className="flex bg-white rounded-md flex-row items-center w-full">
      <div className="overflow-x-auto">
        <div className="flex flex-col">
          <div className="inline-block min-w-full align-middle">
            <LineChart
              width={1180}
              height={400}
              data={mergedChartData}
              margin={{ top: 20, right: 10, left: 10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="hour"
                tick={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  fill: "#4a5568",
                }}
                interval={2}
              />
              <YAxis
                width={100}
                tickFormatter={usdFormatter}
                tick={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  fill: "#4a5568",
                }}
              />
              <Tooltip
                itemSorter={(item) => {
                  return (item.value as number) * -1;
                }}
                contentStyle={{
                  fontSize: "0.875rem",
                  fontWeight: 600,
                  backgroundColor: "#fff",
                  border: "1px solid #e2e8f0",
                  color: "#4a5568",
                }}
                formatter={usdFormatter}
              />
              <Legend />
              {day1.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord1"
                  name={`${day1.display_date}`}
                  stroke="#B71C1C"
                  strokeWidth={2}
                />
              )}
              {day2.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord2"
                  name={`${day2.display_date}`}
                  stroke="black"
                  strokeWidth={2}
                />
              )}
              {day3.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord3"
                  name={`${day3.display_date}`}
                  stroke="#1C1CB7"
                  strokeWidth={2}
                />
              )}
              {day4.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord4"
                  name={`${day4.display_date}`}
                  stroke="#7C1CB7"
                  strokeWidth={2}
                />
              )}
              {day5.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord5"
                  name={`${day5.display_date}`}
                  stroke="#B76A1C"
                  strokeWidth={2}
                />
              )}
              {day6.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord6"
                  name={`${day6.display_date}`}
                  stroke="#1C96B7"
                  strokeWidth={2}
                />
              )}
              {day7.display_date && (
                <Line
                  type="monotone"
                  dataKey="totalNetSalesRecord7"
                  name={`${day7.display_date}`}
                  stroke="#0B5E0B"
                  strokeWidth={2}
                />
              )}
            </LineChart>
          </div>
        </div>
      </div>
    </div>
  );
};
export const FinancialHourlyReport = () => {
  const data = useLoaderData() as FinancialHourlyReportRow[];
  const formattedData = formatData(data);
  const chartRows = formattedData.filter((row) => row.display_date !== "Total");
  const totalRow = formattedData.filter((row) => row.display_date === "Total");
  const tableRows = formattedData.filter((row) => row.display_date !== "Total");
  const gridRef = useRef<GridApi>();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = {
    type: "fitCellContents",
  };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, "");
      const params = {
        fileName: `NetHourlySalesReport_${timestamp}.csv`,
        columnSeparator: ",",
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection: true,
  };

  return (
    <>
      <div className="bg-white px-4 pb-1 mb-3 rounded-md">
        <ReportHeader
          title="Net Hourly Sales Report"
          toolTipShow={true}
          tooltipText={
            "This report shows daily net sales, broken down hour-by-hour from 5am to 5am the next day. Each row is a distinct day, with columns representing net sales for each hour. It offers insight into hourly sales trends and daily sales momentum. Perfect for tracking and comparing sales performance throughout the day."
          }
        />
        <ReportFilters
          includeDates
          includeVendors
          includeTime
          includeDatesStartOfYear
          includeLocations
          includeUsernames
          customQueryPaths={{
            dateFrom: "_and[0].transaction_hour",
            dateTo: "_and[1].transaction_hour",
          }}
        />
        <div className="flex">
          <div className="ml-auto">
            <SecondaryButton onClick={handleExportCSV}>
              Export to CSV
            </SecondaryButton>
          </div>
        </div>
      </div>
      <LineChartComponent rows={chartRows} />
      <div style={{ height: "60vh", marginTop: "4px" }}>
        <div
          className={"ag-theme-quartz"}
          style={{ width: "100%", height: "100%" }}
        >
          <AgGridReact
            rowData={tableRows}
            columnDefs={colDefs}
            rowHeight={rowHeight}
            autoSizeStrategy={autoSizeStrategy}
            pagination={true}
            paginationPageSize={100}
            gridOptions={gridOptions}
            pinnedBottomRowData={totalRow}
            onGridReady={(params) => (gridRef.current = params.api)}
          />
        </div>
      </div>
    </>
  );
};
