import moment from "moment";
import {WebCheckoutReportRow} from "./type";
import {ColDef} from "ag-grid-community";
import {usdFormat} from "../../../utils";


function addressCellRenderer(params: any) {
  const address1 = params.data?.billing_address_1 || '';
  const address2 = params.data?.billing_address_2 || '';
  const city = params.data?.billing_city || '';
  const state = params.data?.billing_state || '';
  const zipcode = params.data?.billing_zipcode || '';

  return `
    <div>
      ${address1} ${address2}<br/>
      ${city}, ${state} ${zipcode}
    </div>
  `;
}

const generateColDefs = (): ColDef<WebCheckoutReportRow>[] => [
  {
    headerName: 'Transaction Date',
    valueGetter: p => p.data?.created_at,
    pinned: 'left',
    valueFormatter: p => {
      const date = p.value ? moment.utc(p.value).subtract(5, 'hours') : null;
      return date ? date.format('ddd, MMM DD, YYYY h:mm a') : '';
    }
  },
  {
    headerName: 'Reference ID',
    valueGetter: p => p.data?.reference_id,
  },
  {
    headerName: 'Processor Reference ID',
    valueGetter: p => p.data?.processor_reference_id,
  },
  {
    headerName: 'Selection',
    valueGetter: p => p.data?.selection,
  },
  {
    headerName: 'Total Amount',
    valueGetter: p => p.data?.total_amount,
    valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
  },
  {
    headerName: 'Subtotal',
    valueGetter: p => p.data?.amount,
    valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
  },
  {
    headerName: 'Service Fee',
    valueGetter: p => p.data?.service_fee,
    valueFormatter: (params) => usdFormat.format((params.value ?? 0) / 100),
  },
  {
    headerName: 'Client Name',
    valueGetter: p => `${p.data?.client_first_name} ${p.data?.client_last_name}`,
  },
  {
    headerName: 'Client Email',
    valueGetter: p => p.data?.client_email,
  },
  {
    headerName: 'Client Phone',
    valueGetter: p => p.data?.client_phone_number,
  },
  {
    headerName: 'Billing Name',
    valueGetter: p => `${p.data?.billing_first_name} ${p.data?.billing_last_name}`,
  },
  {
    headerName: 'Billing Address',
    valueGetter: p => `${p.data?.billing_address_1} ${p.data?.billing_address_2} - ${p.data?.billing_city}, ${p.data?.billing_state}  ${p.data?.billing_zipcode}`,
  },
  {
    headerName: 'Card Type',
    valueGetter: p => p.data?.card_type,
  },
  {
    headerName: 'Last 4 of Card',
    valueGetter: p => p.data?.last_four_of_card,
  },
];

export const colDefs: ColDef<WebCheckoutReportRow>[] = generateColDefs();