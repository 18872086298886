import { gql } from "@apollo/client";

export const GET_LIST_ATTENDEES = gql`
  {
    id
    first_name
    last_name
    phone_number
    email
    # validation_code
    # event_id

    # event {
    #   id
    #   name
    # }

    orders {
      id
      reference_id
      items
      subtotal
      tax
      tip
      digital_surcharge
      discount
      status
      tokens_redeemed
      location {
        name
      }
      transaction_at
    }

    is_suspended

    # card_on_files {
    # id
    # payment_processor
    # payment_data
    # last_four_digits
    #   is_active
    # }

    rfid_assets {
      id
      uid
      # is_active
      # event_id
      # attendee_id
    }

    # note
  }
`;

export const INSERT_ATTENDEES = gql`
  {
    id
    first_name
    last_name
    phone_number
    email
    validation_code
    event_id

    event {
      id
      name
    }

    orders {
      id
      reference_id
      items
      subtotal
      tax
      tip
      digital_surcharge
      discount
      status
    }

    is_suspended
    note
  }
`;

export const UPDATE_ATTENDEES = gql`
  {
    id
    first_name
    last_name
    phone_number
    email
    validation_code
    event_id
    note
    event_id

    event {
      id
      name
    }

    orders {
      id
      reference_id
      items
      subtotal
      tax
      tip
      digital_surcharge
      discount
      status
    }

    is_suspended

    card_on_files {
      id
      payment_processor
      payment_data
      last_four_digits
      is_active
    }

    rfid_assets {
      id
      uid
      is_active
      event_id
      attendee_id
    }
  }
`;

export const GET_ONE_ATTENDEES = gql`
  {
    id
    first_name
    last_name
    phone_number
    email
    validation_code
    event_id

    event {
      id
      name
    }

    orders {
      id
      reference_id
      items
      subtotal
      tax
      tip
      digital_surcharge
      discount
      status
      location_id
      location {
        name
      }
      transaction_at
    }

    is_suspended

    card_on_files {
      id
      payment_processor
      payment_data
      last_four_digits
      is_active
    }

    rfid_assets {
      id
      uid
      is_active
      event_id
      attendee_id
    }

    note
  }
`;

export const GET_MANY_ATTENDEES = gql`
  {
    id
    first_name
    last_name
    phone_number
    email
    # validation_code
    # event_id

    # event {
    #   id
    #   name
    # }

    orders {
      id
      reference_id
      items
      subtotal
      tax
      tip
      digital_surcharge
      discount
      status
      tokens_redeemed
      location_id
      transaction_at
      location {
        name
      }
    }

    is_suspended

    # card_on_files {
    # id
    # payment_processor
    # payment_data
    # last_four_digits
    #   is_active
    # }

    rfid_assets {
      id
      uid
      # is_active
      # event_id
      # attendee_id
    }
  }
`;

export const GET_LIST_ATTENDEES_BY_ORGANIZATION = gql`
  query AttendeesByOrganization($organizationId: Int!) {
    attendees(where: { event: { organization_id: { _eq: $organizationId } } }) {
      id
      first_name
      last_name
      phone_number
      email
    }
  }
`;
