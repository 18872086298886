import { AuthToken } from "../types/auth";
import decodeJwt from "jwt-decode";

export const getUserId = () => {
  const accessToken = localStorage.getItem("ron-auth");
  if (!accessToken) return null;
  const decodedToken: AuthToken = decodeJwt(accessToken);
  const hasuraClaims = decodedToken["https://hasura.io/jwt/claims"];

  const user = hasuraClaims["x-hasura-user-id"];
  return user;
};
