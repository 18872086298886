import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Location } from "../../types/location";
import {
  locationCreateAction,
  LocationCreate,
  locationCreateLoader,
} from "./LocationCreate";
import {
  locationEditAction,
  locationEditLoader,
  LocationEdit,
} from "./LocationEdit";
import { LocationList, locationsListLoader } from "./LocationList";
import { locationByIdLoader, LocationShow } from "./LocationShow";
import { DELETE_LOCATION, DELETE_LOCATION_MENUS } from "./mutations";
import {Breadcrumb} from "../../components/Breadcrumb";

const LocationsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/locations") {
    navigate(`/locations/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const client = createApolloClient();

const locationDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;

  try {
    await client.mutate({
      mutation: DELETE_LOCATION,
      variables: { id },
    });
    await client.mutate({
      mutation: DELETE_LOCATION_MENUS,
      variables: { id },
    });
    toast.success("Location deleted");
    return redirect("/locations/list");
  } catch {
    toast.error("Failed to delete location");
    return null;
  }
};

export const locationsRouter = {
  path: "/locations",
  id: "locations",
  element: <LocationsIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Locations" href="/locations/list" />,
  },
  children: [
    {
      path: "list",
      id: "locations-list",
      element: <LocationList />,
      loader: locationsListLoader,
    },
    {
      path: "create",
      id: "locations-create",
      element: <LocationCreate />,
      loader: locationCreateLoader,
      action: locationCreateAction,
      handle: {
        crumb: () => (
          <Breadcrumb name="Create Location" href="/locations/create" />
        ),
      },
    },
    {
      path: ":id",
      id: "locations-show",
      element: <LocationShow />,
      loader: locationByIdLoader,
      handle: {
        crumb: (data: { location: Location }) => (
          <Breadcrumb
            name={data.location.name}
            href={`/locations/${data.location.id}`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "locations-edit",
      element: <LocationEdit />,
      loader: locationEditLoader,
      action: locationEditAction,
      handle: {
        crumb: (data: { location: Location }) => (
          <Breadcrumb
            name={`Edit ${data.location.name}`}
            href={`/locations/${data.location.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "locations-delete",
      action: locationDeleteAction,
    },
  ],
};
