/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import moment from "moment";
import { useLoaderData } from "react-router-dom";
import { formatDateForApi } from "../../utils";
import { GET_USER_IDS, TOP_TEN_CLERKS } from "./queries/topTenClerks";
import TodayCard from "./components/cards/Today";
import LastSevenCard from "./components/cards/LastSeven";
import LastThirtyCard from "./components/cards/LastThirty";
import YesterdayCard from "./components/cards/Yesterday"
import { getGreeting } from "./utils";
import TopTenLocations from "./components/lists/TopTenLocations";
import TopTenClerks from "./components/lists/TopTenClerks";
import TopTenProducts from "./components/lists/TopTenProducts";
import { TOP_TEN_LOCATIONS } from "./queries/topTenLocations";
import { TOP_TEN_PRODUCT_MIX } from "./queries/topTenProductMix";
import { SALES_INFO } from "./queries/salesInfo";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { organizationStore } from "../../store/organization";
import { useUserStore } from "../../store/user";
import {
  DashboardLoaderData,
  TopTenClerk,
} from "./type";
import Select from "../../components/Select";
import { eventStore } from "../../store/event";
const client = createApolloClient();
const { getState: getOrganizationState } = organizationStore;
const { getState: getUserState } = useUserStore;
const { getState: getEventState } = eventStore;

const statsQuery = async (_gte: string, _lte: string) => {
  const organizationId = getOrganizationState().organizationId;
  const user = getUserState().user;
  const { eventId } = getEventState();

  let where: any = user?.vendor_id
    ? {
        vendor_id: { _eq: user.vendor_id },
        transaction_date: {
          _gte,
          _lte,
        },
      }
    : {
        organization_id: organizationId
          ? {
              _eq: organizationId,
            }
          : {},
        transaction_date: {
          _gte,
          _lte,
        },
      };

  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  }

  const { data } = await client.query({
    query: SALES_INFO,
    variables: {
      where,
    },
  });
  return data?.reports_financial;
};

const topTenLocationsQuery = async () => {
  const organizationId = getOrganizationState().organizationId;

  const user = getUserState().user;
  const { eventId } = getEventState();

  let where: any = user?.vendor_id
    ? {
        vendor_id: { _eq: user.vendor_id },
      }
    : {
        organization_id: organizationId
          ? {
              _eq: organizationId,
            }
          : {},
      };

  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  }
  const { data } = await client.query({
    query: TOP_TEN_LOCATIONS,
    variables: {
      where,
    },
  });
  return data?.reports_top_ten_location;
};

const topTenProductMixQuery = async () => {
  const organizationId = getOrganizationState().organizationId;
  const user = getUserState().user;
  const { eventId } = getEventState();

  let where: any = user?.vendor_id
    ? {
        vendor_id: { _eq: user.vendor_id },
      }
    : {
        organization_id: organizationId
          ? {
              _eq: organizationId,
            }
          : {},
      };

  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  }
  const { data } = await client.query({
    query: TOP_TEN_PRODUCT_MIX,
    variables: {
      where,
    },
  });
  return data?.reports_top_ten_product_mix;
};

const topTenClerksQuery = async () => {
  const organizationId = getOrganizationState().organizationId;
  const user = getUserState().user;
  const { eventId } = getEventState();

  let where: any = user?.vendor_id
    ? {
        vendor_id: { _eq: user.vendor_id },
      }
    : organizationId
    ? {
        organization_id: {
          _eq: organizationId,
        },
      }
    : {};

  if (eventId) {
    where = {
      ...where,
      event_id: {
        _eq: eventId,
      },
    };
  }
  const { data: clerksData } = await client.query({
    query: TOP_TEN_CLERKS,
    variables: {
      where,
    },
  });

  const { data: userIdsData } = await client.query({
    query: GET_USER_IDS,
    variables: {
      usernames: clerksData.reports_top_ten_clerk
        .map((c: TopTenClerk) => c.username)
        ?.filter((c: String | null) => c),
    },
  });

  const data =
    clerksData.reports_top_ten_clerk.map(
      (user: { id: string; username: string }) => ({
        ...user,
        ...userIdsData?.users?.find(
          (c: TopTenClerk) => c.username === user.username
        ),
      })
    ) || [];

  return data;
};

export const dashboardLoader = async () => {
//   const today = new Date();
//   const todayAt5AM = new Date(today);
//   todayAt5AM.setHours(0, 0, 0, 0);
//
//
//   const throughToday = formatDateForApi(moment(todayAt5AM).add(1, 'days'));
//   const oneDayAgo = formatDateForApi(moment(todayAt5AM).subtract(1, 'days'));
//   const startOfDay = formatDateForApi(moment(todayAt5AM).subtract(1, 'days').add(1, 'days'));
//   const sevenDaysAgo = formatDateForApi(moment(todayAt5AM).subtract(7, 'days'));
//   const thirtyDaysAgo = formatDateForApi(moment(todayAt5AM).subtract(30, 'days'));
//
//   const [
//     yesterdayStats,
//     oneDayStats,
//     sevenDayStats,
//     thirtyDayStats,
//     topTenLocationsData,
//     topTenProductMixData,
//     topTenClerksData,
//   ] = await Promise.all([
//     statsQuery(oneDayAgo,oneDayAgo),
//     statsQuery(startOfDay,throughToday),
//     statsQuery(sevenDaysAgo,throughToday),
//     statsQuery(thirtyDaysAgo,throughToday),
//     topTenLocationsQuery(),
//     topTenProductMixQuery(),
//     topTenClerksQuery(),
//   ]);
//
//   const topTenLocations =
//     (topTenLocationsData && topTenLocationsData?.slice(0, 10)) || [];
//   const topTenProducts =
//     (topTenProductMixData && topTenProductMixData?.slice(0, 10)) || [];
//   const topTenClerks =
//     (topTenClerksData && topTenClerksData?.slice(0, 10)) || [];
//   return {
//     topTenLocations,
//     topTenProducts,
//     topTenClerks,
//     yesterdayStats,
//     oneDayStats,
//     sevenDayStats,
//     thirtyDayStats,
//   };
  return { };
};

export const Dashboard = () => {
  const [stats, setStats] = useState("today");
  const user = useUserStore((state) => state.user);
  // const {
  //   yesterdayStats,
  //   oneDayStats,
  //   sevenDayStats,
  //   thirtyDayStats,
  //   topTenLocations,
  //   topTenProducts,
  //   topTenClerks,
  // } = useLoaderData() as DashboardLoaderData;
  // const mobileStats = () => {
  //   switch (stats) {
  //     case "today":
  //       return <TodayCard data={oneDayStats} />;
  //     case "yesterday":
  //       return <YesterdayCard data={yesterdayStats} />;
  //     case "last7":
  //       return <LastSevenCard data={sevenDayStats} />;
  //     case "last30":
  //       return <LastThirtyCard data={thirtyDayStats} />;
  //   }
  // };


  return (
    <div>
      <div className="bg-white shadow">
        <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="min-w-0 flex-1">
              <div className="flex items-center">
                <div>
                  <div className="flex items-center">
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                      {getGreeting()}, {user?.username} {user?.vendor?.name ? `(${user.vendor.name})` : ''}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*{sevenDayStats && thirtyDayStats && (*/}
      {/*    <div className="mt-8">*/}
      {/*    <h2 className="mx-auto mt-8 mb-4 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">*/}
      {/*      Overview*/}
      {/*    </h2>*/}
      {/*    <div className="px-2 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">*/}
      {/*      <div className="hidden md:block">*/}
      {/*        <div className="grid grid-cols-1 gap-3 sm:grid-cols-4">*/}
      {/*          <div className="flex flex-col mx-auto w-full">*/}
      {/*            <h3 className="text-lg font-medium leading-6 text-gray-900">*/}
      {/*              Today*/}
      {/*            </h3>*/}
      {/*            <dl className="mt-5">*/}
      {/*              <TodayCard data={oneDayStats} />*/}
      {/*            </dl>*/}
      {/*          </div>*/}
      {/*          <div className="flex flex-col mx-auto w-full">*/}
      {/*            <h3 className="text-lg font-medium leading-6 text-gray-900">*/}
      {/*              Yesterday*/}
      {/*            </h3>*/}
      {/*            <dl className="mt-5">*/}
      {/*              <YesterdayCard data={yesterdayStats} />*/}
      {/*            </dl>*/}
      {/*          </div>*/}
      {/*          <div className="flex flex-col mx-auto w-full">*/}
      {/*            <h3 className="text-lg font-medium leading-6 text-gray-900">*/}
      {/*              Last 7 Days*/}
      {/*            </h3>*/}

      {/*            <dl className="mt-5">*/}
      {/*              <LastSevenCard data={sevenDayStats} />*/}
      {/*            </dl>*/}
      {/*          </div>*/}
      {/*          <div className="flex flex-col mx-auto w-full">*/}
      {/*            <h3 className="text-lg font-medium leading-6 text-gray-900">*/}
      {/*              Last 30 Days*/}
      {/*            </h3>*/}

      {/*            <dl className="mt-5">*/}
      {/*              <LastThirtyCard data={thirtyDayStats} />*/}
      {/*            </dl>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="block md:hidden">*/}
      {/*        <div className="grid grid-cols-1">*/}
      {/*          <div className="flex flex-col mx-auto w-full">*/}
      {/*            <Select*/}
      {/*              options={[*/}
      {/*                { name: "Today", id: "today" },*/}
      {/*                { name: "Yesterday", id: "yesterday" },*/}
      {/*                { name: "Last 7 Days", id: "last7" },*/}
      {/*                { name: "Last 30 Days", id: "last30" },*/}
      {/*              ]}*/}
      {/*              defaultValue={null}*/}
      {/*              onChange={setStats}*/}
      {/*            />*/}
      {/*            <dl className="mt-5">{mobileStats()}</dl>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{topTenProducts && <TopTenProducts data={topTenProducts} />}*/}
      {/*{topTenLocations && <TopTenLocations data={topTenLocations} />}*/}
      {/*{topTenClerks && <TopTenClerks data={topTenClerks} />}*/}
    </div>
  );
};
