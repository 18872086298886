import React, { ReactNode } from "react";

interface ShowRowProps {
    label: string;
    value?: any;
    children?: ReactNode;
}

export const ShowRow: React.FC<ShowRowProps> = ({ label, value, children }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">{label}</dt>
            <dd className="flex justify-end mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {value || children}
            </dd>
        </div>
    );
};
