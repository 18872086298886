import { gql } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { userStore, useUserStore } from "../../store/user";
import decodeJwt from "jwt-decode";
import { createUnauthenticatedApolloClient } from "../../providers/ApolloClientFactory";

const HASURA_ENDPOINT = import.meta.env.VITE_SERVICE_HASURA_ENDPOINT;
if (!HASURA_ENDPOINT) throw new Error("missing VITE_SERVICE_HASURA_ENDPOINT");

const LOGIN_MUTATION = gql`
  mutation loginMutation($password: String!, $username: String!) {
    login_dashboard(
      loginInputDashboard: { username: $username, password: $password }
    ) {
      accessToken
    }
  }
`;

export enum PERMISSIONS {
  ADMIN = "admin",
  ORGANIZER = "organizer",
  REPORTING = "reporting",
  MANAGER = "manager",
  VENDOR = "vendor",
  CLERK = "clerk",
  NONE = "none",
  EVENT_MANAGER = "event_manager",
  EVENT_REPORTING = "event_reporting",
  VENDOR_MANAGER = "vendor_manager",
  VENDOR_REPORTING = "vendor_reporting",
}
interface LoginBody {
  username: string;
  password: string;
}

export const login = async ({ username, password }: LoginBody) => {
  const client = createUnauthenticatedApolloClient();
  const { getState } = userStore;
  const { getState: getOrganizationState } = organizationStore;

  const setOrganizationId = getOrganizationState().setOrganizationId;
  const initOrganization = getOrganizationState().initOrganization;
  const fetchUser = getState().fetchUser;

  const { data } = await client.mutate({
    mutation: LOGIN_MUTATION,
    variables: {
      username,
      password,
    },
  });
  const accessToken = data.login_dashboard.accessToken;
  if (!accessToken) {
    return Promise.reject();
  }

  const decodedToken: {
    [key: string]: any;
  } = decodeJwt(accessToken);
  const hasuraClaims = decodedToken["https://hasura.io/jwt/claims"];
  if (!hasuraClaims) {
    return Promise.reject();
  }

  const allowedRoles = ["admin"];
  const organizationId = hasuraClaims["x-hasura-user-organization-id"];
  localStorage.setItem("ron-auth", accessToken);
  localStorage.setItem("permissions", allowedRoles[0]);
  setOrganizationId(organizationId);
  await fetchUser();
  await initOrganization();
  const hasuraDefaultRole = hasuraClaims["x-hasura-default-role"];
  if (hasuraDefaultRole) {
    sessionStorage.setItem("hasuraDefaultRole", hasuraDefaultRole);
  }
};

export const usePermissions = (moduleName?: string) => {
  const user = useUserStore((state) => state.user);
  const permissions: any = user?.role?.permissions ?? {};
  const moduleWise: any = moduleName ? permissions?.[moduleName] : [];
  const deletePermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_DELETE")
  );
  const editCreatePermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_EDIT")
  );
  const ListPermission = moduleWise?.find(
    (module: { label: string; value: string }) =>
      module?.value?.includes("_VIEW")
  );
  return {
    permissions,
    moduleWisePermission: moduleWise,
    deletePermission,
    editCreatePermission,
    ListPermission,
    user,
  };
  // (
  //       localStorage.getItem("permissions") ?? PERMISSIONS.NONE
  //     ) as (typeof PERMISSIONS)[keyof typeof PERMISSIONS])
};

export const useLogout = () => {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const initOrganization = useOrganizationStore(
    (state) => state.initOrganization
  );
  const setOrganizationId = useOrganizationStore(
    (state) => state.setOrganizationId
  );
  return [
    async () => {
      localStorage.removeItem("ron-auth");
      localStorage.removeItem("permissions");
      sessionStorage.removeItem("hasuraDefaultRole");
      setOrganizationId(999999999);
      initOrganization();
      setUser(undefined);
      navigate("/login");
    },
  ];
};
