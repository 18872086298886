export const transactionCreateAction = async () => {};

export const TransactionCreate = () => {
  return (
    <></>
    // <Create title="Create a user" {...props}>
    //   <SimpleForm>
    //     <TextInput disabled source="id" />
    //   </SimpleForm>
    // </Create>
  );
};
