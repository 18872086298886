import { useFetcher, useLocation, useRevalidator } from "react-router-dom";
import { renderEventSidebarOptions } from "../../modules/reports/utils";
import CustomSelect from "../customSelect";
import { useEventStore } from "../../store/event";
import { useEffect } from "react";

export const EventSliderSelector = () => {
  const location = useLocation();
  const revalidator = useRevalidator();
  const fetcher = useFetcher();
  const eventId = useEventStore((state) => state.eventId);
  const {
    setEventId,
    fetchAllEvents,
    allEvents: events,
  } = useEventStore((state) => state);

  useEffect(() => {
    fetchAllEvents();
  }, []);

  const handleChange = async (value: number | null) => {
    // console.log({ value });
    setEventId(value);
    if (
      location.pathname.includes("list") ||
      location.pathname.includes("reports")
    ) {
      revalidator.revalidate();
    }
    fetcher.load("/");
  };

  if (!events?.length) {
    return null;
  }

  return (
    <CustomSelect
      defaultValue={eventId}
      placeholderOnly="Event"
      options={renderEventSidebarOptions(events)}
      onChange={handleChange}
    />
  );
};
