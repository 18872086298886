import { useEffect, useMemo, useState } from "react";
import {
  Form,
  ActionFunctionArgs,
  LoaderFunctionArgs,
  useLoaderData,
  redirect,
  useFetcher,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Input, TextArea } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import {
  organizationStore,
  useOrganizationStore,
} from "../../store/organization";
import { UPDATE_ROLE } from "./mutations";
import { RoleByPK } from "./queries";
import { usePermissions } from "../auth/api";
import { Roles } from "./type";
import IndeterminateCheckbox from "../../components/inputs/IndeterminantCheckbox";
import { adminModules, modules, modulesLabel } from "../../utils";
import DeleteModal from "../../components/DeleteModal";
import { SecondaryButton } from "../../components/Button";
import { userStore } from "../../store/user";
const client = createApolloClient();
const { getState: getOriganizationState } = organizationStore;
const { getState: getUserStore } = userStore;
export const editUserAction = async ({
  request,
  params,
}: ActionFunctionArgs) => {
  const { id } = params;
  const user = getUserStore().user;

  const body = await request.formData();
  const permissions = body.get("permissions");
  const bodyData = Object.fromEntries(body);
  console.log(bodyData);
  const parsePermissions =
    permissions && typeof permissions === "string"
      ? JSON.parse(permissions)
      : {};
  try {
    await client.mutate({
      mutation: UPDATE_ROLE,
      variables: {
        id,
        display_name: body.get("display_name"),
        description: body.get("description"),
        organization_id: body.get("organization_id"),
        permissions: parsePermissions,
        is_active: body.get("is_active"),
        last_updated_by: user?.id,
      },
    });
    toast.success("Role updated");
    return redirect(`/roles/list`);
  } catch (error) {
    toast.error("Unable to update role: " + error);
  }
};

export const roleByIdLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params;
  const { data } = await client.query({
    query: RoleByPK,
    variables: { id },
  });

  return { rolesData: data.roles_by_pk };
};

export const RoleEdit = () => {
  const permissions = sessionStorage.getItem("hasuraDefaultRole");
  const organizationId = useOrganizationStore((state) => state.organizationId);
  const organizations = useOrganizationStore((state) => state.organizations);
  const { rolesData } = useLoaderData() as {
    rolesData: Roles;
  };
  const permissionData = useMemo(
    () => rolesData?.permissions || {},
    [rolesData?.permissions]
  );
  const [selectedPermissions, setSelectedPermissions] = useState<any>({});
  useEffect(() => {
    setSelectedPermissions(permissionData);
  }, [permissionData]);
  const { deletePermission } = usePermissions("roles");
  const fetcher = useFetcher();
  return (
    <Form method="post" action={`/roles/${rolesData.id}/edit`}>
      {deletePermission && (
        <div className="flex">
          <div className="ml-auto py-2">
            <DeleteModal
              onConfirm={() => {
                fetcher.submit(
                  {},
                  {
                    method: "delete",
                    action: `/roles/${rolesData.id}/delete`,
                  }
                );
              }}
            >
              {({ setOpen }) => (
                <SecondaryButton onClick={() => setOpen(true)}>
                  Delete
                </SecondaryButton>
              )}
            </DeleteModal>
          </div>
        </div>
      )}
      <FormLayout>
        <div className="col-span-4">
          <Input
            label="Role Name"
            name="display_name"
            required
            defaultValue={rolesData.display_name}
          />
        </div>
        <div className="col-span-2">
          <IndeterminateCheckbox
            label="Is Active?"
            name="is_active"
            defaultChecked={rolesData.is_active}
          />
        </div>
        {permissions === "admin" && (
          <div className="col-span-6">
            <CustomSelect
              title="Organization"
              name="organization_id"
              required
              defaultValue={rolesData.organization_id}
              options={organizations.map((org) => ({
                label: org.name,
                value: org.id,
              }))}
            />
          </div>
        )}
        {permissions !== "admin" && (
          <input
            type="hidden"
            value={organizationId ?? ""}
            name="organization_id"
          />
        )}
        <div className="col-span-6">
          <TextArea
            label="Description"
            name="description"
            required
            defaultValue={rolesData.description}
          />
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <input
            name="permissions"
            type="hidden"
            value={JSON.stringify(selectedPermissions)}
          />

          <label className="col-span-5 block text-lg font-medium text-gray-700">
            Permissions
          </label>

          <div className="col-span-6">
            {Object.keys(permissions === "admin" ? adminModules : modules)?.map(
              (module: string) => (
                <>
                  <div className="col-span-6">
                    <label className="col-span-1 block text-md pb-2 font-medium text-gray-700">
                      {modulesLabel[module]}
                    </label>
                  </div>
                  <div className="bg-gray-300 col-span-6 grid grid-cols-6 rounded p-2">
                    {(permissions === "admin" ? adminModules : modules)[
                      module
                    ]?.map((moduleData: { label: string; value: string }) => (
                      <IndeterminateCheckbox
                        defaultChecked={
                          (selectedPermissions[module] || [])?.filter(
                            (permissionData: any) =>
                              permissionData?.value === moduleData?.value
                          )?.length > 0
                        }
                        label={moduleData?.label}
                        name={moduleData?.value}
                        onChange={(data) => {
                          const selectPermissionByType =
                            selectedPermissions[module] || [];
                          let currentModulePresent = [
                            ...selectPermissionByType,
                          ];
                          if (selectPermissionByType?.length > 0) {
                            if (!data) {
                              const modulePresent =
                                currentModulePresent?.filter(
                                  (currentModule) =>
                                    currentModule?.value === moduleData?.value
                                );
                              if (modulePresent?.length > 0) {
                                currentModulePresent =
                                  currentModulePresent?.filter(
                                    (currentModule) =>
                                      currentModule?.value !== moduleData?.value
                                  );
                              } else {
                                currentModulePresent.push(moduleData);
                              }
                            } else {
                              currentModulePresent.push(moduleData);
                            }
                          } else {
                            currentModulePresent.push(moduleData);
                          }
                          setSelectedPermissions({
                            ...selectedPermissions,
                            [module]: currentModulePresent,
                          });
                        }}
                      />
                    ))}
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </FormLayout>
    </Form>
  );
};
