import { ColumnDef } from "@tanstack/table-core";
import { useLoaderData } from "react-router";
import Table from "../../../components/Table";
import { Discount } from "../../../types/discount";
import { Order } from "../../../types/order";
import { usdFormat } from "../../../utils";

export const DiscountTable = () => {
  const order = useLoaderData() as Order;

  let discounts: Discount[] = [];

  // Handle if order.discount is not an array but a single object
  if (Array.isArray(order.discount)) {
    discounts = [...order.discount];
  } else if (order.discount) {
    discounts = [order.discount];
  }

  const columns: ColumnDef<Discount>[] = [
    {
      header: "Name",
      accessorKey: "name",
      cell: (info) => info.row.original.name,
    },
    {
      header: "Type",
      accessorKey: "discount_type",
      cell: (info) => info.row.original.discount_type,
    },
    {
      header: "Amount",
      accessorKey: "applied_discount",
      cell: (info) => usdFormat.format(info.getValue<number>() / 100),
    },
  ];

  return <Table columns={columns} data={discounts} />;
};
