import { Link } from "react-router-dom";
import { useRouteError } from "react-router-dom";

export const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <div className="h-[calc(100vh_-_64px)] w-full flex justify-center items-center flex-col">
      <h1 className="text-2xl">
        Oops! Something went wrong.
      </h1>
      <Link className="text-blue-500 underline" to="/">
        Return Home
      </Link>
    </div>
  );
};
