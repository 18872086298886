export const transactionsListLoader = async () => {};

export const TransactionList = () => {
  return (
    <></>
    // <List {...props} className="mx-2">
    //   <Datagrid>
    //     <TextField source="order_id" />
    //     <TextField source="payment_id" />
    //     <TextField source="amount" />
    //     <ShowButton basePath="/transactions" />
    //     <EditButton basePath="/transactions" />
    //     <DeleteButton basePath="/transactions" />
    //   </Datagrid>
    // </List>
  );
};
