import { gql } from "@apollo/client";

export const UsersListQuery = gql`
  query UsersList($where: users_bool_exp) {
    users(where: $where, order_by: { username: asc }) {
      id
      is_active
      username
      created_at
      updated_at
      first_name
      last_name
      events
      vendors
      role {
        id
        name
        display_name
      }
      phone_number
      vendor {
        id
        name
      }
      email
      tablet_access_code
      organization_id
      event_id
      users_event {
        id
        name
      }
      user {
        username
      }
    }
  }
`;

export const UsersListQueryEventFilter = gql`
  query UsersList($eventWhere: users_bool_exp) {
    users(where: $eventWhere, order_by: { username: asc }) {
      id
      is_active
      username
      created_at
      updated_at
      first_name
      last_name
      events
      vendors
      role {
        id
        name
        display_name
      }
      phone_number
      vendor {
        id
        name
      }
      email
      tablet_access_code
      organization_id
      event_id
      users_event {
        id
        name
      }
      user {
        username
      }
    }
  }
`;

export const UserByPK = gql`
  query UserById($id: Int!) {
    users_by_pk(id: $id) {
      id
      is_active
      username
      created_at
      updated_at
      first_name
      last_name
      role_id
      role {
        name
      }
      phone_number
      email
      tablet_access_code
      organization_id
      events
      vendors
      event_id
      users_event {
        id
        name
      }
      vendor_id
      vendor {
        name
      }
      vendors
      events
    }
  }
`;

export const GET_LIST_EVENTS_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: events_bool_exp!) {
    events(order_by: { name: asc }, where: $where) {
      id
      name
      location_zones
    }
  }
`;
