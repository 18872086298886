import moment from "moment";
import { usdFormat } from "../../../utils";
import { TransactionReportRow } from "./type";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/Badge";
import {ColDef} from "ag-grid-community";

type CellRenderer = (params: { value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; data: TransactionReportRow; }) => JSX.Element;

const createCellRenderer = (permission: boolean, linkFunc: (params: { data: TransactionReportRow }) => string): CellRenderer => {
  return (params) => (
      <>
        {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
        ) : (
            <Badge>{params.value}</Badge>
        )}
      </>
  );
};
const generateColDefs = (): ColDef<TransactionReportRow>[] => [
  {
    headerName: 'Date',
    valueGetter: p => p.data?.transaction_time,
    pinned: 'left',
    valueFormatter: p => {
      const date = p.value ? moment(p.value) : null;
      return date ? date.format('ddd, MMM DD, YYYY h:mm a') : '';
    }
  },
  {
    headerName: 'Date_Raw',
    valueGetter: p => p.data?.transaction_time,
    hide: true,
  },
  {
    headerName: 'Username',
    valueGetter: p => p.data?.username,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Order ID',
    valueGetter: p => p.data?.order_id,
    cellRenderer: createCellRenderer(true, (p) => `/orders/${p.data.payment_ref_id}/${p.data?.order_id}`),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Transaction Amount',
    valueGetter: p => p.data?.transaction_amount,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Ronin Ref ID',
    valueGetter: p => p.data?.payment_ref_id,
    filter: 'agTextColumnFilter',
  },
  {
    headerName: 'Payment Processor Ref ID',
    valueGetter: p => p.data?.order_mx_ref_id,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Order Status',
    valueGetter: p => p.data?.order_status,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Payment Status',
    valueGetter: p => p.data?.payment_status,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Payment Type',
    valueGetter: p => p.data?.payment_type,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Cashless Asset ID',
    valueGetter: p => p.data?.uid,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Last 4 of CC',
    valueGetter: p => p.data?.last4,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Card Type',
    valueGetter: p => p.data?.card_type,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Item Sub Total',
    valueGetter: p => p.data?.item_subtotal,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Tax',
    valueGetter: p => p.data?.tax,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Tip',
    valueGetter: p => p.data?.tip,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Service Fee',
    valueGetter: p => p.data?.digital_surcharge,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Applied Discount Name',
    valueGetter: p => p.data?.applied_discount_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Applied Discount Amount',
    valueGetter: p => p.data?.applied_discount_amount,
    valueFormatter: params => usdFormat.format((params.value ?? 0)/100),
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Organization Name',
    valueGetter: p => p.data?.organization_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Event Name',
    valueGetter: p => p.data?.event_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Vendor Name',
    valueGetter: p => p.data?.vendor_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Location Name',
    valueGetter: p => p.data?.location_name,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Order Items Count',
    valueGetter: p => p.data?.order_items_count,
    filter: 'agTextColumnFilter'
  },
  {
    headerName: 'Device Identifier',
    valueGetter: p => p.data?.device_app_id,
    filter: 'agTextColumnFilter'
  },
];

export const ordersColDefs: ColDef<TransactionReportRow>[] = generateColDefs();