import gql from "graphql-tag";

export const QUERY_LIST_LOCATION_MENU = gql`
  query ListLocationMenu(
    $where: location_menu_bool_exp
    $order_by: [location_menu_order_by!]
  ) {
    location_menu(where: $where, order_by: $order_by) {
      id
      menu_id
      location_id

      menu {
        id
        name
        updated_at
        created_at
      }
      location {
        id
        name
        updated_at
        created_at
      }
    }
  }
`;

export const GET_LIST_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id

    menu {
      id
      name
      updated_at
      created_at
    }
    location {
      id
      name
      updated_at
      created_at
    }
  }
`;

export const GET_ONE_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;

export const CREATE_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;
export const UPDATE_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;

export const GET_MANY_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;

export const GET_MANY_REFERENCE_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;

export const DELETE_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;

export const DELETE_MANY_LOCATION_MENU = gql`
  {
    id
    menu_id
    location_id
  }
`;
