import { Outlet, useLocation, useNavigate, } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  editTicketsAction,
  editTicketsLoader,
  TicketsEdit
} from "./TicketsEdit";
import { TicketsList, ticketsListLoader} from "./TicketsList";
import { RFID } from "../../types/rfid";

const TicketsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/tickets") {
    navigate(`/tickets/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const ticketsRouter = {
  path: "/tickets",
  id: "tickets",
  element: <TicketsIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Tickets" href="/tickets/list" />,
  },
  children: [
    {
      path: "list",
      id: "tickets-list",
      element: <TicketsList />,
      loader: ticketsListLoader,
    },
    {
      path: ":id/edit",
      id: "tickets-edit",
      element: <TicketsEdit />,
      loader: editTicketsLoader,
      action: editTicketsAction,
      handle: {
        crumb: (data: { rfid: RFID }) => (
          <Breadcrumb
            name={`Edit ${data.rfid.uid}`}
            href={`/tickets/${data.rfid.id}/edit`}
          />
        ),
      },
    },
  ],
};
