import {
  ActionFunctionArgs,
  Form,
  LoaderFunctionArgs,
  useLoaderData,
} from "react-router-dom";
import { FormFooter } from "../../components/FormFooter";
import { Input } from "../../components/inputs/Input";
import CustomSelect from "../../components/customSelect";
import { FormLayout } from "../../layout/FormLayout";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_LIST_EVENTS_BY_ORGANIZATION } from "../../queries";
import { organizationStore } from "../../store/organization";
import { Device } from "../../types/device";
import { Event } from "../../types/event";
import { UPDATE_DEVICE } from "./mutations";
import { GET_DEVICE } from "./queries";

const client = createApolloClient();

const { getState } = organizationStore;

export const deviceEditLoader = async ({ params }: LoaderFunctionArgs) => {
  const organizationId = getState().organizationId;
  const { id } = params;
  const [{ data: deviceData }, { data: eventsData }] = await Promise.all([
    client.query<Device>({
      query: GET_DEVICE,
      variables: {
        id,
      },
    }),
    client.query<Event[]>({
      query: GET_LIST_EVENTS_BY_ORGANIZATION,
      variables: {
        where: organizationId
          ? {
              organization_id: { _eq: organizationId },
            }
          : {},
      },
    }),
  ]);

  return {
    device: deviceData,
    events: eventsData,
  };
};

export const deviceEditAction = async ({
  params,
  request,
}: ActionFunctionArgs) => {
  const { id } = params;
  const body = await request.formData();
  await client.mutate({
    mutation: UPDATE_DEVICE,
    variables: {
      where: {
        id: {
          _eq: id,
        },
      },
      input: Object.fromEntries(body),
    },
  });
};

export const DeviceEdit = () => {
  const { device, events } = useLoaderData() as {
    device: Device;
    events: Event[];
  };
  return (
    <Form action={`/devices/${device.id}/edit`} method="put">
      <FormLayout>
        <div className="col-span-3 sm:col-span-2">
          <Input
            name="name"
            label="Name"
            defaultValue={device?.profile?.name}
          />
        </div>
        <div className="col-span-3 sm:col-span-2">
          <CustomSelect
            options={
              events?.map?.((e) => ({
                value: e?.id,
                label: e?.name,
              })) || []
            }
            title="Event"
            name="event_id"
            defaultValue={device?.configuration?.event_id}
          />
        </div>
        <FormFooter />
      </FormLayout>
    </Form>
  );
};
