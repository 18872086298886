import {
  LOCATIONS_BY_ORGANIZATION,
  LOCATIONS_BY_ORGANIZATION_VENDOR,
} from "../queries/locations";
import { LIST_ORGANIZATIONS } from "../queries/get-list-organizations";
import { CLERKS_BY_ORGANIZATION } from "../queries/clerks";
import { ALL_ORGANIZATIONS } from "../queries/get-all-organizations";
import { persist } from "zustand/middleware";
import create from "zustand";
import createVanilla from "zustand/vanilla";
import { createApolloClient } from "../providers/ApolloClientFactory";
import { userStore } from "./user";
import { eventStore } from "./event";

interface OrganizationState {
  organizationId: number | null;
  organizations: any[];
  locations: any[];
  clerks: any[];
  vendorLocations: any[];
  allOrganizations: any[];
  setOrganizationId: (organizationId: number) => void;
  initOrganization: () => Promise<void>;
  fetchOrganizations: () => Promise<void>;
  fetchLocations: () => Promise<void>;
  fetchVendorLocations: () => Promise<void>;
  fetchClerks: () => Promise<void>;
  fetchAllOrganizations: () => Promise<void>;
}

const client = createApolloClient();

const { getState } = userStore;
const { getState: getEventStore } = eventStore;

export const organizationStore = createVanilla<OrganizationState>()(
  persist(
    (set, get) => ({
      organizationId: null,
      organizations: [],
      locations: [],
      clerks: [],
      vendorLocations: [],
      allOrganizations: [],

      setOrganizationId: (organizationId: number | null) => {
        const { setEventId, fetchAllEvents } = getEventStore();

        set({ organizationId });
        fetchAllEvents();
        setEventId(null);
      },

      initOrganization: async () => {
        const permissions = getState().permissions;
        const { fetchAllEvents } = getEventStore();

        const state = get();
        fetchAllEvents();

        await Promise.all([
          state.fetchOrganizations(),
          state.fetchAllOrganizations(),
          state.fetchLocations(),
          state.fetchVendorLocations(),
          state.fetchClerks(),
        ]);
      },

      fetchOrganizations: async () => {
        const { data } = await client.query({
          query: LIST_ORGANIZATIONS,
        });
        set({
          organizations: data.organizations,
        });
      },
      fetchLocations: async () => {
        const organizationId = get().organizationId;
        let where: any = {};
        if (organizationId) {
          where = {
            ...where,
            event: { organization: { id: { _eq: organizationId } } },
          };
        }
        const { data } = await client.query({
          query: LOCATIONS_BY_ORGANIZATION,
          variables: {
            where,
          },
        });
        set({
          locations: data.locations,
        });
      },
      fetchVendorLocations: async () => {
        const user = getState().user;

        if (!user?.vendor_id) return;

        const { data } = await client.query({
          query: LOCATIONS_BY_ORGANIZATION_VENDOR,
          variables: {
            organizationId: get().organizationId,
            vendorId: user?.vendor_id,
          },
        });
        set({
          vendorLocations: data.locations,
        });
      },
      fetchClerks: async () => {
        const organizationId = get().organizationId;
        // console.log({ organizationId });
        let where: any = {};

        if (organizationId) {
          where = {
            ...where,
            organization_id: { _eq: organizationId },
          };
        }
        const { data } = await client.query({
          query: CLERKS_BY_ORGANIZATION,
          variables: {
            where,
          },
        });
        set({
          clerks: data.users,
        });
      },
      fetchAllOrganizations: async () => {
        const { data } = await client.query({
          query: ALL_ORGANIZATIONS,
        });
        set({
          allOrganizations: data.organizations,
        });
      },
    }),
    {
      name: "organization",
    }
  )
);

export const useOrganizationStore = create(organizationStore);
