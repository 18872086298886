import { ErrorBoundary } from "../components/ErrorBoundary";
import Sidebar from "../components/Sidebar";
import { useUserStore } from "../store/user";
import { ResourceLayout } from "./ResourceLayout";

export const ErrorLayout = () => {
  const user = useUserStore((state) => state.user);

  if (!user) {
    return (
      <>
        <ErrorBoundary />
      </>
    );
  }

  return (
    <Sidebar>
      <ResourceLayout>
        <ErrorBoundary />
      </ResourceLayout>
    </Sidebar>
  );
};
