import { gql } from "@apollo/client";

export const TOP_TEN_PRODUCT_MIX = gql`
  query top_ten_product_mix($where: reports_top_ten_product_mix_bool_exp!) {
    reports_top_ten_product_mix(
      where: $where
      order_by: { total_net_sales: desc }
    ) {
      pos_name
      product_type
      total_net_sales
      vendor_id
      total_units_sold
    }
  }
`;
