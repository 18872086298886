import { ColumnDef } from "@tanstack/react-table";

import { Link, LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import Table from "../../components/Table";
import Tabs from "../../components/Tabs";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_ORGANIZATION } from "./queries";
import { Event } from "../../types/event";
import moment from "moment";
import { OrganizationUser } from "../../types/organizationUser";
import { capitalize } from "../../utils";

const client = createApolloClient();

export const organizationByIdLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const { id } = params;
  const { data } = await client.query({
    query: GET_ORGANIZATION,
    variables: { id },
  });
  return data?.organizations_by_pk;
};

const eventColumns: ColumnDef<Event>[] = [
  {
    accessorKey: "name",
    header: "Name",
    cell: (info) => (
      <Link to={`events/${info.row.original.id}`}>
        {info.getValue<String>()}
      </Link>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Created",
    cell: (info) =>
      moment(info.getValue<string>()).format("MMMM Do YYYY, h:mm a"),
  },
];

const userColumns: ColumnDef<OrganizationUser>[] = [
  {
    accessorKey: "username",
    header: "Name",
    cell: (info) => (
      <Link to={`users/${info.row.original.id}`}>
        {info.getValue<String>()}
      </Link>
    ),
  },
  {
    accessorKey: "created_at",
    header: "Created",
    cell: (info) =>
      moment(info.getValue<string>()).format("MMMM Do YYYY, h:mm a"),
  },
  {
    accessorKey: "role.name",
    header: "Role",
    cell: (info) => capitalize(info.getValue<string>()),
  },
];

export const OrganizationsShow = () => {
  const { events, users } = useLoaderData() as {
    events: Event[];
    users: OrganizationUser[];
  };

  return (
    <Tabs
      tabs={[
        {
          id: "events",
          name: "Events",
        },
        {
          id: "users",
          name: "Users",
        },
      ]}
    >
      {({ selectedTab }) =>
        selectedTab === "events" ? (
          <Table columns={eventColumns} data={events} />
        ) : (
          <Table columns={userColumns} data={users} />
        )
      }
    </Tabs>
  );
};
