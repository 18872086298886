import { gql } from "@apollo/client";

export const CREATE_ITEM_MODIFIER = gql`
  mutation create_item($input: item_modifiers_insert_input!) {
    insert_item_modifiers_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_ITEM_MODIFIER = gql`
  mutation update_items($input: item_modifiers_set_input, $where: item_modifiers_bool_exp!) {
    update_item_modifiers(_set: $input, where: $where) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_ITEM_MODIFIER = gql`
   mutation DeleteItem($id: Int!) {
    delete_item_modifiers_by_pk(id: $id) {
      id
    }
  }
`;
