export const transactionEditLoader = async () => {};
export const transactionEditAction = async () => {};

export const TransactionEdit = () => {
  return (
    <></>
    // <Edit title="Edit user" {...props}>
    //   <SimpleForm>
    //     <TextInput disabled source="id" />
    //   </SimpleForm>
    // </Edit>
  );
};
