import { toast } from "react-hot-toast";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { GET_RECEIPT_ONE } from "../queries";

const client = createApolloClient();

export const generateReceipt = async (reference_id: string) => {
  const { data } = await client.query({
    query: GET_RECEIPT_ONE,
    variables: {
      reference_id,
    },
  });

  const receipt = data.receipt_one;

  if (receipt) {
    return toast((t) => (
      <div onClick={() => toast.dismiss(t.id)}>
        {/* Show generated receipt url */}
        <a
          href={`${import.meta.env.VITE_RECEIPT_ENDPOINT}/${
            receipt.reference_id
          }`}
          className="text-blue-500 underline"
          target="_blank"
          rel="noreferrer"
        >
          Receipt Generated
        </a>
      </div>
    ));
  }
  return toast.error("Receipt Failed");
};
