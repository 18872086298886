import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import { Breadcrumb } from "../../components/Breadcrumb";
import { ItemEdit, itemEditAction, itemEditLoader } from "./ItemEdit";
import { ItemList, itemsListLoader } from "./ItemList";
import { Item } from "../../types/item";
import { ItemShow, itemShowLoader } from "./ItemShow";
import { ItemCreate, itemCreateAction, itemCreateLoader } from "./ItemCreate";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_ITEM } from "./mutations";

const client = createApolloClient();

const ItemIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/items") {
    navigate(`/items/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const itemDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_ITEM,
      variables: { id },
    });
    toast.success("Item Deleted");
    return redirect(`/items/list`);
  } catch {
    toast.error("Failed to delete item, Check Menus and Remove Item");
    return null;
  }
};

export const itemsRouter = {
  path: "/items",
  id: "items",
  element: <ItemIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Items" href="/items/list" />,
  },
  children: [
    {
      path: "list",
      id: "items-list",
      element: <ItemList />,
      loader: itemsListLoader,
    },
    {
      path: ":id",
      id: "items-show",
      element: <ItemShow />,
      loader: itemShowLoader,
      handle: {
        crumb: (data: Item) => (
          <Breadcrumb name={data.name} href={`/items/${data.id}`} />
        ),
      },
    },
    {
      path: "create",
      id: "items-create",
      element: <ItemCreate />,
      action: itemCreateAction,
      loader: itemCreateLoader,
      handle: {
        crumb: () => <Breadcrumb name={`Create Item`} href={`/items/create`} />,
      },
    },
    {
      path: ":id/edit",
      id: "items-edit",
      element: <ItemEdit />,
      loader: itemEditLoader,
      action: itemEditAction,
      handle: {
        crumb: (data: Item) => (
          <Breadcrumb
            name={`Edit ${data.name}`}
            href={`/items/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "items-delete",
      action: itemDeleteAction,
    },
  ],
};
