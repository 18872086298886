import { Fragment, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Input } from './inputs/Input';
import { PrimaryButton } from './Button';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

interface SendEmailModalProps {
  isOpen: boolean;  // Control modal visibility
  onClose: () => void;  // Close modal callback
  type: string;
  title: string;// Pass the type (mail or sms) down as a prop
  onConfirm: (params: {
    type: string;
    value: string;
    setOpen: any;
    setEmail: any;
    setPhoneNumber: any;
    setType: any;
  }) => void;
}

export default function PromoCodeSendModal({
                                             isOpen,
                                             onClose,
                                             type,  // Use the type passed down from ButtonCellRenderer
                                             onConfirm,
                                             title,
                                           }: SendEmailModalProps) {
  const cancelButtonRef = useRef(null);
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const handleConfirm = async () => {
    if ((type === 'mail' && email) || (type === 'sms' && phoneNumber)) {
      onConfirm({
        type: type,
        value: type ? (type === 'mail' ? email : phoneNumber) : '',
        setOpen: onClose, // Use onClose to close modal
        setEmail,
        setPhoneNumber,
        setType: () => {}, // Resetting type is unnecessary here, pass a no-op
      });
    }
  };

  return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
            as="div"
            className="relative z-10"
            initialFocus={cancelButtonRef}
            onClose={onClose} // Control modal close
        >
          <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 py-8 text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        {title} {type === 'mail' ? 'Email' : 'SMS'}
                      </Dialog.Title>
                      <div className="mt-2 justify-items-center">
                        {type === 'mail' ? (
                            <Input
                                defaultValue={email}
                                placeholder="Email"
                                type="email"
                                name="email"
                                onChange={(value?: any) => setEmail(value || '')}
                            />
                        ) : (
                            <PhoneInput
                                defaultCountry={'us'}
                                value={phoneNumber}
                                onChange={(value?: any) =>
                                    setPhoneNumber(value || '')
                                }
                            />
                        )}
                      </div>
                      <div className="mt-2">
                        <PrimaryButton onClick={handleConfirm}>
                          Submit
                        </PrimaryButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  );
}
