import create from "zustand";
import createVanilla from "zustand/vanilla";
import { persist } from "zustand/middleware";
import { redirect } from "react-router-dom";

import { GET_USER, GET_VENDOR_USER } from "../queries/users";
import { getUserId } from "../providers/AuthProvider";
import { OrganizationUser } from "../types/organizationUser";
import { createApolloClient } from "../providers/ApolloClientFactory";
import { PERMISSIONS } from "../modules/auth/api";
import { organizationStore } from "./organization";

interface UserState {
  user: OrganizationUser | undefined;
  permissions: PERMISSIONS | undefined;
  fetchUser: () => void;
  setUser: (user: OrganizationUser | undefined) => void;
}

export const userStore = createVanilla<UserState>()(
  persist(
    (set) => ({
      user: undefined,
      permissions: undefined,
      fetchUser: async () => {
        const client = createApolloClient();
        const userId = getUserId();
        const { getState: getOrganizationState } = organizationStore;

        const setOrganizationId = getOrganizationState().setOrganizationId;

        const permissions =
          (localStorage.getItem("permissions") as PERMISSIONS) ?? undefined;
        try {
          // VENDOR_ONLY.includes(permissions) ? GET_VENDOR_USER :
          const { data } = await client.query({
            query: GET_USER,
            variables: { userId },
          });
          // setOrganizationId(data.users_by_pk?.data.users_by_pk);

          console.log({ users_by_pk: data.users_by_pk });
          set({ user: data.users_by_pk, permissions });
        } catch {
          redirect("/login");
        }
      },
      setUser: (user) => set({ user }),
    }),
    { name: "user" }
  )
);

export const useUserStore = create(userStore);
