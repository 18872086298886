import { gql } from "@apollo/client";

export const GET_DISCOUNTS = gql`
  query GetDiscounts($where: discount_bool_exp!) {
    discount(where: $where, order_by: { name: asc }) {
      amount
      code
      description
      name
      id
      discount_type
      created_at
      updated_at
      percentage
      org {
        id
        name
      }
      user {
        username
      }
    }
  }
`;

export const GET_DISCOUNT = gql`
  query DiscountByPk($id: Int!) {
    discount_by_pk(id: $id) {
      amount
      code
      description
      name
      id
      org {
        id
        name
      }
      discount_type
      percentage
    }
  }
`;
