import gql from "graphql-tag";

export const GET_LIST_EVENTS = gql`
  query events($where: events_bool_exp!) {
    events(where: $where, order_by: { name: asc }) {
      end_date
      currency
      id
      created_at
      configuration
      name
      description
      logo
      background_url
      payment_types
      location_zones
      color_mode
      digital_surcharge_label
      payment_processor_id

      payment_processor_config {
        payment_processor
      }

      organization_id
      slug

      start_date
      timezone
      timezone_diff
      updated_at
      locations {
        name
        id
      }
    }
  }
`;

export const CREATE_EVENT = gql`
  {
    end_date
    currency
    id
    created_at
    configuration
    name
    description
    logo
    background_url
    payment_types
    color_mode
    digital_surcharge_label
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    organization_id
    slug
    signup_questions
    start_date
    timezone
    timezone_diff
    updated_at
    locations {
      name
      id
    }
  }
`;

export const UPDATE_EVENT = gql`
  {
    end_date
    currency
    id
    created_at
    configuration
    name
    description
    logo
    background_url
    payment_types
    color_mode
    digital_surcharge_label
    start_date
    payment_processor_id
    signup_questions
    payment_processor_config {
      payment_processor
    }

    organization_id
    slug

    timezone
    timezone_diff
    updated_at
    locations {
      name
      id
    }
  }
`;

export const GET_ONE_EVENT = gql`
  {
    id
    name
    start_date
    end_date
    currency
    description
    logo
    background_url
    payment_types
    payment_processor_id
    signup_questions

    payment_processor_config {
      payment_processor
    }

    organization_id
    slug
    color_mode
    digital_surcharge_label
    timezone
    timezone_diff
  }
`;

export const GET_MANY_EVENT = gql`
  {
    id
    name
    currency
    description
    logo
    background_url
    payment_types
    color_mode
    digital_surcharge_label
    timezone
    timezone_diff
    configuration
    created_at
    updated_at
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    organization_id
    slug
    start_date
    end_date
  }
`;

export const GET_LIST_EVENTS_BY_ORGANIZATION = gql`
  query EventsByOrganization($where: events_bool_exp!) {
    events(order_by: { name: asc }, where: $where) {
      id
      name
      location_zones
    }
  }
`;


