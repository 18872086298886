import { gql } from "@apollo/client";

export const GET_ORDER = gql`
  query order($id: Int!, $reference_id: String!) {
    orders_by_pk(id: $id, reference_id: $reference_id) {
      id
      discount
      subtotal
      digital_surcharge
      tax
      tip
      status
      phone_number
      user_id
      created_at
      updated_at
      transaction_at
      reference_id
      attendee_id
      transaction_time
      device_id
      device_app_id
          email
      notes
      promo_code_uid
      promo_code
      cash_charged
      credit_present_charged
      credit_not_present_charged
      token_value_charged
      promo_balance_charged
      cash_balance_charged
      total_discount
      total_promo_discount
      build_id
      android_device_id
      new_order_payload
      tappit_charged
      tappit_uid
      split_payment
      is_tab
      name
      nickname
      attendee {
        rfid_assets {
          uid
          __typename
        }
        __typename
      }
      uid
      event {
        name
        __typename
      }
      location {
        name
        __typename
      }
      reference_id
      items
      user {
        id
        username
        phone_number
        email
        __typename
      }
      menu_id
      menu {
        name
        __typename
      }
      vendor_id
      vendor {
        name
        __typename
      }
      tokens_redeemed
      mx_ref_id
      transactions {
        created_at
        payment {
          amount
          id
          payment_data
          payment_type
          reference_id
          status
          __typename
        }
        amount
        status
        id
        note
        __typename
      }
      __typename
    }
  }
`;

export const GET_RECEIPT_ONE = gql`
  query get_receipt_one($reference_id: String!) {
    receipt_one(orderRef: { reference_id: $reference_id }) {
      reference_id
    }
  }
`;

export const GET_ORDERS = gql`
  query reports_transactions(
    $where: reports_transactions_bool_exp!
    $limit: Int
  ) {
    reports_transactions(
      where: $where
      limit: 1000
      order_by: { transaction_time: desc }
    ) {
      username
      order_items_count
      organization_id
      organization_name
      event_id
      event_name
      location_id
      location_name
      order_id
      transaction_date
      transaction_hour
      transaction_time
      order_mx_ref_id
      payment_ref_id
      order_status
      payment_status
      payment_type
      transaction_amount
      item_subtotal
      tax
      tip
      digital_surcharge
      device_app_id
      applied_discount_amount
      applied_discount_name
      vendor_name
      uid
      last4
      card_type
      email
      attendee_id
      items_json
      is_tab
      tab_name
      tab_nickname
      promo_code_uid
      build_id
      total_promo_discount
      created_at
      build_id
    }
  }
`;