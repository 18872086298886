export enum VendorType {
  BAR = "bar",
  FOOD = "food",
  MERCHANDISE = "merchandise",
  CRAFT = "craft",
  OTHER = "other",
}

export interface Vendor {
  id: number;
  name: string;
  type: VendorType;
  other_type: string;
  organization_id: number;
  poc_name: string;
  poc_email: string;
  poc_phone: string;
  updated_at: string;
  created_at: string;
  is_active: boolean;
  revenue_share: number;
  sales_tax_responsible: boolean;
  flat_fee: number;
  user: {
    username: string;
  };
}
