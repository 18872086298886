import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

export function ResourceLayout({ children }: { children: ReactNode }) {
  const location = useLocation();

  if (location.pathname === "/dashboard") {
    return <div className="mx-auto max-w-7xl pb-4">{children}</div>;
  }

  return (
    <div className="min-h-full mx-auto max-w-7xl sm:px-6 lg:px-8 pb-4">
      {children}
    </div>
  );
}
