import { ReactNode } from "react";

export function ShowLayout({
  header,
  actions,
  children,
}: {
  header: string;
  actions?: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="flex justify-between px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          {header}
        </h3>
        {actions}
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">{children}</dl>
      </div>
    </div>
  );
}
