import { gql } from "@apollo/client";

export const DEFERRED_REPORT = gql`
  query reports_transactions(
    $where: reports_transactions_bool_exp!
  ) {
    reports_transactions(
      where: $where
      order_by: { transaction_time: desc }
    ) {
      username
      order_items_count
      organization_id
      organization_name
      event_id
      event_name
      location_id
      location_name
      order_id
      transaction_date
      transaction_hour
      transaction_time
      order_mx_ref_id
      payment_ref_id
      order_status
      payment_status
      payment_type
      transaction_amount
      item_subtotal
      tax
      tip
      digital_surcharge
      applied_discount_amount
      applied_discount_name
      vendor_name
      uid
      last4
      card_type
      device_app_id
      items_json
    }
  }
`;
