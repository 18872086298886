import { gql } from "@apollo/client";

export const WEB_CHECKOUT_REPORT = gql`
  query web_checkout_orders(
    $where: web_checkout_orders_bool_exp!
    $offset: Int!
    $limit: Int!
  ) {
    web_checkout_orders(
      where: $where
      limit: $limit
      offset: $offset
      order_by: { created_at: desc }
    ) {
      amount
    billing_address_1
    billing_address_2
    billing_city
    billing_first_name
    billing_last_name
    billing_state
    billing_zipcode
    card_type
    client_email
    client_first_name
    client_last_name
    client_phone_number
    created_at
    event_id
    id
    last_four_of_card
    updated_at
    total_amount
    service_fee
    selector
    selection
    reference_id
    processor_reference_id
    payment_data
    organization_id
    }
  }
`;
export const WEB_CHECKOUT_REPORT_COUNT = gql`
  query web_checkout_orders($where: web_checkout_orders_bool_exp!) {
    total: web_checkout_orders_aggregate(where: $where) {
    aggregate {
      count
      __typename
    }
    __typename
  }
}
`;
