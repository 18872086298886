import { RFIDAssetsRow } from "./type";
import { Link } from "react-router-dom";
import { Badge } from "../../../components/Badge";
import {ColDef} from "ag-grid-community";
import React from "react";
import {rfidAction} from "./RfidActions";



const ButtonCellRenderer = (props: any) => {
  const handleButtonClick = async () => {
    const { id } = props.data;
    console.log({id})
    await rfidAction({ id });
  };

  return <button onClick={handleButtonClick}
                 className="rounded-md border border-gray-300 bg-primary-600  py-1 px-2 text-sm font-medium shadow-sm text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
  >Disassociate Asset</button>;
};


type CellRenderer = (params: { value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; data: RFIDAssetsRow; }) => JSX.Element;

const createCellRenderer = (permission: boolean, linkFunc: (params: { data: RFIDAssetsRow }) => string): CellRenderer => {
  return (params) => (
      <>
        {permission ? (
            <Link to={linkFunc(params)}>
              <Badge>{params.value}</Badge>
            </Link>
        ) : (
            <Badge>{params.value}</Badge>
        )}
      </>
  );
};

const generateColDefs = (): ColDef<RFIDAssetsRow>[] => [
  {
    headerName: 'UID',
    valueGetter: p => p.data?.uid,
    cellRenderer: createCellRenderer(
        true,
        (p) => `/rfid-assets/${p.data.id}/edit`
    ),
  },
  {
    headerName: 'Type',
    valueGetter: p => p.data?.is_qr,
    valueFormatter: (p) => {
      return p.data?.is_active ? "QR" : "RFID";
    },
  },
  {
    headerName: "Is Active?",
    valueGetter: (p) => p.data?.is_active,
    valueFormatter: (p) => {
      return p.data?.is_active ? "Yes" : "No";
    },
  },
  {
    headerName: "Actions",
    cellRenderer: ButtonCellRenderer, // Use the button cell renderer
  }
];

export const rfidColDefs: ColDef<RFIDAssetsRow>[] = generateColDefs();