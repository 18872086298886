import { gql } from "@apollo/client";

export const TOP_TEN_CLERKS = gql`
  query top_ten_clerks($where: reports_top_ten_clerk_bool_exp) {
    reports_top_ten_clerk(where: $where, order_by: { total_net_sales: desc }) {
      username
      vendor_id
      total_net_sales
      total_tips
    }
  }
`;

export const GET_USER_IDS = gql`
  query getUserIds($usernames: [String!]) {
    users(where: { username: { _in: $usernames } }) {
      id
      username
    }
  }
`;
