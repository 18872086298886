import toast from "react-hot-toast";
import { CREATE_ORGANIZATION } from "./mutations";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Input } from "../../components/inputs/Input";
import { Form, ActionFunctionArgs, redirect } from "react-router-dom";
import { FormLayout } from "../../layout/FormLayout";
import { organizationStore } from "../../store/organization";
import { userStore } from "../../store/user";
import { GET_ORGANIZATION_LABELS } from "./queries";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getUserStore } = userStore;

export const organizationCreateAction = async ({
  request,
}: ActionFunctionArgs) => {
  const user = getUserStore().user;
  const fetchOrganizations = getState().fetchOrganizations;
  const body = await request.formData();
  const inputData = Object.fromEntries(body);

  try {
    await client.mutate({
      mutation: CREATE_ORGANIZATION,
      variables: { 
        input: {
          ...inputData,
          last_updated_by: user?.id
        }
      },
    });
    toast.success("Organization created");
    await fetchOrganizations();
    return redirect("/organizations/list");
  } catch (error) {
    console.log("Failed to create organization" + error);
    toast.error("Failed to create organization" + error);
    return null;
  }
};

export const OrganizationsCreate = () => {
  return (
    <Form method="post" action="/organizations/create">
      <FormLayout>
        <div className="col-span-6">
          <Input 
              label="Name" 
              type="text" 
              name="name" 
              required 
          />
        </div>
        <div className="col-span-6 grid grid-cols-6 gap-5 bg-gray-100 p-2 rounded">
          <label className="col-span-6 block text-sm font-medium text-gray-700">
            Invoicing Configuration
          </label>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Logo URL" 
                name="logo_url" 
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Background URL" 
                name="background_url"
                type="text" 
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Name" 
                name="business_name"
                type="text"
             />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Address 1" 
                name="business_address_1"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Address 2" 
                name="business_address_2"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business City" 
                name="business_city"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business State" 
                name="business_state"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Zipcode" 
                name="business_zipcode"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Website" 
                name="business_website"
                type="text"
            />
          </div>
          <div className="col-span-6 sm:col-span-3">
            <Input 
                label="Business Phone Number" 
                name="business_phone_number"
                type="text"
            />
          </div>
            </div>
      </FormLayout>
    </Form>
  );
};
