import { gql } from "@apollo/client";

export const GET_ORGANIZATIONS = gql`
  query OrganizationsList($where: organizations_bool_exp!) {
    organizations(order_by: { name: asc }, where: $where) {
      name
      created_at
      updated_at
      events(order_by: { name: asc }) {
        id
        name
      }
      id
      user {
        username
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query OrganizationById($id: Int!) {
    organizations_by_pk(id: $id) {
      name
      id
      logo_url
      background_url
      business_name
      business_address_1
      business_address_2
      business_city
      business_state
      business_zipcode
      business_website
      business_phone_number
      background_url
      logo_url
      events {
        id
        name
        created_at
        updated_at
      }
      users {
        id
        username
        created_at
        updated_at
        role {
          name
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_LABELS = gql`
  query OrganizationById($id: Int!) {
    organizations_by_pk(id: $id) {
      id
      background_url
      business_address_1
      business_address_2
      business_city
      business_name
      business_phone_number
      business_state
      business_website
      business_zipcode
    }
  }
`;