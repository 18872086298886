import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { UPDATE_ATTENDEE_RFID} from "../mutations";
import toast from "react-hot-toast";
const client = createApolloClient();

export const rfidAction = async ({ id }: { id: number }) => {
    try {
        await client.mutate({
            mutation: UPDATE_ATTENDEE_RFID,
            variables: {
                id: id,
                attendee_id: null,
            },
        });
        toast.success("Cashless Asset updated successfully");
        window.location.reload();
    } catch (error){
        toast.error(id+ "Error updating Cashless Asset" + error);
    }
};