import { useRef } from "react";
import { useDatePickerState } from "@react-stately/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { FieldButton } from "./Button";
import { Calendar } from "./Calendar";
import { DateField } from "./DateField";
import { Popover } from "./Popover";
import {
  CalendarIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";

export function DatePicker(props) {
  let state = useDatePickerState(props);
  let ref = useRef(null);
  let {
    groupProps,
    labelProps,
    fieldProps,
    buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(props, state, ref);

  return (
    <div className="relative inline-flex flex-col justify-between text-left w-full h-full">
      <label {...labelProps} htmlFor={props.name} className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      <div {...groupProps} ref={ref} className="flex group">
        <div className="bg-white border border-gray-300 group-hover:border-gray-400 transition-colors rounded-l-md pr-10 group-focus-within:border-primary-600 group-focus-within:group-hover:border-primary-600 pl-1 py-2 relative flex items-center w-full text-sm">
          <DateField {...fieldProps} />
          {state.validationState === "invalid" && (
            <ExclamationCircleIcon className="w-6 h-6 text-red-500 absolute right-1" />
          )}
        </div>
        <FieldButton {...buttonProps} isPressed={state.isOpen}>
          <CalendarIcon className="w-5 h-5 text-gray-700 group-focus-within:text-primary-600" />
        </FieldButton>
      </div>
      {state.isOpen && (
        <Popover
          {...dialogProps}
          isOpen={state.isOpen}
          onClose={() => state.setOpen(false)}
        >
          <Calendar {...calendarProps} />
        </Popover>
      )}
      <input type="hidden" {...props} {...fieldProps} />
    </div>
  );
}
