import { gql } from "@apollo/client";

export const UpdatePaymentProcessorByPk = gql`
  mutation UpdatePaymentProcessor(
    $id: Int!
    $set: payment_processor_config_set_input
  ) {
    update_payment_processor_config_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export const CreatePaymentProcessor = gql`
  mutation CreatePaymentProcessor(
    $input: payment_processor_config_insert_input!
  ) {
    insert_payment_processor_config_one(object: $input) {
      id
    }
  }
`;

export const DeletePaymentProcessor = gql`
  mutation delete_payment_processor_config($id: Int!) {
    delete_payment_processor_config_by_pk(id: $id) {
      id
    }
  }
`;
