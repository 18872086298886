interface InputProps {
  label: string;
  error?: string;
  type?: string;
  name: string;
  placeholder?: string;
  defaultValue?: string | number;
}

export default function PercentageInput({
  label,
  error,
  name,
  type = "text",
  placeholder,
  defaultValue,
  ...rest
}: InputProps) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        {/* Input with percentage mask */}
        <input
          type="number"
          name={name}
          id={name}
          className="block w-full rounded-md border-red-300 pr-12 focus:border-input-500 focus:ring-input-500 sm:text-sm"
          placeholder="0"
          defaultValue={defaultValue}
          pattern="[1-9]*"
          max={100}
          min={0}
          step={0.01}
          aria-describedby="percentage"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span className="text-gray-500 sm:text-sm" id="percentage">
            %
          </span>
        </div>
      </div>
    </div>
  );
}
