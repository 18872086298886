import { Outlet, useLocation, useNavigate, } from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { editRfidAction, editRFIDLoader, RFIDAssetEdit } from "./RfidAssetEdit";
import { RFIDAssetList, rfidsListLoader } from "./RfidAssetList";
import { RFID } from "../../types/rfid";

const RFIDIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/rfids") {
    navigate(`/rfid-assets/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export const rfidsRouter = {
  path: "/rfid-assets",
  id: "rfid-assets",
  element: <RFIDIndex />,
  handle: {
    crumb: () => <Breadcrumb name="RFID" href="/rfid-assets/list" />,
  },
  children: [
    {
      path: "list",
      id: "rfid-assets-list",
      element: <RFIDAssetList />,
      loader: rfidsListLoader,
    },
    {
      path: ":id/edit",
      id: "rfid-assets-edit",
      element: <RFIDAssetEdit />,
      loader: editRFIDLoader,
      action: editRfidAction,
      handle: {
        crumb: (data: { rfid: RFID }) => (
          <Breadcrumb
            name={`Edit ${data.rfid.uid}`}
            href={`/rfid-assets/${data.rfid.id}/edit`}
          />
        ),
      },
    },
  ],
};
