import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query locations($where: locations_bool_exp) {
    locations(where: $where, order_by: { name: asc }) {
      id
      event {
        id
        name
      }
      vendor_id
      is_active
      name
      vendor {
        id
        name
      }
      location_type
      zone
      created_at
      updated_at
      dynamic_descriptor
      digital_surcharge_percentage
      payment_processor_config {
        id
        payment_processor
        __typename
      }
      location_menus {
        id
        menu_id
        location_id
        menu {
          name
          id
        }
        __typename
      }
      user {
        username
      }
      __typename
    }
  }
`;

export const GET_LOCATIONS_REPORT_FILTER = gql`
  query locations($locationWhere: locations_bool_exp) {
    locations(where: $locationWhere, order_by: { name: asc }) {
      id
      event {
        id
        name
      }
      vendor_id
      is_active
      name
      vendor {
        id
        name
      }
      location_type
      zone
      created_at
      updated_at
      dynamic_descriptor
      digital_surcharge_percentage
      payment_processor_config {
        id
        payment_processor
        __typename
      }
      location_menus {
        id
        menu_id
        location_id
        menu {
          name
          id
        }
        __typename
      }
      user {
        username
      }
      __typename
    }
  }
`;
export const GET_LOCATION = gql`
  query location($id: Int!) {
    locations_by_pk(id: $id) {
      id
      event_id
      vendor_id
      is_active
      name
      zone
      digital_surcharge_percentage
      payment_processor_id
      dynamic_descriptor
      redeemable_tokens
      location_type
      payment_processor_config {
        payment_processor
        __typename
      }
      event {
        name
      }
      vendor {
        name
      }
      location_menus {
        id
        menu_id
        location_id
        __typename
      }
      __typename
    }
  }
`;

export const GET_LOCATION_TYPE = gql`
  query location_type($where: dashboard_reference_bool_exp!) {
    dashboard_reference(order_by: { reference_label: asc }, where: $where) {
      reference_value
      reference_label
    }
  }
`;