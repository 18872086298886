import { ReactNode, useState } from "react";
import { classNames } from "../utils";

interface TabsProps {
  tabs: {
    name: string;
    id: string;
  }[];
  defaultTab?: string;
  children: ({ selectedTab }: { selectedTab: any }) => ReactNode;
}

export default function Tabs({ tabs, defaultTab, children }: TabsProps) {
  const [selectedTab, setSelectedTab] = useState<string>(
    defaultTab ?? tabs[0].id
  );
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
          defaultValue={tabs.find((tab) => tab.id === selectedTab)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <p
                key={tab.name}
                onClick={() => setSelectedTab(tab.id)}
                className={classNames(
                  tab.id === selectedTab
                    ? "border-primary-500 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap mb-2 py-2 px-1 border-b-2 font-medium text-sm cursor-pointer"
                )}
                aria-current={tab.id === selectedTab ? "page" : undefined}
              >
                {tab.name}
              </p>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {children({
          selectedTab,
        })}
      </div>
    </div>
  );
}
