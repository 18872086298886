import { ColumnDef } from "@tanstack/react-table";

import { Link, useParams } from "react-router-dom";
import Table from "../../components/Table";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Attendee, CardOnFile } from "../../types/attendee";
import { Order } from "../../types/order";
import { RFID } from "../../types/rfid";
import { usdFormat } from "../../utils";
import { calculateDiscountAmount } from "../orders/util";
import {
  GET_ATTENDEE_DETAIL,
  GET_ATTENDEE_CARD,
  GET_ATTENDEE_ORDERS,
} from "./queries";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../components/Loader";
import { RFID_LIST_QUERY } from "../rfid_assets/queries";
import { Badge } from "../../components/Badge";

const client = createApolloClient();

// export const attendeeShowLoader = async ({ params }: LoaderFunctionArgs) => {
//   const { id } = params;
//   const { data } = await client.query({
//     query: GET_ATTENDEE,
//     variables: {
//       id,
//     },
//   });
//   return data.attendees_by_pk;
// };

export const AttendeeShow = () => {
  const { id } = useParams();

  const { data, isLoading: isLoadingDetails } = useQuery<{
    details: Attendee;
  }>(
    ["attendee_details", id],

    async () => {
      const { data } = await client.query({
        query: GET_ATTENDEE_DETAIL,
        variables: {
          id,
        },
      });
      return { details: data.attendees_by_pk };
    }
  );
  const { data: rfidData, isLoading: isLoadingRfid } = useQuery<{
    rfid_assets: RFID[];
  }>(["rfid_details", id], async () => {
    const { data } = await client.query({
      query: RFID_LIST_QUERY,
      variables: {
        where: {
          attendee_id: {
            _eq: id,
          },
        },
      },
    });
    return data;
  });

  const { data: cardData, isLoading: isLoadingCard } = useQuery<{
    card_on_files: CardOnFile[];
  }>(
    ["card_details", id],

    async () => {
      const { data } = await client.query({
        query: GET_ATTENDEE_CARD,
        variables: {
          where: {
            attendee_id: {
              _eq: id,
            },
          },
        },
      });
      return data;
    }
  );
  const { data: ordersData, isLoading: isLoadingOrders } = useQuery<{
    orders: Order[];
  }>(
    ["orders_details", id],

    async () => {
      const { data } = await client.query({
        query: GET_ATTENDEE_ORDERS,
        variables: {
          where: {
            attendee_id: {
              _eq: id,
            },
          },
        },
      });
      return data;
    }
  );

  return (
    <>
      {isLoadingDetails ? (
        <Loader />
      ) : (
        <>
          <div className="overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                {data?.details?.first_name} {data?.details?.last_name}
              </h3>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data?.details?.phone_number}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data?.details?.email}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Is Suspended
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data?.details?.is_suspended ? "Yes" : "No"}
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Note</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {data?.details?.note}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </>
      )}
      <>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Cashless Assets
            </h3>
          </div>
          {isLoadingRfid ? (
            <Loader />
          ) : (
            <RFIDAssetsTable data={rfidData?.rfid_assets || []} />
          )}
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Cards On File
            </h3>
          </div>
          {isLoadingCard ? (
            <Loader />
          ) : (
            <CardsOnFileTable data={cardData?.card_on_files || []} />
          )}
        </div>
        <div className="overflow-hidden bg-white shadow sm:rounded-lg mt-5">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Orders
            </h3>
          </div>
          {isLoadingOrders ? (
            <Loader />
          ) : (
            <OrdersTable data={ordersData?.orders || []} />
          )}
        </div>
      </>
    </>
  );
};

const rfidAssetsColumns: ColumnDef<RFID>[] = [
  {
    accessorKey: "uid",
    header: "UID",
  },
  {
    accessorKey: "is_qr",
    header: "Type",
    cell: (info) => (info.getValue() ? "QR" : "RFID"),
  },
  {
    accessorKey: "is_active",
    header: "Is Active",
    cell: (info) => (info.getValue() ? "Yes" : "No"),
  },
];

const RFIDAssetsTable = ({ data }: { data: RFID[] }) => {
  return <Table columns={rfidAssetsColumns} data={data} />;
};

const cardsOnFileColumns: ColumnDef<CardOnFile>[] = [
  {
    accessorKey: "last_four_digits",
    header: "Last Four Digits",
    cell: (info) => `**** **** **** ${info.getValue()}`,
  },
  {
    accessorKey: "is_active",
    header: "Is Active",
    cell: (info) => (info.getValue() ? "Yes" : "No"),
  },
];

const CardsOnFileTable = ({ data }: { data: CardOnFile[] }) => {
  return <Table columns={cardsOnFileColumns} data={data} />;
};

const ordersColumns: ColumnDef<Order>[] = [
  {
    accessorKey: "reference_id",
    header: "Reference ID",
    cell: (info) => (
      <Link
        to={`/orders/${info.row.original.reference_id}/${info.row.original.id}`}
      >
        <Badge>{info.getValue<string>()}</Badge>
      </Link>
    ),
  },
  {
    accessorKey: "location.name",
    header: "Location",
    cell: (info) => info.row.original.location?.name || "N/A",
  },
  {
    accessorKey: "transaction_at",
    header: "Order Date",
    cell: (info) => new Date(info.getValue<string>()).toLocaleDateString(),
  },
  {
    accessorKey: "subtotal",
    header: "Subtotal",
    cell: (info) => usdFormat.format(info.getValue<number>() / 100),
  },
  {
    accessorKey: "tax",
    header: "Tax",
    cell: (info) => usdFormat.format(info.getValue<number>() / 100),
  },
  {
    accessorKey: "tip",
    header: "Tip",
    cell: (info) => usdFormat.format(info.getValue<number>() / 100),
  },
  {
    accessorKey: "digital_surcharge",
    header: "Service Fee",
    cell: (info) => usdFormat.format(info.getValue<number>() / 100),
  },
  {
    accessorKey: "total",
    header: "Total",
    cell: (info) => {
      const allValue = info.row.original;
      const totalDiscount = allValue.discount
        ? calculateDiscountAmount(allValue.discount, allValue.subtotal)
        : 0;
      const refunded = allValue.transactions?.reduce((acc, transaction) => {
        const amountRefund =
          transaction?.payment?.status === "refund"
            ? transaction.payment.amount
            : 0;
        return acc + amountRefund;
      }, 0);
      const total =
        (allValue?.subtotal +
          allValue?.digital_surcharge +
          allValue?.tip +
          allValue?.tax) /
          100 -
        totalDiscount -
        refunded / 100;
      return usdFormat.format(total);
    },
  },

  {
    accessorKey: "status",
    header: "Status",
  },
];

const OrdersTable = ({ data }: { data: Order[] }) => {
  return <Table columns={ordersColumns} data={data} />;
};
