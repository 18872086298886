import { gql } from "@apollo/client";

export const LOCATIONS_BY_ORGANIZATION = gql`
  query LocationsByOrganizer($where: locations_bool_exp!) {
    locations(order_by: { name: asc }, where: $where) {
      id
      name
      vendor_id
      event_id
    }
  }
`;

export const LOCATIONS_BY_ORGANIZATION_VENDOR = gql`
  query LocationsByOrganizer($organizationId: Int, $vendorId: Int) {
    locations(
      where: {
        _and: [
          { event: { organization: { id: { _eq: $organizationId } } } }
          { vendor_id: { _eq: $vendorId } }
        ]
      }
    ) {
      id
      name
      vendor_id
    }
  }
`;

export const GET_LIST_LOCATIONS = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }
  }
`;

export const CREATE_LOCATION = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }
  }
`;

export const UPDATE_LOCATION = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    location_menus {
      id
      menu_id
      location_id
    }
  }
`;

export const GET_ONE_LOCATION = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    location_menus {
      id
      menu_id
      location_id
    }
  }
`;

export const GET_MANY_LOCATION = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    location_menus {
      id
      menu_id
      location_id
    }
  }
`;

export const DELETE_LOCATION = gql`
  {
    id
    event_id
    vendor_id
    is_active
    name
    digital_surcharge_percentage
    payment_processor_id

    payment_processor_config {
      payment_processor
    }

    location_menus {
      id
      menu_id
      location_id
    }
  }
`;
