import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import toast from "react-hot-toast";
import SectionHeading from "../../components/SectionHeading";
import { Breadcrumb } from "../../components/Breadcrumb";
import {
  TransactionEdit,
  transactionEditAction,
  transactionEditLoader,
} from "./TransactionsEdit";
import { TransactionList, transactionsListLoader } from "./TransactionsList";
import { requireAuth } from "../../modules/auth/RequireAuth";
import { Transaction } from "../../types/transaction";
import { TransactionShow, transactionShowLoader } from "./TransactionsShow";
import {
  TransactionCreate,
  transactionCreateAction,
} from "./TransactionsCreate";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { DELETE_TRANSACTION } from "./mutations";

const client = createApolloClient();

const TransactionIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/transactions") {
    navigate(`/transactions/list`);
  }

  return (
    <>
      <SectionHeading resource="transactions" />
      <Outlet />
    </>
  );
};

const transactionDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_TRANSACTION,
      variables: {
        id,
      },
    });
    toast.success("Transaction deleted successfully");
    return redirect(`/transactions/list`);
  } catch {
    toast.error("Error deleting transaction");
  }
};

export const transactionsRouter = {
  path: "/transactions",
  id: "transactions",
  element: <TransactionIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Transactions" href="/transactions/list" />,
  },
  loader: requireAuth(),
  children: [
    {
      path: "list",
      id: "transactions-list",
      element: <TransactionList />,
      loader: transactionsListLoader,
    },
    {
      path: ":id",
      id: "transactions-show",
      element: <TransactionShow />,
      loader: transactionShowLoader,
      handle: {
        crumb: (data: Transaction) => (
          <Breadcrumb
            name={`Ref #${data.payment.reference_id}`}
            href={`/transactions/${data.id}`}
          />
        ),
      },
    },
    {
      path: "create",
      id: "transactions-create",
      element: <TransactionCreate />,
      action: transactionCreateAction,
      handle: {
        crumb: () => (
          <Breadcrumb
            name={`Create Transaction`}
            href={`/transactions/create`}
          />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "transactions-edit",
      element: <TransactionEdit />,
      loader: transactionEditLoader,
      action: transactionEditAction,
      handle: {
        crumb: (data: Transaction) => (
          <Breadcrumb
            name={`Edit Ref #${data.payment.reference_id}`}
            href={`/transactions/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "transactions-delete",
      action: transactionDeleteAction,
    },
  ],
};
