import { gql } from "@apollo/client";

export const QUERY_LIST_ITEMS = gql`
  query ListItems($where: items_bool_exp, $order_by: [items_order_by!]) {
    items(where: $where, order_by: $order_by) {
      created_at
      description
      id
      is_active
      name
      tags
      price
      short_name
      unique_id
      updated_at
      event_id
      tax
      tax_percentage
      menu_items {
        menu {
          tax_type
        }
      }
      event {
        id
        name
      }
    }
  }
`;

export const GET_LIST_ITEMS = gql`
  {
    created_at
    description
    id
    is_active
    name
    tags
    price
    short_name
    unique_id
    updated_at
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;

export const CREATE_ITEM = gql`
  {
    id
    price
    tags
    name
    short_name
    description
    unique_id
    is_active
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;

export const UPDATE_ITEM = gql`
  {
    id
    price
    tags
    name
    short_name
    description
    unique_id
    is_active
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;

export const GET_ONE_ITEM = gql`
  {
    id
    price
    tags
    name
    short_name
    description
    unique_id
    is_active
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;

export const DELETE_ITEM = gql`
  {
    id
    price
    tags
    name
    short_name
    description
    unique_id
    is_active
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;

export const GET_MANY_ITEM = gql`
  {
    id
    price
    tags
    name
    short_name
    description
    unique_id
    is_active
    event_id
    tax
    tax_percentage
    menu_items {
      menu {
        tax_type
      }
    }
    event {
      id
      name
    }
  }
`;
