import { persist } from "zustand/middleware";
import create from "zustand";
import createVanilla from "zustand/vanilla";
import { createApolloClient } from "../providers/ApolloClientFactory";
import { GET_LIST_EVENTS } from "../queries";
import { organizationStore } from "./organization";
import { userStore } from "./user";

interface EventState {
  eventId: number | null;
  // events: any[];

  allEvents: any[];
  setEventId: (eventId: any) => void;
  // initEvent: () => Promise<void>;
  // fetchEvents: () => Promise<void>;
  fetchAllEvents: () => Promise<void>;
}

const client = createApolloClient();
const { getState: getUserState } = userStore;

export const eventStore = createVanilla<EventState>()(
  persist(
    (set, get) => ({
      eventId: null,

      allEvents: [],

      setEventId: (eventId: number | null) => {
        set({ eventId });
      },

      fetchAllEvents: async () => {
        const users = getUserState().user;
        const { getState } = organizationStore;
        const { organizationId } = getState();
        let where: any = {};
        if ((users?.events?.length || 0) > 0) {
          where = {
            ...where,
            id: {
              _in: users?.events,
            },
          };
        }
        if (organizationId) {
          where = {
            ...where,

            organization_id: {
              _eq: organizationId,
            },
          };
        }
        const { data } = await client.query({
          query: GET_LIST_EVENTS,
          variables: {
            where,
          },
        });
        set({
          allEvents: data?.events,
        });
      },
    }),
    {
      name: "event",
    }
  )
);

export const useEventStore = create(eventStore);
