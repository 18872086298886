import { gql } from "@apollo/client";

export const ALL_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations(order_by: { name: asc }) {
      id
      name
      events {
        id
        name
        created_at
        locations {
          id
          name
        }
      }
      vendors(order_by: { name: asc }) {
        id
        name
      }
    }
  }
`;
