import { usdFormat } from "../../../utils";
import { CashBalanceReportRow } from "./type";
import {ColDef} from "ag-grid-community";

let totalRow: CashBalanceReportRow  = {
  id: "total-row",
  username: "Total",
  gross_cash_loaded: 0,
  gross_cash_refunded: 0,
  net_cash_loaded: 0,
  event_id: 0,
  event_name: "",
  created_at_hour: "",
  location_id: 0,
  location_name: "",
  organization_id: 0,
  user_id: 0,
  total: 0
};

const generateColDefs = (): ColDef<CashBalanceReportRow>[] => [
  {
    headerName: 'Username',
    valueGetter: p => p.data?.username,
    pinned: 'left',
    cellStyle: params => {
      if (params.value === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Event Name',
    valueGetter: p => p.data?.event_name,
  },
  {
    headerName: 'Location Name',
    valueGetter: p => p.data?.location_name,
  },
  {
    headerName: 'Gross Cash Balance Loaded',
    field: 'gross_cash_loaded',
    valueGetter: params => {
      return params.data?.gross_cash_loaded;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Gross Cash Balance Refunded',
    field: 'gross_cash_refunded',
    valueGetter: params => {
      return params.data?.gross_cash_refunded;
    },
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  },
  {
    headerName: 'Net Cash Balance Loaded',
    field: 'net_cash_loaded',
    valueGetter: params => params.data?.net_cash_loaded,
    valueFormatter: params => usdFormat.format(params.value ?? 0),
    cellStyle: params => {
      if (params.data?.username === 'Total') {
        return { fontWeight: 'bold' };
      }
      return null;
    }
  }
];

export const colDefs: ColDef<CashBalanceReportRow>[] = generateColDefs();

export const formatData = (data: CashBalanceReportRow[]) => {
  if (!data) return [];
  totalRow = {
    id: "total-row",
    username: "Total",
    gross_cash_loaded: 0,
    gross_cash_refunded: 0,
    net_cash_loaded: 0,
    event_id: 0,
    event_name: "",
    created_at_hour: "",
    location_id: 0,
    location_name: "",
    organization_id: 0,
    user_id: 0,
    total: 0
  };

  const groupedRows: Record<string, CashBalanceReportRow> = {};

  data.forEach((curr) => {
    const username = curr.username;
    const locationName = curr.location_name;
    const gross_cash_loaded = curr.gross_cash_loaded / 100;
    const gross_cash_refunded = curr.gross_cash_refunded / 100;
    const net_cash_loaded = curr.net_cash_loaded / 100;

    const rowIdentifier = `${username}-${locationName}`;

    if (groupedRows[rowIdentifier]) {
      const row = groupedRows[rowIdentifier];
      row.gross_cash_loaded += gross_cash_loaded;
      row.gross_cash_refunded += gross_cash_refunded;
      row.net_cash_loaded += net_cash_loaded;
    } else {
      groupedRows[rowIdentifier] = {
        ...curr,
        id: rowIdentifier,
        gross_cash_loaded,
        gross_cash_refunded,
        net_cash_loaded,
      };
    }

    // Update the totals
    totalRow.gross_cash_loaded += gross_cash_loaded;
    totalRow.gross_cash_refunded += gross_cash_refunded;
    totalRow.net_cash_loaded += net_cash_loaded;
  });
  groupedRows[totalRow.id] = totalRow;
  return Object.values(groupedRows);
};