import { Link } from "react-router-dom";
import { requireAuth } from "../../modules/auth/RequireAuth";

const NotFound = () => {
  return (
    <>
      <div className="h-[calc(100vh_-_64px)] w-full flex justify-center items-center flex-col">
        <h1 className="text-2xl">Oops! That URL does not exist.</h1>
        <Link className="text-blue-500 underline" to="/">
          Return Home
        </Link>
      </div>
    </>
  );
};

export const notFoundRouter = {
  path: "*",
  id: "not-found",
  element: <NotFound />,
  loader: requireAuth(),
};
