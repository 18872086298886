import { Discount } from "../../types/discount";

export const calculateDiscountAmount = (discounts: Discount[], subtotal: number) =>
    discounts.reduce((acc, item) => {
      if (item.amount) {
        acc += item.amount / 100;
      } else if (item.percentage) {
        acc += ((item.percentage / 100) * subtotal) / 100;
      }
      return acc;
    }, 0);