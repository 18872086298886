import { gql } from '@apollo/client';

export type RefundInput = {
  amountRefund: number;
  notes: string;
  order_id: number;
  totals: number;
  paymentType: string;
  fullRefund: boolean;
}

export const REFUND_ORDER = gql`
  mutation RefundItem($object: RefundsInsertInput) {
    insert_refunds_one(
      object: $object
    ) {
      statusMessage
    }
  }
`