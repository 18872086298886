import gql from "graphql-tag";

export const GET_LIST_VENDORS = gql`
  {
    id
    name
    organization_id
    created_at
    updated_at
    type
    other_type
  }
`;

export const INSERT_VENDOR = gql`
  {
    id
    name
    organization_id
    created_at
    updated_at
    type
    other_type
  }
`;

export const UPDATE_VENDOR = gql`
  {
    id
    name
    organization_id
    created_at
    updated_at
    type
    other_type
  }
`;

export const GET_ONE_VENDOR = gql`
  {
    id
    name
    organization_id
    created_at
    updated_at
    type
    other_type
  }
`;

export const GET_MANY_VENDOR = gql`
  {
    id
    name
    organization_id
    created_at
    updated_at
    type
    other_type
  }
`;

export const GET_LIST_VENDORS_BY_ORGANIZATION = gql`
  query VendorsByOrganization($where: vendors_bool_exp) {
    vendors(where: $where, order_by: { name: asc }) {
      id
      name
      organization_id
      created_at
      updated_at
      type
      other_type
    }
  }
`;
