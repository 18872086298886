import { gql } from '@apollo/client';

export const GET_TRANSACTIONS = gql`
  query GetTransactions {
    transactions {
      id
      order_id
      payment_id
      amount
      created_at
      note
      status
      updated_at
      order {
        id
        reference_id
      }
      payment {
        id 
        amount
        payment_data
        payment_type
        reference_id
        status
      }
    }
  }
`;

export const GET_TRANSACTION = gql`
  query GetTransaction($id: Int!) {
    transactions_by_pk(id: $id) {
      id
      order_id
      payment_id
      amount
      created_at
      note
      status
      updated_at
      order {
        id
        reference_id
      }
      payment {
        id 
        amount
        payment_data
        payment_type
        reference_id
        status
      }
    }
  }
`;