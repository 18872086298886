import { useState } from "react";

interface InputProps {
  label: string;
  subText?: string;
  name?: string;
  defaultChecked?: string;
  onChange?: (val: string) => void;
  choices: { id: string; title: string }[];
}

export default function RadioGroup({
  label,
  subText,
  name,
  onChange,
  defaultChecked,
  choices,
}: InputProps) {
  const [checked, setChecked] = useState(defaultChecked || choices[0].id);

  const handleChange = (val: string) => {
    setChecked(val);
    onChange?.(val);
  };

  return (
    <div>
      <label className="text-base font-medium text-gray-900">{label}</label>
      {subText && <p className="text-sm leading-5 text-gray-500">{subText}</p>}
      <fieldset className="mt-4">
        <legend className="sr-only">choices</legend>
        <div className="space-y-4">
          {choices.map((choice) => (
            <div key={choice.id} className="flex items-center">
              <input
                onChange={() => handleChange(choice.id)}
                id={choice.id}
                type="radio"
                checked={checked === choice.id}
                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500"
              />
              <label
                htmlFor={choice.id}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {choice.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      <input type="hidden" name={name} value={checked} />
    </div>
  );
}
