import { gql } from "@apollo/client";

export const CREATE_DISCOUNT = gql`
  mutation CreateDiscount($data: discount_insert_input!) {
    insert_discount_one(object: $data) {
      id
    }
  }
`;
export const UPDATE_DISCOUNT = gql`
  mutation UpdateDiscount($id: Int!, $data: discount_set_input) {
    update_discount_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const DELETE_DISCOUNT = gql`
  mutation DeleteDiscount($id: Int!) {
    delete_discount_by_pk(id: $id) {
      id
    }
  }
`;
