import { gql } from '@apollo/client';

export const CREATE_VENDOR = gql`
  mutation createVendor($input: vendors_insert_input!) {
    insert_vendors_one(object: $input) {
      id
    }
  }
`;

export const UPDATE_VENDOR = gql`
  mutation updateVendor($id: Int!, $data: vendors_set_input) {
    update_vendors_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const DELETE_VENDOR = gql`
  mutation DeleteVendor($id: Int!) {
    delete_vendors_by_pk(id: $id) {
      id
    }
  }
`;
