import { ColumnDef } from "@tanstack/react-table";
import Table from "../../components/Table";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { GET_DEVICES } from "./queries";
import { Device } from "../../types/device";
import {Link, LoaderFunctionArgs, useLoaderData, useNavigate, useRevalidator} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import moment from "moment/moment";
import {organizationStore} from "../../store/organization";
import {eventStore} from "../../store/event";
import {Event} from "../../types/event";
import {Badge} from "../../components/Badge";
import {GET_LIST_EVENTS} from "../events/queries";
import {usePermissions} from "../auth/api";
import {ColDef, GridApi, SizeColumnsToContentStrategy} from "ag-grid-community";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {Input} from "../../components/inputs/Input";
import {PrimaryButton, SecondaryButton} from "../../components/Button";
import {AgGridReact} from "ag-grid-react";

const client = createApolloClient();
const { getState } = organizationStore;
const { getState: getEventStore } = eventStore;

const buildSearch = (search: string) => [
  {
    mac_address: {
      _ilike: `%${search}%`,
    },
  },
];

type CellRenderer = (params: {
  value: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined;
  data: Device;
}) => JSX.Element | null;
const createCellRenderer = (permission: boolean, linkFunc: (params: { data: Device }) => string): CellRenderer => {
  return (params) => {
    if (params.value === undefined) {
      return null;
    }
    return (
        <>
          {permission ? (
              <Link to={linkFunc(params)}>
                <Badge>{params.value}</Badge>
              </Link>
          ) : (
              <Badge>{params.value}</Badge>
          )}
        </>
    );
  };
};


export const devicesListLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const search = url.searchParams.get("search");
  const organizationId = getState().organizationId;
  const eventId = getEventStore().eventId;

  let where: any = {_or: search ? buildSearch(search) : undefined };
  // if (organizationId) {
  //   where = {
  //     ...where,
  //     organization_id: {
  //       _eq: organizationId,
  //     },
  //   };
  // }
  const { data } = await client.query({
    query: GET_DEVICES,
    variables: {
      where,
    },
  });
  return data.devices;
};

export const DeviceList = () => {
  const { editCreatePermission } =
      usePermissions("devices");
  const navigate = useNavigate();
  const revalidator = useRevalidator();
  const gridRef = useRef<GridApi>();
  const devices = useLoaderData() as Device[];
  const [rowData, setRowData] = useState<Device[]>([]);
  useEffect(() => {
    setRowData(devices);
  }, [devices]);
  const generateColDefs = (): ColDef<Device>[] => [
    {
      headerName: 'Mac Address',
      valueGetter: p => p.data?.mac_address,
      cellRenderer: createCellRenderer(editCreatePermission, (p) => `/devices/${p.data.id}/edit`),
      filter: 'agTextColumnFilter',
      pinned: 'left'
    },
    {
      headerName: 'Device Type',
      valueGetter: p => p.data?.item_type,
    },
    {
      headerName: 'Created Date',
      valueGetter: p => p.data?.created_at,
      valueFormatter: p => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format('MM/DD/YYYY h:mm:ss a') : '';
      }
    },
    {
      headerName: 'Updated Date',
      valueGetter: p => p.data?.updated_at,
      valueFormatter: p => {
        const date = p.value ? moment(p.value) : null;
        return date ? date.format('MM/DD/YYYY h:mm:ss a') : '';
      }
    }
  ];
  const colDefs: ColDef<Device>[] = generateColDefs();
  const rowHeight = 50;
  const autoSizeStrategy: SizeColumnsToContentStrategy = { type: 'fitCellContents' };
  const handleExportCSV = () => {
    if (gridRef.current) {
      const timestamp = new Date().toISOString().replace(/[-:]/g, '');
      const params = {
        fileName: `Devices_${timestamp}.csv`,
        columnSeparator: ',',
      };
      gridRef.current.exportDataAsCsv(params);
    }
  };
  const gridOptions = {
    enableCellTextSelection:true
  };
  return (
      <div style={{height: '75vh'}}>
        <div className={'ag-theme-quartz'} style={{width: '100%', height: '100%'}}>
          <div className=' grid gap-2 grid-cols-6'>
            <Breadcrumbs />
            <div className='col-span-2 col-start-5 mt-2'>
              <Input
                  name="search"
                  placeholder={`Search Devices`}
                  onChange={(s) => {
                    navigate(
                        s && typeof s === "string" && s.length > 0
                            ? `/devices/list?search=${s}`
                            : `/devices/list`
                    );
                    revalidator.revalidate();
                  }}
              />
            </div>
            <div className='py-2 col-span-2 col-start-7'>
              <SecondaryButton onClick={handleExportCSV}>Export to CSV</SecondaryButton>
              {editCreatePermission && (
                  <PrimaryButton onClick={() => navigate(`/devices/create`)}>
                    Create
                  </PrimaryButton>
              )}
            </div>
          </div>
          <AgGridReact
              rowData={rowData}
              columnDefs={colDefs}
              rowHeight={rowHeight}
              autoSizeStrategy={autoSizeStrategy}
              pagination={true}
              gridOptions={gridOptions}
              paginationPageSize={100}
              onGridReady={(params) => (gridRef.current = params.api)}
          />
        </div>
      </div>
  );
};