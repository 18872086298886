import { gql } from "@apollo/client";

export const CREATE_ROLE = gql`
  mutation CreateRole(
    $name: String!
    $display_name: String!
    $permissions: jsonb!
    $description: String!
    $organization_id: Int!
    $is_active: Boolean
    $last_updated_by: Int
  ) {
    insert_roles(
      objects: {
        name: $name
        display_name: $display_name
        permissions: $permissions
        is_active: $is_active
        description: $description
        organization_id: $organization_id
        last_updated_by: $last_updated_by
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $id: Int!
    $display_name: String!
    $description: String!
    $organization_id: Int!
    $permissions: jsonb!
    $is_active: Boolean
    $last_updated_by: Int
  ) {
    update_roles_by_pk(
      pk_columns: { id: $id }
      _set: {
        display_name: $display_name
        permissions: $permissions
        description: $description
        organization_id: $organization_id
        is_active: $is_active
        last_updated_by: $last_updated_by
      }
    ) {
      id
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation DeleteRole($id: Int!) {
    delete_roles_by_pk(id: $id) {
      id
    }
  }
`;
