import {
  ActionFunctionArgs,
  Outlet,
  redirect,
  useLocation,
  useNavigate,
} from 'react-router-dom';
// import { Breadcrumb } from "../../components/Breadcrumb";
import { createApolloClient } from '../../providers/ApolloClientFactory';
// import { Discount } from "../../types/discount";
// import {
//   promoCodeCreateAction,
//   PromoCodeCreate,
//   promoCodeCreateLoader,
// } from "./PromoCodeCreate";
// import {
//   promoCodeEditAction,
//   promoCodeEditLoader,
//   PromoCodeEdit,
// } from "./PromoCodeEdit";
// import { PromoCodeList, promoCodeListLoader } from "./PromoCodeList";
// import { promoCodeByIdLoader, PromoCodeShow } from "./PromoCodeShow";
// import { DELETE_PROMO_CODE } from "./mutations";
import toast from 'react-hot-toast';
import { QrCodeDisplay, QrCodeDisplayLoader } from './QrCodeDisplay';

const PromocodeIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === '/qr_code') {
    navigate(`/qr_code`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const client = createApolloClient();

// const promocodeDeleteAction = async ({ params }: ActionFunctionArgs) => {
//   const { id } = params;
//   try {
//     await client.mutate({
//       mutation: DELETE_PROMO_CODE,
//       variables: { id },
//     });
//     toast.success("Promocode Deleted");
//     return redirect(`/promocode/list`);
//   } catch {
//     toast.error("Failed to delete Discount");
//   }
// };

export const qrCodeRouter = {
  path: '/qr_code/:uid',
  id: 'qrcode',
  loader: QrCodeDisplayLoader,
  element: <QrCodeDisplay />,
  // handle: {
  //   crumb: () => <Breadcrumb name="Promo Code" href="/promocode/list" />,
  // },
  // children: [
  //   {
  //     path: "list",
  //     id: "promocode-list",
  //     element: <PromoCodeList />,
  //     loader: promoCodeListLoader,
  //   },
  //   {
  //     path: "create",
  //     id: "promocode-create",
  //     loader: promoCodeCreateLoader,
  //     element: <PromoCodeCreate />,
  //     action: promoCodeCreateAction,
  //     handle: {
  //       crumb: () => (
  //         <Breadcrumb name="Create Promocode" href="/promocode/create" />
  //       ),
  //     },
  //   },
  //   {
  //     path: ":id",
  //     id: "promocode-show",
  //     element: <PromoCodeShow />,
  //     loader: promoCodeByIdLoader,
  //     handle: {
  //       crumb: (data: Discount) => (
  //         <Breadcrumb name={data.name} href={`/promocode/${data.id}`} />
  //       ),
  //     },
  //   },
  //   {
  //     path: ":id/edit",
  //     id: "promocode-edit",
  //     element: <PromoCodeEdit />,
  //     loader: promoCodeEditLoader,
  //     action: promoCodeEditAction,
  //     handle: {
  //       crumb: (data: Discount) => (
  //         <Breadcrumb
  //           name={`Edit ${data.name}`}
  //           href={`/promocode/${data.id}/edit`}
  //         />
  //       ),
  //     },
  //   },
  //   {
  //     path: ":id/delete",
  //     id: "promocode-delete",
  //     action: promocodeDeleteAction,
  //   },
  // ],
};
