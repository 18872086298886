import { gql } from "@apollo/client";

export const GET_DEVICES = gql`
  query devices($where: devices_bool_exp) {
    devices(where: $where) {
      id
      mac_address
      item_type
      created_at
      updated_at
    }
  }
`;

export const GET_DEVICE = gql`
  query devicesByPk($id: Int!) {
    devices_by_pk(id: $id) {
      id
      mac_address
      item_type
      created_at
      updated_at
    }
  }
`;
