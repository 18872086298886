import {
  ActionFunctionArgs,
  Outlet, redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Breadcrumb } from "../../components/Breadcrumb";
import { createApolloClient } from "../../providers/ApolloClientFactory";
import { Discount } from "../../types/discount";
import { discountCreateAction, DiscountsCreate } from "./DiscountCreate";
import {
  discountEditAction,
  discountEditLoader,
  DiscountsEdit,
} from "./DiscountEdit";
import { DiscountsList, discountsListLoader } from "./DiscountList";
import { discountByIdLoader, DiscountsShow } from "./DiscountShow";
import { DELETE_DISCOUNT } from "./mutations";
import toast from "react-hot-toast";


const DiscountsIndex = () => {
  const location = useLocation();
  const navigate = useNavigate();

  if (location.pathname === "/discounts") {
    navigate(`/discounts/list`);
  }

  return (
    <>
      <Outlet />
    </>
  );
};

const client = createApolloClient();

const discountDeleteAction = async ({ params }: ActionFunctionArgs) => {
  const { id } = params;
  try {
    await client.mutate({
      mutation: DELETE_DISCOUNT,
      variables: { id },
    });
    toast.success("Discount Deleted");
    return redirect(`/discounts/list`);
  } catch {
    toast.error("Failed to delete Discount");
  }
};


export const discountsRouter = {
  path: "/discounts",
  id: "discounts",
  element: <DiscountsIndex />,
  handle: {
    crumb: () => <Breadcrumb name="Discounts" href="/discounts/list" />,
  },
  children: [
    {
      path: "list",
      id: "discounts-list",
      element: <DiscountsList />,
      loader: discountsListLoader,
    },
    {
      path: "create",
      id: "discounts-create",
      element: <DiscountsCreate />,
      action: discountCreateAction,
      handle: {
        crumb: () => (
          <Breadcrumb name="Create Discount" href="/discounts/create" />
        ),
      },
    },
    {
      path: ":id",
      id: "discounts-show",
      element: <DiscountsShow />,
      loader: discountByIdLoader,
      handle: {
        crumb: (data: Discount) => (
          <Breadcrumb name={data.name} href={`/discounts/${data.id}`} />
        ),
      },
    },
    {
      path: ":id/edit",
      id: "discounts-edit",
      element: <DiscountsEdit />,
      loader: discountEditLoader,
      action: discountEditAction,
      handle: {
        crumb: (data: Discount) => (
          <Breadcrumb
            name={`Edit ${data.name}`}
            href={`/discounts/${data.id}/edit`}
          />
        ),
      },
    },
    {
      path: ":id/delete",
      id: "discounts-delete",
      action: discountDeleteAction,
    },
  ],
};
