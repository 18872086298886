import {
  useFetcher,
  useLocation,
  useNavigate,
  useRevalidator,
} from "react-router-dom";
import { Breadcrumbs } from "./Breadcrumbs";
import { SecondaryButton, PrimaryButton } from "./Button";
import DeleteModal from "./DeleteModal";
import { Input } from "./inputs/Input";
import {
  usePermissions,
} from "../modules/auth/api";
import { userStore } from "../store/user";

interface SectionHeadingProps {
  resource: string;
}
const { getState } = userStore;
const supportedBreadCrumbs = [
  "users",
  "roles",
  "attendees",
  "devices",
  "discounts",
  "locations",
  "organizations",
  "menus",
  "payment_processor_configs",
  "payment-processors",
  "rfid-assets",
  "vendors",
  "items",
  "item-modifiers",
  "events",
];
const supportedSearches = [
  "users",
  "roles",
  "attendees",
  "devices",
  "items",
  "item-modifiers",
  "rfid-assets",
  "menus",
  "locations",
  "vendors",
  "events",
  "payment-processors",
];
const supportedCreates = [
  "users",
  "roles",
  "discounts",
  "locations",
  "organizations",
  "menus",
  "payment_processor_configs",
  "payment-processors",
  "vendors",
  "items",
  "item-modifiers",
  "events",
];
const supportedDeletes = [
  "users",
  "roles",
  "devices",
  "discounts",
  "locations",
  "organizations",
  "menus",
  "payment_processor_configs",
  "payment-processors",
  "rfid_assets",
  "vendors",
  "items",
  "item-modifiers",
  "events",
];


function ActionButton({ resource }: { resource: string }) {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get("search") ?? "";
  const last = location.pathname.split("/").pop();
  const edit = location.pathname.split("/").pop();
  const deleteId = location.pathname.split("/").reverse()[1];
  // const permissions = getState().permissions;
  const {
    permissions,
    deletePermission,
    editCreatePermission,
    ListPermission,
  } = usePermissions(resource);
  // console.log({ permissions, resource });
  if (last === "list") {
    return (
      <div className="flex">
        {supportedSearches.includes(resource) && (
          <div className="mr-2">
            <Input
              name="search"
              placeholder={`Search ${
                resource
              }`}
              // defaultValue={search}
              onChange={(s) => {
                 navigate(
                      s && typeof s === "string" && s.length > 0
                        ? `/${resource}/list?search=${s}`
                        : `/${resource}/list`
                    );
                revalidator.revalidate();
              }}
            />
          </div>
        )}
        {supportedCreates.includes(resource) && editCreatePermission && (
          <PrimaryButton onClick={() => navigate(`/${resource}/create`)}>
            Create
          </PrimaryButton>
        )}
      </div>
    );
  } else if (Number.isInteger(Number(last))) {
    return (
      <div className="grid gap-2 grid-cols-3">
        {supportedCreates.includes(resource) && editCreatePermission && (
          <PrimaryButton onClick={() => navigate(`/${resource}/create`)}>
            Create New
          </PrimaryButton>
        )}
        {editCreatePermission ? (
          <PrimaryButton onClick={() => navigate(`/${resource}/${last}/edit`)}>
            Edit
          </PrimaryButton>
        ) : (
          ""
        )}
        {deletePermission && (
          <DeleteModal
            onConfirm={() => {
              fetcher.submit(
                {},
                {
                  method: "delete",
                  action: `/${resource}/${last}/delete`,
                }
              );
            }}
          >
            {({ setOpen }) => (
              <SecondaryButton onClick={() => setOpen(true)}>
                Delete
              </SecondaryButton>
            )}
          </DeleteModal>
        )}
      </div>
    );
  } else if (edit === "edit") {
    return (
      <div className="grid gap-2 grid-cols-1">
        {supportedDeletes.includes(resource) && deletePermission && (
          <DeleteModal
            onConfirm={() => {
              fetcher.submit(
                {},
                {
                  method: "delete",
                  action: `/${resource}/${deleteId}/delete`,
                }
              );
            }}
          >
            {({ setOpen }) => (
              <SecondaryButton onClick={() => setOpen(true)}>
                Delete
              </SecondaryButton>
            )}
          </DeleteModal>
        )}
      </div>
    );
  }
  return <div className="h-9" />;
}

export default function SectionHeading({ resource }: SectionHeadingProps) {
  if (supportedBreadCrumbs.includes(resource)) {
    return (
      <div className="border-b border-gray-200 py-2 mb-2 sm:flex sm:items-center sm:justify-between">
        <Breadcrumbs />
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <ActionButton resource={resource} />
        </div>
      </div>
    );
  } else {
    return (
      <div className="border-b border-gray-200 py-2 mb-2 sm:flex sm:items-center sm:justify-between">
        <div></div>
        <div className="mt-3 flex sm:mt-0 sm:ml-4">
          <ActionButton resource={resource} />
        </div>
      </div>
    );
  }
}
