import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import set from "lodash/set";
import get from "lodash/get";
import { CalendarDateTime, startOfYear } from "@internationalized/date";
import debounce from "lodash/debounce";
import moment, { Moment } from "moment-timezone";

import { DatePicker } from "../../../components/DatePicker";
import CustomSelect from "../../../components/customSelect";

import {
  renderLocationOptions,
  renderOrganizationOptions,
  renderUsernameOptions,
  renderVendorOptions,
} from "../utils";
import {
  organizationStore,
  useOrganizationStore,
} from "../../../store/organization";
import { eventStore, useEventStore } from "../../../store/event";
import { VENDORS_LIST_QUERY } from "../../vendors/queries";
import { createApolloClient } from "../../../providers/ApolloClientFactory";
import { Vendor } from "../../../types/vendor";
import { userStore } from "../../../store/user";
import { GET_LOCATIONS_REPORT_FILTER} from "../../locations/queries";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const formatDateForApi = (date: Moment) => {
  return moment.tz(date, timezone).format("YYYY-MM-DDTHH:mm:ss");
};

interface IReportFiltersProps {
  includeLocations?: boolean;
  includeDates?: boolean;
  includeDateOnly?: boolean;
  includeDatesStartOfYear?: boolean;
  includeTime?: boolean;
  includeEvents?: boolean;
  multipleEvents?: boolean;
  includeVendors?: boolean;
  includeUsernames?: boolean;
  includeOrganizations?: boolean;
  multipleOrganizations?: boolean;
  vendor_id?: number;
  customQueryPaths?: Record<string, string>;
}

const mappings = {
  organizationId: "organization_id._eq",
  locationId: "location_id._eq",
  eventId: "event_id._eq",
  vendorId: "vendor_id._eq",
  username: "clerk_id._eq",
  dateFrom: "_and.[0].transaction_hour._gte",
  dateTo: "_and.[0].transaction_hour._lte",
};

const parseSearch = (search: string) => {
  const defaults: Record<string, any> = {};
  const searchUrl = new URL(search);
  const where = searchUrl.searchParams.get("where");
  const whereVariable = JSON.parse(where ?? "{}");
  Object.entries(mappings).forEach(([key, value]) => {
    defaults[key] = get(whereVariable, value);
  });
  return defaults;
};
const { getState } = organizationStore;
const client = createApolloClient();

export const ReportFilters = ({
  includeLocations,
  includeDates,
  includeDateOnly,
  includeDatesStartOfYear,
  includeTime,
  includeEvents,
  multipleEvents,
  includeVendors,
  includeUsernames,
  includeOrganizations,
  multipleOrganizations,
  vendor_id,
  customQueryPaths,
}: IReportFiltersProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { getState } = userStore;
  const { getState: getOrganizationState } = organizationStore;
  const { getState: getEventState } = eventStore;
  const rawOrganizationId = getOrganizationState().organizationId;
  const organizationId = parseInt(rawOrganizationId !== null ? rawOrganizationId.toString() : '', 10);
  const { eventId } = getEventState();
  const user = getState().user;
  const allEvents = useEventStore((state) => state.allEvents);

  const allOrganizations = useOrganizationStore(
    (state) => state.allOrganizations
  );
  const users = useOrganizationStore((state) => state.clerks);
  // const locations = useOrganizationStore(
  //   (user?.vendors?.length || 0) > 0
  //     ? (state) => state.vendorLocations
  //     : (state) => state.locations
  // );

  const organizations = useOrganizationStore((state) => state.organizations);
  const momentDateOnly = moment().add(1, "day");
  const momentDate = moment().startOf("day");
  const momentDateToFiveAm = moment()
    .startOf("day")
    .add(1, "day")
    .add(5, "hours");
  const momentDateFiveAm = moment().startOf("day").add(5, "hours");

  const dateOnly = new CalendarDateTime(
    momentDateOnly.year(),
    momentDateOnly.month() + 1,
    momentDateOnly.date()
  );
  const date = new CalendarDateTime(
    momentDate.year(),
    momentDate.month() + 1,
    momentDate.date(),
    momentDate.hour(),
    momentDate.minute(),
    momentDate.second()
  );
  const dateToFiveAm = new CalendarDateTime(
    momentDateToFiveAm.year(),
    momentDateToFiveAm.month() + 1,
    momentDateToFiveAm.date(),
    momentDateToFiveAm.hour(),
    momentDateToFiveAm.minute(),
    momentDateToFiveAm.second()
  );
  const dateFiveAm = new CalendarDateTime(
    momentDateFiveAm.year(),
    momentDateFiveAm.month() + 1,
    momentDateFiveAm.date(),
    momentDateFiveAm.hour(),
    momentDateFiveAm.minute(),
    momentDateFiveAm.second()
  );

  const defaults = parseSearch(window.location.href);
  const [dateFromFiveAm, setdateFromFiveam] = useState(dateFiveAm);
  const [dateOnlyFrom, setDateOnlyFrom] = useState(startOfYear(dateOnly));
  const [dateOnlyTo, setDateOnlyTo] = useState(dateOnly);
  const [dateFrom, setDateFrom] = useState(
    includeDatesStartOfYear ? startOfYear(dateFiveAm) : date
  );
  const [dateFromStartOfYear, setDateFromStartOfYear] = useState(
    startOfYear(dateFiveAm)
  );
  const [dateTo, setDateTo] = useState(dateToFiveAm);
  const [location, setLocation] = useState(defaults.locationId);
  const [event, setEvent] = useState(defaults.eventId);
  const [vendor, setVendor] = useState(defaults.vendorId);
  const [username, setUsername] = useState(defaults.username);
  const [organization, setOrganization] = useState<string[] | null>(
    defaults.organizationId || organizationId
  );
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const fetchVendor = async () => {

    let where: any = (organizationId && organizationId !== 0)
      ? {
          organization_id: {
            _eq: organizationId,
          },
        }
      : {};
    let locationWhere: any = (organizationId && organizationId !== 0)
        ? {
          event: {organization_id: {
              _eq: organizationId,
            }},
        }
        : {};
    if (eventId) {
      locationWhere = {
        ...locationWhere,
        event_id: {
          _eq: eventId,
        },
      };
    }
    if (user?.vendors) {
      locationWhere = {
        vendor_id: {
          _in: user?.vendors,
        },
      };
    }
    if (user?.events) {
      locationWhere = {
        event_id: {
          _in: user?.events,
        },
      };
    }

    const [{ data: vendorData }, {data: locationData}] = await Promise.all ([
        client.query({
          query: VENDORS_LIST_QUERY,
          variables: {
            where,
          },
        }),
        client.query({
          query: GET_LOCATIONS_REPORT_FILTER,
          variables: {
            locationWhere,
          },
        }),
    ]);
    setVendors(vendorData?.vendors || []);
    setLocations(locationData?.locations || []);
  };
  useEffect(() => {
    fetchVendor();
  }, [organizationId,eventId]);

  useEffect(() => {
    const where: any = {};
    if (organizationId) {
      where.organization_id = {
        _eq: organizationId,
      };
    }
    if (vendor_id) {
      where.vendor_id = {
        _eq: vendor_id,
      };
    } else if (includeVendors && user?.vendors) {
      where.vendor_id = {
        _in: user?.vendors,
      };
    }

    if (includeOrganizations) {
      delete where.organization_id;
      where.organizations = organization;
    }

    if (includeDates && includeDateOnly) {
      let dateFromFilter = {
        day: dateFrom.day,
        month: dateFrom.month - 1,
        year: dateFrom.year,
        hour: 0,
        minute: 0,
      };

      let dateToFilter = {
        day: dateTo.day,
        month: dateTo.month - 1,
        year: dateTo.year,
        hour: 0,
        minute: 0,
      };
      if (includeTime) {
        dateFromFilter = {
          ...dateFromFilter,
          hour: dateFromFiveAm.hour,
          minute: dateFrom.minute,
        };
        dateToFilter = {
          ...dateToFilter,

          hour: dateTo.hour,
          minute: dateTo.minute,
        };
      }

      if (customQueryPaths && customQueryPaths.dateFrom) {
        set(where, customQueryPaths.dateFrom, {
          _gte: formatDateForApi(moment(dateFromFilter)),
        });
      }

      if (customQueryPaths && customQueryPaths.dateTo) {
        set(where, customQueryPaths.dateTo, {
          _lt: formatDateForApi(moment(dateToFilter)),
        });
      }

      if (!customQueryPaths) {
        where["_and"] = [
          {
            transaction_hour: {
              _gte: formatDateForApi(moment(dateFromFilter)),
            },
          },
          {
            transaction_hour: {
              _lt: formatDateForApi(moment(dateToFilter)),
            },
          },
        ];
      }
    }

    if (includeDates && includeTime) {
      let dateFromFilter = {
        day: dateFrom.day,
        month: dateFrom.month - 1,
        year: dateFrom.year,
        hour: 0,
        minute: 0,
      };

      let dateToFilter = {
        day: dateTo.day,
        month: dateTo.month - 1,
        year: dateTo.year,
        hour: 0,
        minute: 0,
      };
      if (includeTime) {
        dateFromFilter = {
          ...dateFromFilter,
          hour: dateFromFiveAm.hour,
          minute: dateFrom.minute,
        };
        dateToFilter = {
          ...dateToFilter,

          hour: dateTo.hour,
          minute: dateTo.minute,
        };
      }

      if (customQueryPaths && customQueryPaths.dateFrom) {
        set(where, customQueryPaths.dateFrom, {
          _gte: formatDateForApi(moment(dateFromFilter)),
        });
      }

      if (customQueryPaths && customQueryPaths.dateTo) {
        set(where, customQueryPaths.dateTo, {
          _lt: formatDateForApi(moment(dateToFilter)),
        });
      }

      if (!customQueryPaths) {
        where["_and"] = [
          {
            transaction_hour: {
              _gte: formatDateForApi(moment(dateFromFilter)),
            },
          },
          {
            transaction_hour: {
              _lt: formatDateForApi(moment(dateToFilter)),
            },
          },
        ];
      }
    }

    if (username && !username.includes("") && username.length > 0) {
      where["username"] = {
        _in: username,
      };
    }

    if (event) {
      if (customQueryPaths && customQueryPaths.event) {
        where[customQueryPaths.event] = event;
      } else {
        where["event_id"] = {
          _eq: event,
        };
      }
    }

    if (vendor && !vendor.includes("") && vendor.length > 0) {
      where["vendor_id"] = {
        _in: vendor,
      };
    }

    if (location && !location.includes("") && location.length > 0) {
      where["location_id"] = {
        _in: location,
      };
    }
    navigate(`?where=${JSON.stringify(where)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    organizationId,
    location,
    dateFrom,
    dateTo,
    organization,
    username,
    event,
    vendor,
  ]);

  const debounceSetDateFrom = debounce((date) => {
    console.log("debounceSetDateFrom", date);
    setDateFrom(date);
  }, 500);

  const debounceSetDateTo = debounce((date) => {
    console.log("debounceSetDateTo", date);
    setDateTo(date);
  }, 500);

  // Find the selected organization for the vendor and event dropdowns
  let selectedOrganization = [];
  const startOfYearTimeDefaultValue = includeDatesStartOfYear
    ? dateFromStartOfYear
    : dateFromFiveAm;
  if (multipleOrganizations) {
    if (organization) {
      selectedOrganization = allOrganizations.filter(
        (org: { id: string }) => organization.indexOf(org.id) > -1
      );
    } else {
      selectedOrganization = allOrganizations;
    }
  } else {
    selectedOrganization = organizations;
  }

  const filters = [
    includeDateOnly && includeDates && (
      <>
        <div className="flex flex-row items-center w-full">
          <DatePicker
            label="From"
            granularity="day"
            defaultValue={dateOnlyFrom}
            onChange={debounceSetDateFrom}
            onTimeChange={(time: CalendarDateTime) => {
              debounceSetDateFrom({ ...dateOnlyFrom, ...time });
            }}
          />
        </div>
        <div className="flex flex-row items-center w-full">
          <DatePicker
            label="To"
            granularity="day"
            defaultValue={dateOnlyTo}
            onChange={debounceSetDateTo}
            onTimeChange={(time: CalendarDateTime) => {
              debounceSetDateTo({ ...dateOnlyFrom, ...time });
            }}
          />
        </div>
      </>
    ),
    includeDates && includeTime && (
      <>
        <div className="flex flex-row items-center w-full">
          <DatePicker
            label="From"
            defaultValue={startOfYearTimeDefaultValue}
            onChange={debounceSetDateFrom}
            onTimeChange={(time: CalendarDateTime) => {
              debounceSetDateFrom({ ...dateFrom, ...time });
            }}
            includeTime={includeTime}
            granularity="hour"
            hourCycle={12}
          />
        </div>
        <div className="flex flex-row items-center w-full">
          <DatePicker
            label="To"
            defaultValue={dateTo}
            onChange={debounceSetDateTo}
            onTimeChange={(time: CalendarDateTime) => {
              debounceSetDateTo({ ...dateFrom, ...time });
            }}
            includeTime={includeTime}
            granularity="hour"
            hourCycle={12}
          />
        </div>
      </>
    ),
    includeOrganizations && (
      <div>
        <CustomSelect
          title="Organization"
          multi={multipleOrganizations}
          defaultValue={organization}
          onChange={setOrganization}
          options={renderOrganizationOptions(organizations)}
        />
      </div>
    ),
    includeEvents && (
      <div>
        <CustomSelect
          title="Event"
          multi={multipleEvents}
          defaultValue={event}
          onChange={setEvent}
          options={[
            ...(allEvents?.map((e) => ({
              label: e.name,
              value: e.id,
            })) || []),
          ]}
        />
      </div>
    ),
    includeVendors && !user?.vendors && (
      <div>
        <CustomSelect
          title="Vendor"
          defaultValue={vendor}
          onChange={setVendor}
          multi
          options={renderVendorOptions(vendors ?? [])}
        />
      </div>
    ),
    includeLocations && (
      <div>
        <CustomSelect
          title="Location"
          defaultValue={location}
          onChange={setLocation}
          multi
          options={renderLocationOptions(locations ?? [])}
        />
      </div>
    ),
    includeUsernames && (
      // <div className="sm:col-span-3">
      <div>
        <CustomSelect
          title="Username"
          defaultValue={username}
          onChange={setUsername}
          multi
          options={renderUsernameOptions(users ?? [])}
        />
      </div>
    ),
  ].filter(Boolean);

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 my-4 ">
        {filters}
      </div>
    </>
  );
};
